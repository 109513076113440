import mixpanel from 'mixpanel-browser'
import { useCallback } from 'react'
import { toast } from 'react-toastify'

import { Callback } from 'src/common'


export const useClipboard = (data: string): Callback => useCallback(() => {
  const textField = document.createElement('textarea')
  textField.innerText = data
  document.body.appendChild(textField)
  textField.select()
  textField.setSelectionRange(0, 99999)
  document.execCommand('copy')
  textField.remove()
  toast.info('Copied to clipboard', {
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
  })
  mixpanel.track('copy_link_click')
}, [data])
