import React from 'react'


interface Props {
    className?: string
}

export const IcoDuplicate:React.FC<Props> = ({ className }) => (
  <svg className={ className } viewBox="0 0 14.5996 16.9995" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"><defs/><path d="M14.5996 15.3999L13.2998 15.3999 13.2998 14.3999 13.5996 14.3999 13.5996 1 3.3999 1 3.3999 1.2998 2.3999 1.2998 2.3999 0 14.5996 0z"/><path d="M12.2002,16.9995H0V2.3999h12.2002V16.9995z M1,15.9995h10.2002V3.3999H1V15.9995z M6.5996,11.2993 h-1v-1.5h-1.5v-1h1.5v-1.5h1v1.5h1.5v1h-1.5V11.2993z"/></svg>
)
