import classnames from 'classnames'
import React, { useEffect } from 'react'
import { useState } from 'react'

import { StatisticResponse } from 'src/apis/core'
import { Callback } from 'src/common'
import chartsClasses from 'src/components/StatisticModal/StatisticModal.module.css'
import { useClipboard } from 'src/hooks/useClipboard'
import { useCoreApi } from 'src/hooks/useCoreApi'
import { AnalyticsItem } from 'src/models/Analytic'

import { InputWithAction } from '../InputWithAction/InputWithAction'
import { ButtonGroup } from '../shared/buttonGroup/ButtonGroup'
import { Bar } from '../shared/charts/Bar'
import { Pie } from '../shared/charts/Pie'
// import { IcoDelete } from '../shared/icons/IcoDelete'
// import { IcoDuplicate } from '../shared/icons/IcoDuplicate'

import classes from './AnalyticItem.module.css'




interface Props {
  item: AnalyticsItem
  isOpen: boolean
  onOpen: Callback<number|null>
  setSelectedItems: any
  selectedItems: number[]
}

const getCreatedDate = (date: string) => new Date(date).toLocaleString('en-GB', { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })


export const AnalyticItem: React.FC<Props> = ({ item, isOpen, onOpen, setSelectedItems, selectedItems }) => {

  const [statisticPeriod, setStatisticPeriod] = useState<'LAST_DAY' | 'YESTERDAY' | 'WEEK' | 'MONTH' | 'LIFETIME'>('LIFETIME')
  const [stat, setStat] = useState<StatisticResponse>()
  const [loading, setLoading] = useState(false)
  const periodButtons = [
    { title: 'Last day', action: () => setStatisticPeriod('LAST_DAY'), isSelected: statisticPeriod === 'LAST_DAY' },
    { title: 'Yesterday', action: () => setStatisticPeriod('YESTERDAY'), isSelected: statisticPeriod === 'YESTERDAY' },
    { title: 'Week', action: () => setStatisticPeriod('WEEK'), isSelected: statisticPeriod === 'WEEK' },
    { title: 'Month', action: () => setStatisticPeriod('MONTH'), isSelected: statisticPeriod === 'MONTH' },
    { title: 'All time', action: () => setStatisticPeriod('LIFETIME'), isSelected: statisticPeriod === 'LIFETIME' },
  ]
  const coreApi = useCoreApi()
  const copyLinkToClipboard = useClipboard(`${item.domain}/${item.hash}`)

  useEffect(() => {
    isOpen && coreApi.getPreviewStatistic({ id: item.id, period: statisticPeriod })
      .then(stat => {
        if(stat.totalClicks !== 0){
          setStat(stat)
        }
      })
      .catch(console.log)
      .finally(() => setLoading(false))
  }, [coreApi, item.id, statisticPeriod, isOpen])

  return (
    <div className={classes.wrapper}>
      <div className={classes.root} onClick={() => onOpen(isOpen ? null : item.id)}>
        <input type="checkbox" disabled={selectedItems.length >= 5 && !selectedItems.includes(item.id)} checked={selectedItems.includes(item.id)} onClick={e => e.stopPropagation()} onChange={(e) => e.target.checked ? setSelectedItems((curr: any[]) => [...curr, item.id]) : setSelectedItems((curr: any[]) => curr.filter(id => id !== item.id))}/>
        <div className={classes.name}>{item.name}</div>
        <div className={classes.created}>{getCreatedDate(item.createdAt)}</div>
        <div className={classes.destination}>{item.link}</div>
        <div className={classes.total}>{item.stat.total}</div>
        <div className={classes.last24h}>{item.stat.yesterday}</div>
        <div className={classes.week}>{item.stat.lastWeek}</div>
        <div className={classes.month}>{item.stat.lastMonth}</div>
        {/* <div className={classes.actions}>
          <IcoDuplicate className={classes.icon}/>
          <IcoDelete className={classes.icon}/>
        </div> */}
      </div>
      {isOpen ? <div className={classes.panel}>
        { !stat &&
              <div className={chartsClasses.Statistic__overlay}>
                {
                  loading ?
                    <p>Stats is loading...</p> :
                    <p>There is no statistics yet. Share the link to your clients to see more...</p>
                }
              </div>
        }
        <div className={classes.card}>
          <img src={item.image} style={{
            height: '160px',
            width: '100%',
            objectFit: 'cover',
          }} alt=""/>
          <span style={{
            color: '#28292C',
            fontSize: '16px',
            letterSpacing: '0.5px',
            lineHeight: '24px',
          }}>{item.title}</span>
          <span style={{
            color: '#8A8F98',
            fontSize: '14px',
            letterSpacing: '1px',
            lineHeight: '18px',
          }}>{item.body}</span>
          <InputWithAction inputText={`${item.domain}/${item.hash}`} inputDescription="Preview link" action={copyLinkToClipboard} buttonText="Copy"/>
        </div>
        <div className={chartsClasses.Bar_container} style={stat ? {} : { filter: 'opacity(0.3) grayscale(1)' }}>
          <div className={chartsClasses['period-picker']}>
            <h5 className={ chartsClasses.caption }>Clicks</h5>
            <ButtonGroup
              list={ periodButtons }
            />
          </div>
          {!stat?.discreteClicks?.find(i => i?.amount > 0) && stat?.totalClicks && (<div className={chartsClasses.empty_stat_message}>
            <p>There is no statistic by this period</p>
          </div>)}
          <div className={chartsClasses.Bar_wrapper} style={!stat?.discreteClicks?.find(i => i?.amount > 0) && stat?.totalClicks ? { filter: 'opacity(0.3) grayscale(1)' }: { height: '330px' }}>
            <Bar styles={{ minWidth: 'auto' }} data={stat ? stat.discreteClicks : null} totalClicks={stat ? stat.totalClicks : 0}/>
          </div>
        </div>
        <div className={classnames(classes.Pies)} style={stat ? {} : { filter: 'opacity(0.3) grayscale(1)' }}>
          <div className={chartsClasses.Pie_wrapper}>
            <Pie data={stat && stat.operationSystems ? stat.operationSystems : null}/>
            <p>OS</p>
          </div>
          <div className={chartsClasses.Pie_wrapper}>
            <Pie data={stat && stat.devices ? stat.devices : null}/>
            <p>Platforms</p>
          </div>
          <div className={chartsClasses.Pie_wrapper}>
            <Pie data={(stat && stat.browsers) ? stat.browsers : null}/>
            <p>Browsers</p>
          </div>
          <div className={chartsClasses.Pie_wrapper}>
            <Pie data={stat && stat.countries ? stat.countries : null}/>
            <p>Countries</p>
          </div>
          <div className={chartsClasses.Pie_wrapper}>
            <Pie data={stat && stat.cities ? stat.cities : null}/>
            <p>Cities</p>
          </div>
        </div>
      </div> : null}
    </div>
  )
}
