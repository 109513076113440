export enum DomainStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  ERRONEOUS = 'ERRONEOUS',
  EXPIRED = 'EXPIRED',
  REMOVED = 'REMOVED'
}

export interface Domain {
  createdAt: string
  default: boolean
  host: string
  id: number
  status: DomainStatus
}
