export const mockedBrowsers = [
  [
    {
      'value': 'Chrome',
      'amount': 3,
    },
    {
      'value': 'Firefox',
      'amount': 3,
    },
    {
      'value': 'Safari',
      'amount': 1,
    },
  ],
  [
    {
      'value': 'Chrome',
      'amount': 4,
    },
    {
      'value': 'Firefox',
      'amount': 5,
    },
    {
      'value': 'Safari',
      'amount': 0,
    },
  ],
  [
    {
      'value': 'Chrome',
      'amount': 2,
    },
    {
      'value': 'Firefox',
      'amount': 1,
    },
    {
      'value': 'Safari',
      'amount': 3,
    },
  ],
  [
    {
      'value': 'Chrome',
      'amount': 7,
    },
    {
      'value': 'Firefox',
      'amount': 2,
    },
    {
      'value': 'Safari',
      'amount': 1,
    },
  ],
  [
    {
      'value': 'Chrome',
      'amount': 2,
    },
    {
      'value': 'Firefox',
      'amount': 4,
    },
    {
      'value': 'Safari',
      'amount': 2,
    },
  ],
]
export const mockedCities = [
  [
    {
      'value': 'Kyiv',
      'amount': 3,
    },
    {
      'value': 'New-York',
      'amount': 5,
    },
    {
      'value': 'Los-Angeles',
      'amount': 7,
    },
    {
      'value': 'London',
      'amount': 4,
    },
  ],
  [
    {
      'value': 'Kyiv',
      'amount': 4,
    },
    {
      'value': 'New-York',
      'amount': 2,
    },
    {
      'value': 'Los-Angeles',
      'amount': 3,
    },
    {
      'value': 'London',
      'amount': 2,
    },
  ],
  [
    {
      'value': 'Kyiv',
      'amount': 2,
    },
    {
      'value': 'New-York',
      'amount': 15,
    },
    {
      'value': 'Los-Angeles',
      'amount': 17,
    },
    {
      'value': 'London',
      'amount': 14,
    },
  ],
  [
    {
      'value': 'Kyiv',
      'amount': 7,
    },
    {
      'value': 'New-York',
      'amount': 4,
    },
    {
      'value': 'Los-Angeles',
      'amount': 2,
    },
    {
      'value': 'London',
      'amount': 0,
    },
  ],
  [
    {
      'value': 'Kyiv',
      'amount': 2,
    },
    {
      'value': 'New-York',
      'amount': 6,
    },
    {
      'value': 'Los-Angeles',
      'amount': 3,
    },
    {
      'value': 'London',
      'amount': 2,
    },
  ],
]

export const mockedCountries = [
  [
    {
      'value': 'Ukraine',
      'amount': 3,
    },
    {
      'value': 'USA',
      'amount': 7,
    },
    {
      'value': 'UK',
      'amount': 4,
    },
  ],
  [
    {
      'value': 'Ukraine',
      'amount': 4,
    },
    {
      'value': 'USA',
      'amount': 8,
    },
    {
      'value': 'UK',
      'amount': 2,
    },
  ],
  [
    {
      'value': 'Ukraine',
      'amount': 2,
    },
    {
      'value': 'USA',
      'amount': 5,
    },
    {
      'value': 'UK',
      'amount': 5,
    },
  ],
  [
    {
      'value': 'Ukraine',
      'amount': 7,
    },
    {
      'value': 'USA',
      'amount': 10,
    },
    {
      'value': 'UK',
      'amount': 6,
    },
  ],
  [
    {
      'value': 'Ukraine',
      'amount': 2,
    },
    {
      'value': 'USA',
      'amount': 4,
    },
    {
      'value': 'UK',
      'amount': 2,
    },
  ],
]

export const mockedDevices = [
  [
    {
      'value': 'DESKTOP',
      'amount': 3,
    },
    {
      'value': 'MOBILE',
      'amount': 3,
    },
  ],
  [
    {
      'value': 'DESKTOP',
      'amount': 4,
    },
    {
      'value': 'MOBILE',
      'amount': 5,
    },
  ],
  [
    {
      'value': 'DESKTOP',
      'amount': 2,
    },
    {
      'value': 'MOBILE',
      'amount': 7,
    },
  ],
  [
    {
      'value': 'DESKTOP',
      'amount': 7,
    },
    {
      'value': 'MOBILE',
      'amount': 8,
    },
  ],
  [
    {
      'value': 'DESKTOP',
      'amount': 2,
    },
    {
      'value': 'MOBILE',
      'amount': 4,
    },
  ],
]

export const mockedOs = [
  [
    {
      'value': 'Windows NT',
      'amount': 3,
    },
    {
      'value': 'Linux',
      'amount': 2,
    },
    {
      'value': 'Mac OS X',
      'amount': 2,
    },
  ],
  [
    {
      'value': 'Windows NT',
      'amount': 8,
    },
    {
      'value': 'Mac OS X',
      'amount': 1,
    },
  ],
  [
    {
      'value': 'Windows NT',
      'amount': 4,
    },
    {
      'value': 'Linux',
      'amount': 2,
    },
    {
      'value': 'Mac OS X',
      'amount': 3,
    },
  ],
  [
    {
      'value': 'Windows NT',
      'amount': 6,
    },
    {
      'value': 'Mac OS X',
      'amount': 2,
    },
  ],
  [
    {
      'value': 'Mac OS X',
      'amount': 3,
    },
    {
      'value': 'Windows NT',
      'amount': 1,
    },
  ],
]
