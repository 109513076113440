import mixpanel from 'mixpanel-browser'
import React, { useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { Callback } from 'src/common'
import { useAuth } from 'src/hooks/useAuth'
import { useCoreApi } from 'src/hooks/useCoreApi'
import { Domain } from 'src/models/Domain'

import { Description } from './Description/Description'
import { DestinationURL } from './DestinationURL/DestinationURL'
import { DomainSelector } from './DomainSelector/DomainSelector'
import classes from './PreviewBuilderForm.module.css'
import { Title } from './Title/Title'


interface Props {
  setEmptyFetch: Callback<boolean>
  setPreviewImgURL: Callback<string>
  domains: Domain[]
  setSelectedDomain: Callback<Domain | undefined>
  selectedDomain: Domain | undefined
  onAddDomain: () => void
}

type FormValues = {
  title: string
  body: string
  name: string
  link: string
  image: string
  hash: string
}

export const PreviewBuilderForm: React.FC<Props> = ({
  setEmptyFetch,
  setPreviewImgURL,
  domains,
  setSelectedDomain,
  selectedDomain,
  onAddDomain,
}) => {
  const [loading, setLoading] = useState<boolean>(false)

  const coreApi = useCoreApi()

  const prefetch = new URLSearchParams(window.location.search).get('prefetch')

  const auth = useAuth()

  const { replace } = useHistory()

  const methods = useFormContext<FormValues>()

  const setValue = methods.setValue

  const emptyFetch = useCallback(() => {
    setEmptyFetch(true)
    setValue('title', 'No data have been found with the current link.')
    setValue('body', 'Try to change the link or customize it manually.')
    setPreviewImgURL('https://cdn.piar.io/images/197/oe0WX-no-image.png')
  }, [setValue, setEmptyFetch, setPreviewImgURL])

  const fetchMeta = async () => {
    setLoading(true)
    mixpanel.track('magic_button_click')
    try {
      const meta = await coreApi.fetchMeta(methods.watch().link)
      meta.title
        ? setValue('title', meta.title)
        : setValue('title', 'No data have been found with the current link.')
      meta.description
        ? setValue('body', meta.description)
        : setValue('body', 'Try to change the link or customize it manually.')
      meta.images && meta.images.length
        ? setPreviewImgURL(meta.images[0])
        : setPreviewImgURL('https://cdn.piar.io/images/197/oe0WX-no-image.png')
      if (Object.keys(meta).length === 0) {
        emptyFetch()
      } else {
        setEmptyFetch(false)
      }
    } catch (error) {
      emptyFetch()
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    (async () => {
      if (!prefetch) return
      replace('/builder')
      setValue('link', prefetch)
      try {
        const meta = await coreApi.fetchMeta(prefetch)
        meta.title
          ? setValue('title', meta.title)
          : setValue('title', 'No data have been found with the current link.')
        meta.description
          ? setValue('body', meta.description)
          : setValue('body', 'Try to change the link or customize it manually.')
        meta.images && meta.images.length
          ? setPreviewImgURL(meta.images[0])
          : setPreviewImgURL(
            'https://cdn.piar.io/images/197/oe0WX-no-image.png',
          )
        if (Object.keys(meta).length === 0) {
          emptyFetch()
        } else {
          setEmptyFetch(false)
        }
      } catch (error) {
        emptyFetch()
        console.log(error)
      } finally {
        setLoading(false)
      }
    })()
  }, [
    coreApi,
    prefetch,
    replace,
    setEmptyFetch,
    setValue,
    emptyFetch,
    setPreviewImgURL,
  ])

  return (
    <div className={classes.root}>
      <div>
        <DestinationURL methods={methods} loading={loading} fetchMeta={fetchMeta}/>
      </div>
      <div>
        <Title methods={methods}/>
      </div>
      <div>
        <Description methods={methods}/>
      </div>
      {auth.token && (
        <div>
          <DomainSelector methods={methods} selectedDomain={selectedDomain} selectDomain={setSelectedDomain} addDomain={onAddDomain} domains={domains}/>
        </div>
      )}
    </div>
  )
}
