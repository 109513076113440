import { ResponsiveBar } from '@nivo/bar'
import React from 'react'

import { StatisticItem } from 'src/models/Statistic'


interface Props {
    data: StatisticItem[] | null
    totalClicks: number
    styles?: React.CSSProperties
    // period: 'LAST_DAY' | 'YESTERDAY' | 'WEEK' | 'MONTH' | 'LIFETIME'
}

const getMockData = (period: 'hourly' | 'daily') => {
  
  if(period === 'daily'){
    const mockArray = [3, 5, 7, 4, 1, 0, 4, 8, 6, 2, 2, 5, 8, 4, 7, 3, 6, 6, 4, 3, 4]
    const mk = [
      { value: prepareDateForRender(new Date().toString(), 'daily'), amount:mockArray[0] },
    ]

    for (let index = 1; index < 20; index++) {
      mk.push({ value: prepareDateForRender(new Date(Date.now() - 8.64e7 *  index).toString(), 'daily'), amount:mockArray[index] })
    }
    return mk.reverse()
  } else {
    const mockArray = [3, 5, 7, 4, 1, 0, 4, 8, 6, 2, 2, 5, 8, 4, 7, 3, 6, 6, 4, 3, 4, 5, 6, 5, 5, 3, 6, 6]
    const mk = [
      { value: prepareDateForRender(new Date(new Date().setHours(0, 0, 0, 0)).toString(), 'hourly'), amount:mockArray[0] },
    ]
    for (let index = 1; index < 25; index++) {
      mk.push({ value: prepareDateForRender(new Date(new Date().setHours(0, 0, 0, 0) - 60 * 60 * 1000 *  index).toString(), 'hourly'), amount:mockArray[index] })
    }
    return mk.reverse()
  }
}



const prepareDateForRender = (date: string, period: 'hourly' | 'daily') => {
  if(period === 'hourly'){
    const dateObj = new Date(date)
    return dateObj.toLocaleTimeString([], { hour: '2-digit', minute:'2-digit' })
  }
  if(period === 'daily'){
    const dateObj = new Date(date)
    return dateObj.toLocaleDateString('nl', { month:'2-digit', day:'2-digit' })
  }
  return new Date(date).toLocaleDateString('nl', { month:'2-digit', day:'2-digit' })
}

const prepareDataToRender = (data: any[] | null) => {
  if( data ){
    if(new Date(data[1].value).getTime() - new Date(data[0].value).getTime() === 3600000){

      if(!data.find(i => i?.amount > 0)){
        return getMockData('hourly')
      }

      return data.map( (i, index) => {
        return { 
          amount: i.amount,
          value: `${prepareDateForRender(i.value, 'hourly')} ${index}`,
        }
      } )
    } else {
      if(!data.find(i => i?.amount > 0)){
        return getMockData('daily')
      }

      return data.map( (i, index) => {
        return { 
          amount: i.amount,
          value: `${prepareDateForRender(i.value, 'daily')} ${index}`,
        }
      } )
    }
    
  } else {
    return getMockData('daily')
  }
}

export const Bar: React.FC<Props> = ({ data, totalClicks, styles }) => {
  return (
    <div style={{ width: '100%', height: '100%', minWidth: '400px', ...styles }}>
      <ResponsiveBar 
        data={prepareDataToRender(data)}
        colors={{ scheme: 'set2' }} 
        enableLabel={!!data?.find(i => i?.amount > 0)}
        keys={['amount']} 
        indexBy="value"
        enableGridY={true} 
        margin={{ bottom:data ? 70 : 10, left:30, top: 15, right: 10 }} 
        padding={0.2}
        borderRadius={2}
        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        axisBottom={{ 
          tickSize: data ? 3 : 0, 
          tickPadding: data ? 3 : 15, 
          legend: `Total clicks: ${totalClicks}`, 
          legendOffset: 55, 
          legendPosition: 'middle', 
          tickRotation: 45, 
          format: values => values.toString().slice(0, 5), 
        }}
        axisLeft={data ? totalClicks >= 10 ? { tickSize: 0, tickPadding: 6 } : { tickSize: 0, tickPadding: 6, tickValues: totalClicks } : { tickSize: 0, tickPadding: 6 }}
        animate={false}
        motionStiffness={280}
        motionDamping={40}
        labelSkipHeight={12}
        labelSkipWidth={15}
        theme={{ axis: { legend: { text: { fontFamily: 'Roboto', textTransform: 'capitalize', fontSize: '14px' } }, ticks: { text: { fontSize: '10px' } } } }}
      />   
    </div>         
  )}
