// import { error } from 'console'

import mixpanel from 'mixpanel-browser'

import { Preview } from 'src/models/Preview'
// import { PreviewStatus } from 'src/models/PreviewHistoryItem'

import { CoreApi, UserDataResponse } from '.'


const prepareUserData = (data: UserDataResponse) => ({
  ...data,
  subscription: {
    ...data.subscription,
    expiredAt: new Date(data.subscription.expiredAt),
  },
})

interface Params {
  baseUrl: string
}

const serializePreview = (preview: Preview): Preview => {
  const normalPreview: Preview = {
    link: '',
    title: '',
    body: '',
    image: '',
    name: '',
  }
  ;(Object.keys(preview) as Array<keyof typeof preview>).forEach(
    (key) => preview[key] && (normalPreview[key] = preview[key])
  )
  if (normalPreview.hash) {
    normalPreview.hash = normalPreview.hash.toLowerCase()
  }
  return normalPreview
}

export const fetchCoreApi = ({ baseUrl }: Params): CoreApi => {
  async function client(
    endpoint: string,
    {
      data,
      token,
      headers: customHeaders,
      customConfig,
    }: {
      data?: unknown
      token?: string | null
      headers?: HeadersInit
      customConfig?: RequestInit
    } = {}
  ) {
    const config = {
      method: data ? 'POST' : 'GET',
      body: data ? JSON.stringify(data) : undefined,
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined,
        'Content-Type': 'application/json',
        ...customHeaders,
      },
      ...customConfig,
    }
    return window
      .fetch(`${baseUrl}/api/${endpoint}`, config as RequestInit)
      .then(async (response) => {
        if (response.status === 401) {
          // await auth.logout()
          // window.location.assign(window.location)
          // return Promise.reject({ message: 'Please re-authenticate.' })
          return Promise.reject(response.status)
        }
        try {
          const data = await response.json()
          if (response.ok) {
            return data
          } else {
            return Promise.reject(data)
          }
        } catch (error) {
          if (response.ok) {
            return
          } else {
            return Promise.reject({ status: response.status })
          }
        }
      })
  }
  function clientWithAuth(endpoint: string,
    {
      data,
      headers: customHeaders,
      customConfig,
    }: {
      data?: unknown
      headers?: HeadersInit
      customConfig?: RequestInit
    } = {}) {
    return client(endpoint, { 
      data,
      token: localStorage.getItem('bearerToken'), 
      headers: customHeaders, 
      customConfig, 
    })
  } 
  return {
    //APPS
    getRESTAPIApps: () => clientWithAuth('apps'),
    createRESTAPIApp: () => clientWithAuth('apps', {
      customConfig: { method: 'POST' },
    }),
    //SUMMO
    updateAppSumoCodes: (payload) =>
      clientWithAuth('summo/codes', {
        data: payload,
        customConfig: { method: 'PUT' },
      }),

    //AUTH
    signUpWithAppSumoCodes: (creds) =>
      client('auth/sign-up/summo', { data: creds }),
    signin: (creds) => fetch(`${baseUrl}/api/auth/sign-in`, { method: 'POST', body: JSON.stringify(creds), headers: { 'Content-Type': 'application/json' } })
      .then(response => response.json())
      .then(result => {
        if(result.error || result.causes){
          return Promise.reject(result)
        }
        localStorage.setItem('USER_ID', result.id.toString())
        mixpanel.identify(result.id.toString())
        mixpanel.people.set({
          $email: result.email,
          USER_ID: result.id.toString(),
        })
        return result
      }),
    signup: (creds) => client('auth/sign-up', { data: creds }),
    authoriseWithProvider: ({ provider, token }) => 
      client(`auth/oauth2?provider=${provider}&token=${token}`)
        .then(result => {
          localStorage.setItem('USER_ID', result.id.toString())
          mixpanel.identify(result.id.toString())
          mixpanel.people.set({
            $email: result.email,
            USER_ID: result.id.toString(),
          })
          if ((window as any).fbq) {
            (window as any).fbq('trackCustom', 'CompleteRegistration')
          }
          return result
        }, (err) => Promise.reject(err)),
    //PREVIEWS
    generatePreview: (preview) => 
      clientWithAuth('previews', { data: serializePreview(preview) })
        .then((result) => {
          localStorage.removeItem('generationPromise')
          return result
        }, (err) => Promise.reject(err)),
    removePreview: (previewId) =>
      clientWithAuth(`previews/${previewId}`, {
        customConfig: { method: 'DELETE' },
      }),
    getPreviewStatistic: ({ id, period }) =>
      clientWithAuth(
        `previews/${id}/stats?period=${period}&zone=${
          Intl.DateTimeFormat().resolvedOptions().timeZone
        }`
      ),
    getHistory: (query) =>
      clientWithAuth(`previews/v2?sort=createdAt,desc&page=${query?.page ? query?.page : 0}&size=9&status=${query?.status ? query.status : 'ACTIVE'}${query?.favorite ? '&favorite=true' : ''}&zone=${
        Intl.DateTimeFormat().resolvedOptions().timeZone
      }${query?.name ? `&name=${query?.name}` : ''}${query?.author && query.author.length ? query?.author.map(author => `&author=${encodeURIComponent(author)}`).join('') : ''}${query?.from ? `&from=${query.from}` : ''}${query?.to ? `&to=${query.to}` : ''}`),
    patchPreviewName: ({ id, value }) => 
      clientWithAuth(`previews/${id}/name?value=${value}`, {
        customConfig: { method: 'PATCH' },
      }),
    patchPreviewFavorite: ({ id, value }) => 
      clientWithAuth(`previews/${id}/favorite?enabled=${value}`, {
        customConfig: { method: 'PATCH' },
      })
    ,
    archivePreview: (id) => 
      clientWithAuth(`previews/${id}/archive`),
    restorePreview: (id) => 
      clientWithAuth(`previews/${id}/restore`),
    patchExternalStat: ({ id, enabled }) => 
      clientWithAuth(`previews/${id}/stat-sharing?enabled=${enabled}`, {
        customConfig: { method: 'PATCH' },
      }),
    getPreviewsProgress: () => clientWithAuth('previews/progress'),
    getPreviewPublicStat: ({ hash, period }) => fetch(`${baseUrl}/public/previews/v1/${hash}/stats?period=${period}&zone=${
      Intl.DateTimeFormat().resolvedOptions().timeZone
    }`)
      .then((resp) => resp.json()),
    getPreviewPublicContent: ({ hash }) => fetch(`${baseUrl}/public/previews/v1/${hash}`)
      .then((resp) => resp.json()),
    getAnalytics: (query) => clientWithAuth(`previews/analytics?zone=${
      Intl.DateTimeFormat().resolvedOptions().timeZone
    }&page=${query?.page ? query?.page : 0}${query?.name ? `&name=${query?.name}` : ''}${query?.author && query.author.length ? query?.author.map(author => `&author=${encodeURIComponent(author)}`).join('') : ''}${query?.from ? `&from=${query.from}` : ''}${query?.to ? `&to=${query.to}` : ''}`),
    getPreviewPayload: ({ id }) => clientWithAuth(`previews/${id}/payload`),
    getAuthors: ({ email }) => clientWithAuth(`previews/authors?size=20${email ? `&email=${email}` : ''}`),
    //USERS
    inviteMember: ({ email }) => clientWithAuth('users/team/members', {
      data: { email },
      customConfig: { method: 'PUT' },
    }),
    deactivateMember: ({ id }) => clientWithAuth(`users/team/members/${id}`, {
      customConfig: { method: 'DELETE' },
    }),
    reInviteMember: ({ id }) => clientWithAuth(`users/team/members/${id}/re-invite`),
    restoreMember: ({ id }) => clientWithAuth(`users/team/members/${id}/restore`),
    getMembers: ({ page }) => clientWithAuth(`users/team/members?page=${page}`),
    confirmMember: ({ pass, hash }) => clientWithAuth(`users/confirmation/${hash}/member`, {
      data: { pass },
    }),
    confirmAccount: (confirmationHash) => 
      client(`users/confirmation/${confirmationHash}`)
        .then(result => {
          localStorage.setItem('USER_ID', result.id.toString())
          mixpanel.identify(result.id.toString())
          mixpanel.people.set({
            $email: result.email,
            USER_ID: result.id.toString(),
          })
          if ((window as any).fbq) {
            (window as any).fbq('trackCustom', 'CompleteRegistration')
          }
          return result
        }, (err) => Promise.reject(err)),
    resendConfirmation: (userEmail) =>
      client(
        `users/confirmation/resend?email=${encodeURIComponent(userEmail)}`
      ),
    getUserData: (authToken?) =>
      client('users/me', {
        token: authToken ? authToken : localStorage.getItem('bearerToken'),
      }).then(
        (result) => (result.subscription ? prepareUserData(result) : result),
        (err) => Promise.reject(err)
      ),
    deleteAccount: async (email: string) => {
      const response = await fetch(
        'https://preview-it.firebaseio.com/delete.json',
        {
          method: 'POST',
          body: JSON.stringify({ email }),
        }
      )
  
      if (response.status < 400) {
        return Promise.resolve(null)
      }
  
      if (response.status === 401) {
        return Promise.reject(response.status)
      }
  
      return Promise.reject(await response.json())
    },
    resetPassword: (email) => 
      clientWithAuth(`users/reset/pass?email=${encodeURIComponent(email)}`),
    setNewPass: ({ pass, hash }) => client(`users/reset/pass/${hash}`, {
      data: { pass, hash },
      customConfig: { method: 'PUT' },
    }),
    //ARTICLES
    fetchMeta: async (targetUrl) =>
      clientWithAuth(`articles/fetch-meta?url=${encodeURIComponent(targetUrl)}`),
    //FILES
    uploadFile: async (file) => {
      const form = new FormData()
      form.append('file', file, file.name)
      const response = await fetch(`${baseUrl}/api/files/upload`, {
        method: 'POST',
        body: form,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
        },
      })
      if (response.status === 401) {
        return Promise.reject(response.status)
      }

      if (response.status < 400) {
        return Promise.resolve(await response.json())
      }
      return Promise.reject(await response.json())
    },
    //DOMAINS
    getDomainsList: (authToken?) => client('domains', {
      token: authToken ? authToken : localStorage.getItem('bearerToken'),
    }),
    setDefaultDomain: (id) => clientWithAuth(`domains/${id}/default`, {
      customConfig: { method: 'PATCH' },
    }),
    createNewDomain: (host: string) => clientWithAuth('domains', {
      data: {
        host,
        clientOwned: true,
      },
    }),
    deleteDomain: (id) => clientWithAuth(`domains/${id}`, {
      customConfig: { method: 'DELETE' },
    }),
    refreshDomain: (id) => clientWithAuth(`domains/${id}/refresh`),
    //BILLING
    startTrial: async (tariffName) =>
      clientWithAuth(`billing/subscriptions/trial?name=${tariffName}`)
        .then(
          (result) => (result.subscription ? prepareUserData(result) : result),
          (err) => Promise.reject(err)
        ),
    startNewPlan: ({ name, strategy }) =>
      clientWithAuth(
        `billing/subscriptions?name=${name}$strategy=${strategy.toUpperCase()}`
      ),
    completePayment: (paypalPayment) =>
      clientWithAuth('billing/payments/paypal/complete', {
        data: paypalPayment,
      }),
    checkoutPayment: (frequency) =>
      clientWithAuth(
        `billing/payments/paypal/checkout?strategy=${frequency.toUpperCase()}`
      ),
    getBillingPlans: () => clientWithAuth('billing/subscriptions/pricing'),
  }
}
