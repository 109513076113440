import classnames from 'classnames'
import mixpanel from 'mixpanel-browser'
import React, { useEffect } from 'react'
import Select from 'react-select'

import { Callback } from 'src/common'
import { ErrorMessage } from 'src/components/shared/errorMessage/ErrorMessage'
import inputClasses from 'src/components/shared/inputs/inputs.module.css'
import { Domain } from 'src/models/Domain'

import classes from './DomainSelector.module.css'


const placeholderUrl = 'https://your-domain.com/'

interface Props {
  methods: any
  domains: Domain[]
  selectDomain: Callback<Domain | undefined>
  selectedDomain: Domain | undefined
  addDomain: () => void
}

export const DomainSelector: React.FC<Props> = ({
  methods,
  domains,
  selectDomain,
  selectedDomain,
  addDomain,
}) => {

  const options = [
    { value: placeholderUrl, label: placeholderUrl },
    ...domains.map((domain) => ({
      value: domain.host,
      label: `${domain.host}/`,
    })),
  ]

  const onSelectDomain = (selected: any) => {
    if (selected) {
      return selectDomain(
        domains.find((domain) => domain.host === (selected as any).value)
      )
    }
  }

  const trackPreviewHashClick = () => {
    mixpanel.track('preview_hash_click')
  }

  useEffect(() => {
    if (domains.length !== 1) return

    selectDomain(domains[0])
  }, [domains, selectDomain])

  return (
    <div className={inputClasses.root}>
      <div className={inputClasses['input-title']}>
        <label htmlFor='preview-hash' className={inputClasses.title}>
          Customize link back-half and link domain
        </label>
        {
          !domains.length ? (
            <label
              className={classnames(inputClasses.title, classes['add-domain'])}
              onClick={addDomain}
            >
              Add domain
            </label>
          ) : null
        }
      </div>

      <div
        className={classnames(
          (methods?.errors?.hash?.type === 'manual' && classes['soft-error']), classes['input-wrapper']
        )}
      >
        <input
          ref={methods.register({ maxLength: 255 })}
          id='preview-hash'
          className={classnames(
            inputClasses.input,
            inputClasses['lower-case'],
            methods.errors &&
              methods.errors['hash'] &&
              methods.errors.hash.type === 'maxLength' &&
              inputClasses.error
          )}
          type='text'
          autoComplete='off'
          placeholder='random 5-symbol hash if empty'
          name='hash'
          onClick={trackPreviewHashClick}
        />
        {
          domains.length > 1 ? (
            <Select
              onChange={onSelectDomain}
              isDisabled={!domains.length}
              defaultValue={{
                value: placeholderUrl,
                label: placeholderUrl,
              }}
              options={options}
              className={classnames(inputClasses.input, inputClasses.domain)}
              placeholder={placeholderUrl}
            />
          ) : (
            <input
              value={domains[0] ? domains[0].host : placeholderUrl}
              onChange={onSelectDomain}
              disabled={true}
              className={classnames(inputClasses.input, inputClasses.domain)}
            />
          )
        }
      </div>
      {methods.errors.hash && methods.errors.hash.type === 'maxLength' && (
        <ErrorMessage>
          Your input exceed maxLength{' '}
          {methods.watch().hash && methods.watch().hash.length}/255
        </ErrorMessage>
      )}
      {methods.errors.hash && methods.errors.hash.type === 'manual' && (
        <ErrorMessage>{methods.errors.hash.message}</ErrorMessage>
      )}
      <label
        htmlFor='preview-hash'
        className={classnames(inputClasses.title, classes.description)}
      >
        We&apos;ll generate some random link back-half, so the link will look
        like <strong>{selectedDomain?.host ? selectedDomain?.host + '/' : placeholderUrl}1a2b3</strong> if you leave this field
        empty.
      </label>
    </div>
  )
}
