import React, { FC, useState, useEffect } from 'react'

import { PreviewProps } from '../PreviewList'
import classes from '../SlackPreview/SlackPreview.module.css'


export const SlackPreview: FC<PreviewProps> = ({ title, body, link, image }) => {
  const [url, setUrl] = useState<URL>()
  useEffect(() => {
    try {
      setUrl(new URL(link))
    } catch (error) {
      setUrl(undefined)
    }
  }, [link])
  
  return(
    <div className={classes.card_seo_slack}>
      <div className={classes.card_seo_slack__bar}></div>
      <div className={classes.card_seo_slack__content}>
        <div>
          {/* <img
            className={classes.card_seo_slack__favicon}
            alt="favicon"
            src={`${link}/favicon.ico`}
          /> */}
          <span className={classes.card_seo_slack__link}>{url ? url.host : ''}</span>
        </div>
        <div className={classes.card_seo_slack__title}>
          {title}
        </div>
        <span className={classes.card_seo_slack__description}>{body}</span>
        <div
          className={classes.card_seo_slack__image}
        // style={{ backgroundImage: `url(${image})` }}
        >
          <img alt="slack-preview" src={image}/>
        </div>
      </div>
    </div>
  )}
