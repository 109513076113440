import Tippy from '@tippyjs/react'
import classnames from 'classnames'
import mixpanel from 'mixpanel-browser'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Callback } from 'src/common'
import { useClipboard } from 'src/hooks/useClipboard'
import { useCoreApi } from 'src/hooks/useCoreApi'
import { Preview } from 'src/models/Preview'
import { PreviewHistoryItem, PreviewStatus } from 'src/models/PreviewHistoryItem'

import { InputWithAction } from '../InputWithAction/InputWithAction'
import { Button } from '../shared/button/Button'
import buttonClasses from '../shared/button/Button.module.css'
import { IcoDelete } from '../shared/icons/IcoDelete'
import { IcoDuplicate } from '../shared/icons/IcoDuplicate'
import { IcoEdit } from '../shared/icons/IcoEdit'
import { IcoFavorite } from '../shared/icons/IcoFavorite'
import { IcoSave } from '../shared/icons/IcoSave'
import { IcoStats } from '../shared/icons/IcoStats'
import { IcoArchive } from '../shared/icons/icoArchive'
import { IcoClose } from '../shared/icons/icoClose'
import inputStyles from '../shared/inputs/inputs.module.css'

import '../shared/tippyjs/tippyjs.css'
import classes from './HistoryItemCard.module.css'


interface Props {
    item: PreviewHistoryItem
    deleteItem: Callback<number>
    updateItemFavorite: Callback<{ id: number, value: boolean }>
    setPreview: Callback<Preview>
    setSelectedItemId?: Callback<number | null>
    setHistoryContent: Callback<PreviewHistoryItem[]>
    historyContent: PreviewHistoryItem[]
    status: PreviewStatus
    archive: Callback<number>
    restore: Callback<number>
}

export const HistoryItemCard: React.FC<Props> = ({ item, deleteItem, setPreview, updateItemFavorite, setSelectedItemId, setHistoryContent, historyContent, status, archive, restore }) => {
  const copyLinkToClipboard = useClipboard(`${item.domain}/${item.hash}`)
  const history = useHistory()
  const coreApi = useCoreApi()
  const [isPreviewNameEditable, setPreviewNameEditable] = useState<boolean>(false)
  const [previewName, setPreviewName] = useState<string>(item.name)

  const DeleteAlert = ({ onUndo, closeToast }: { onUndo: Callback, closeToast?: Callback }) => {
    const handleClick = () => {
      onUndo()
      closeToast && closeToast()
    }

    return (
      <div className={ classes['delete-alert']}>
        Preview is deleting
        <button
          type="button"
          onClick={ handleClick }
        >UNDO</button>
      </div>
    )
  }

  const deletePreview = () => {
    mixpanel.track('delete_link_click')

    let enableToDelete = true
    const autoClose = 5000

    toast(<DeleteAlert onUndo={ () => enableToDelete = false } />, {
      autoClose,
      hideProgressBar: false,
      closeOnClick: false,
      closeButton: false,
      toastId: item.id,
      onClose: () => {
        if (enableToDelete) {
          deleteItem(item.id)
        }
      },
    })
  }

  const duplicate = () => {
    mixpanel.track('open_btn_click')
    setPreview({
      name: item.name,
      title: item.title,
      body: item.body,
      image: item.image,
      link: item.link,
      hash: item.hash,
    })
    history.push('/builder')
  }

  const archivatePreview = () => {
    archive(item.id)
  }

  const restorePreview = () => {
    restore(item.id)
  }

  const showStats = () => {
    mixpanel.track('show_statistic_click')
    setSelectedItemId && setSelectedItemId(item.id)
  }

  const toggleFavorite = () => {
    mixpanel.track('toggle_preview_favorite')
    updateItemFavorite({ id: item.id, value: !item.favorite })
  }

  const getCreatedDate = (date: string) => new Date(date).toLocaleString('en-GB', { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })

  const savePreviewName = useCallback(async (value: string) => {
    try {
      await coreApi.patchPreviewName({ id: item.id, value })
      const newHistoryContent = historyContent.map((historyItem) => {
        return historyItem.id !== item.id ? historyItem : {
          ...historyItem,
          name: value,
        }
      })
      setHistoryContent(newHistoryContent)
      setPreviewNameEditable(false)
    } catch (error) {
      console.error(error)
      toast.error('Something went wrong. Try again later.', {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
      })
    }
  }, [coreApi, item, historyContent, setHistoryContent, setPreviewNameEditable])

  return(
    <div key={item.id} className={classes.root}>
      <div className={ classnames({ [classes['heading-group']]: true, [classes.editable]: isPreviewNameEditable }) }>
        { !isPreviewNameEditable ?
          <>
            <Tippy
              content={ item.name }
              placement="top-start"
            >
              <h2>{ item.name }</h2>
            </Tippy>
            <button
              type="button"
              className={ buttonClasses['btn-icon-only'] }
              aria-label="Edit preview name"
              onClick={ () => setPreviewNameEditable(true) }
            ><IcoEdit /></button>
          </> :
          <>
            <input
              className={ inputStyles.input }
              style={{ padding: '8px 12px', fontSize: '14px', lineHeight: '16px' }}
              type="text"
              name="preview-name"
              placeholder="Preview name"
              maxLength={ 255 }
              id="preview-name"
              value={ previewName }
              autoFocus
              onChange={ (e) => setPreviewName(e.target.value) }
            />
            <button
              type="button"
              className={ buttonClasses['btn-icon-only'] }
              aria-label="Save changes"
              title="Save changes"
              onClick={ () => savePreviewName(previewName) }
            ><IcoSave /></button>
            <button
              type="button"
              className={ buttonClasses['btn-icon-only'] }
              aria-label="Cancel changes"
              title="Cancel changes"
              onClick={ () => {
                setPreviewName(item.name)
                setPreviewNameEditable(false)
              }}
            ><IcoClose /></button>
          </>
        }
        <div className={classes.actions}>
          <Tippy content="Add to favorites" placement="top">
            <button type="button" onClick={toggleFavorite} className={classes['caption-action']}>
              <IcoFavorite className={classes.icon} enabled={item.favorite}/>
            </button>
          </Tippy>
          {status === 'ACTIVE' ? <Tippy content="Statistic" placement="top">
            <button type="button" onClick={showStats} className={classes['caption-action']}>
              <IcoStats className={classes.icon}/>
              {/* <span>Statistic</span> */}
            </button>
          </Tippy> : null}
          <Tippy content="Duplicate" placement="top">
            <button type="button" onClick={duplicate} className={classes['caption-action']}>
              <IcoDuplicate className={classes.icon}/>
              {/* <span>Duplicate</span> */}
            </button>
          </Tippy>
          {status === 'ACTIVE' ? 
            <Tippy content="Move to Archive" placement="top">
              <button type="button" onClick={archivatePreview} className={classes['caption-action']}>
                <IcoArchive className={classes.icon}/>
                {/* <span>Delete</span> */}
              </button>
            </Tippy> : 
            <Tippy content="Restore" placement="top">
              <button type="button" onClick={restorePreview} className={classes['caption-action']}>
                <IcoArchive className={classes.icon}/>
                {/* <span>Delete</span> */}
              </button>
            </Tippy>}
          <Tippy content="Delete" placement="top">
            <button type="button" onClick={deletePreview} className={classes['caption-action']}>
              <IcoDelete className={classes.icon}/>
              {/* <span>Delete</span> */}
            </button>
          </Tippy>
        </div>
      </div>
      <div className={classes.preview} style={{ cursor: status === 'ACTIVE' ? 'pointer' : 'default' }}>
        <div className={classes.image}>
          <img alt="preview" src={item.image}/>
        </div>
        <div className={classes.preview_caption}>
          <p className={classes.title}>{item.title}</p>
          <p className={classes.secondary}>{item.body}</p>
        </div>
        {status === 'ACTIVE' ? <div className={classes.overlay_container} onClick={showStats}>
          <Button isActive={true} btn_class={classes.overlay_button} onClick={console.log}>See stats</Button>
          <div className={classes.overlay}></div>
        </div> : null}
      </div>
      <div className={classes.caption}>
        <span className={classes.label}>Author</span>
        <span className={classes.content}>{item.createdBy}</span>
        <span className={classes.label}>Destination</span>
        <Tippy
          content={ item.link }
          placement="top-start"
        >
          <a
            href={ item.link }
            target="_blank"
            rel="noopener noreferrer"
            className={classnames(classes.target_link, classes.content)}
          >{ item.link }</a>
        </Tippy>
        {/* <span className={classes.label}>Updated at</span> */}
        {/* <span className={classes.content}>{getCreatedDate(item.updatedAt)}</span> */}
      </div>
      <InputWithAction btn_styles={{ backgroundColor:'#40466e' }} inputText={`${item.domain}/${item.hash}`} inputDescription="Preview Link" action={copyLinkToClipboard} buttonText="Copy"/>
      <div className={classes['preview-meta']}>
        <div>
          <span>{getCreatedDate(item.createdAt)}</span>
          <span className={classes.label}>
              Generated
          </span>
        </div>
        <div>
          <span>
            {item.stat.totalClicks}
          </span>
          <span className={classes.label}>Total clicks</span>
        </div>
      </div>
    </div>
  )}
