import React, { FC, useState, useEffect } from 'react'

import { PreviewProps } from '../PreviewList'
import classes from '../WhatsappPreview/WhatsappPreview.module.css'


export const WhatsappPreview: FC<PreviewProps> = ({ title, body, link, image }) => {
  const [url, setUrl] = useState<URL>()
  useEffect(() => {
    try {
      setUrl(new URL(link))
    } catch (error) {
      setUrl(undefined)
    }
  }, [link])
  return(
    <div className={classes['card-seo-whatsapp']}>
      <div className={classes['card-seo-whatsapp__image']} style={{ backgroundImage: `url(${image})` }}></div>
      <div className={classes['card-seo-whatsapp__text']}>
        <div className={classes['card-seo-whatsapp__title']}>{title}</div>
        <div className={classes['card-seo-whatsapp__body']}>{body}</div>
        <span className={classes['card-seo-whatsapp__link']}>{url ? url.host : ''}</span>
      </div>
    </div>
  )}
