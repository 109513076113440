import React, { useState, useEffect, useCallback } from 'react'
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  Redirect,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import './components/shared/toastify/toastify.css'

import classes from './App.module.css'
import { UserData } from './apis/core'
import { AppSumoRedemption } from './components/AppSumoRedemption/AppSumoRedemption'
import { AuthForm } from './components/AuthForm/AuthForm'
import { EmailConfirmationModal } from './components/EmailConfirmationModal/EmailConfirmationModal'
import { Header } from './components/Header/Header'
import { ResetPassForm } from './components/ResetPassForm/ResetPassForm'
import { Modal } from './components/shared/modal/Modal'
import { BrandedModal } from './components/shared/modal/branded-modal/BrandedModal'
import { useAuth } from './hooks/useAuth'
import { useCoreApi } from './hooks/useCoreApi'
import { Preview } from './models/Preview'
import { AccountSettings } from './pages/AccountSettings/AccountSettings'
import { Analytics } from './pages/Analytics/Analytics'
import { Billing } from './pages/Billing/Billing'
import { Dashboard } from './pages/Dashboard/Dashboard'
import { MemberSetPass } from './pages/MemberSetPass/MemberSetPass'
import { PreviewBuilder } from './pages/PreviewBuilder/PreviewBuilder'
import { PreviewsComparing } from './pages/PreviewsComparing/PreviewsComparing'
import { StatsSharing } from './pages/StatsSharing/StatsSharing'


export const App: React.FC = () => {

  const { replace } = useHistory()

  const location = useLocation()

  const [selectedTab, setSelectedTab] = useState(
    location.search.includes('login') ? 'signup' : 'login'
  )

  const { 
    setShowAuthForm, 
    setShowConfirmMessage, 
    setError,
    token, 
    signout, 
    showAuthForm, 
    authoriseWithProvider, 
  } = useAuth()

  const coreApi = useCoreApi()

  const currentURLParams = useCallback(() => new URLSearchParams(window.location.search), [])

  const [predefinedPreview, setPredefinedPreview] = useState<Preview>()

  const [user, setUser] = useState<UserData | null>(null)

  const { setLoginFromGenerateButton } = useAuth()

  const [showResetPassFrom, setResetPassForm] = useState(
    location.pathname === '/reset' &&
      currentURLParams().has('hash')
  )

  const closeAuthModal = useCallback(() => {
    setShowAuthForm(false)
    setShowConfirmMessage(false)
    setError('')
    setLoginFromGenerateButton(false)
  }, [
    setShowAuthForm,
    setShowConfirmMessage,
    setError,
    setLoginFromGenerateButton,
  ])
  
  const closeResetPassModal = useCallback(() => {
    replace('/')
    setResetPassForm(false)
  }, [replace])
  
  useEffect(() => {
    if (currentURLParams().has('login') && !token) {
      setShowAuthForm(true)
      replace('/builder')
    }
  }, [setShowAuthForm, token, replace, currentURLParams])

  useEffect(() => {
    (async () => {
      if (token && !(currentURLParams().has('provider'))) {
        try {
          const currentUser = await coreApi.getUserData(token)

          if ((currentUser.status === 'ACTIVE' || currentUser.status === 'NEW') && !currentUser.subscription ) {
            setUser(await coreApi.startTrial('basic'))
          } else {
            setUser(currentUser)
          }
        } catch (error) {
          if (error === 401) {
            signout()
            setShowAuthForm(true)
          } else {
            console.log(error)
          }
        }
      }
    })()
  }, [coreApi, token, setUser, setShowAuthForm, signout, currentURLParams])

  useEffect(() => {
    (async () => {
      if(currentURLParams().has('provider') && currentURLParams().has('token') && location.pathname === '/'){
        console.log(currentURLParams().toString())
        const provider = currentURLParams().get('provider') || ''
        const token = currentURLParams().get('token') || ''
        try {
          const user = await authoriseWithProvider({ provider, token })
          setUser(user)
        } catch (error) {
          console.log(error)
        } 
        return replace('/')
      }
    })()
  }, [replace, authoriseWithProvider, location.pathname, currentURLParams])

  return (
    <div className={classes.root}>
      <Switch>
        <Route exact path="/"
          render={() => token
            ? <Redirect to={{
              pathname: '/dashboard',
              search: location.search,
            }}/>
            : <Redirect to={{
              pathname: '/builder',
              search: location.search,
            }}/>} />
        <Route
          path="/profile"
          render={() => token
            ? <>
              <Header
                setPreview={setPredefinedPreview}
                subscription={user?.subscription}
                setSelectedTab={setSelectedTab}
              />
              <AccountSettings user={user} setUser={setUser} />
            </>
            : <Redirect to={{
              pathname: '/builder',
              search: location.search,
            }} />
          }
        />
        <Route
          path="/billing"
          render={() => token
            ? <><Header
              setPreview={setPredefinedPreview}
              subscription={user?.subscription}
              setSelectedTab={setSelectedTab}
            /><Billing user={user} setUser={setUser}/></>
            : <Redirect to={{
              pathname: '/builder',
              search: location.search,
            }} />
          }
        />
        <Route path='/builder'>
          <>
            <Header
              setPreview={setPredefinedPreview}
              subscription={user?.subscription}
              setSelectedTab={setSelectedTab}
            />
            <PreviewBuilder
              user={user}
              setUser={setUser}
              predefinedPreview={predefinedPreview}
            />
          </>
        </Route>
        <Route
          path="/dashboard"
          render={() => token
            ? <>
              <Header
                setPreview={setPredefinedPreview}
                subscription={user?.subscription}
                setSelectedTab={setSelectedTab}
              />
              <Dashboard user={user} setUser={setUser} setPreview={setPredefinedPreview}/>
            </>
            : <Redirect to={{
              pathname: '/builder',
              search: location.search,
            }} />
          }
        />
        <Route path='/reset'>
          <>
            <Header
              setPreview={setPredefinedPreview}
              subscription={user?.subscription}
              setSelectedTab={setSelectedTab}
            />
            <PreviewBuilder
              user={user}
              setUser={setUser}
              predefinedPreview={predefinedPreview}
            />
          </>
        </Route>
        <Route path="/analytics">
          <>
            <Header
              setPreview={setPredefinedPreview}
              subscription={user?.subscription}
              setSelectedTab={setSelectedTab}
            />
            <Analytics></Analytics>
          </>
        </Route>
        <Route path="/appsumo">
          <AppSumoRedemption/>
        </Route>
        <Route path="/sharing/:hash">
          <StatsSharing/>
        </Route>
        <Route path="/comparing">
          <>
            <Header
              setPreview={setPredefinedPreview}
              subscription={user?.subscription}
              setSelectedTab={setSelectedTab}
            />
            <PreviewsComparing />
          </>
        </Route>
        <Route path="/member_flow/:hash">
          <MemberSetPass/>
        </Route>
        <Route>
          <Redirect to={{
            pathname: '/',
          }}/>
        </Route>
      </Switch>

      <EmailConfirmationModal/>

      <BrandedModal
        styles={{
          backgroundColor: '#40466E',
          overflow: 'hidden',
          padding: '12px',
        }}
        visible={showAuthForm}
        onCloseAttempt={closeAuthModal}
      >
        <AuthForm
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          onCloseAuthForm={closeAuthModal}
        />
      </BrandedModal>

      <Modal
        styles={{
          backgroundColor: '#40466E',
          overflow: 'hidden',
          padding: '12px',
        }}
        visible={showResetPassFrom}
        onCloseAttempt={closeResetPassModal}
      >
        <ResetPassForm
          hash={new URLSearchParams(window.location.search).get('hash') || ''}
          onCloseForm={closeResetPassModal}
        />
      </Modal>

      <ToastContainer position='bottom-left' />
    </div>
  )
}
