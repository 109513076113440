import classnames from 'classnames'
import mixpanel from 'mixpanel-browser'
import React, { useState, useCallback, useEffect, useRef } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { UserData } from 'src/apis/core'
import { Callback } from 'src/common'
import { Channels } from 'src/components/Channels/Channels'
import { ImageEditor } from 'src/components/ImageEditor/ImageEditor'
import { InputWithAction } from 'src/components/InputWithAction/InputWithAction'
import { PreviewBuilderForm } from 'src/components/PreviewBuilderForm/PreviewBuilderForm'
import { PreviewList } from 'src/components/PreviewList/PreviewList'
import { RequiredDomain } from 'src/components/RequiredDomain/RequiredDomain'
import { Button } from 'src/components/shared/button/Button'
import { Modal } from 'src/components/shared/modal/Modal'
import { BrandedModal } from 'src/components/shared/modal/branded-modal/BrandedModal'
import { useAuth } from 'src/hooks/useAuth'
import { useCoreApi } from 'src/hooks/useCoreApi'
import { useOrderedSet } from 'src/hooks/useOrderedSet'
import { Channel } from 'src/models/Channels'
import { Domain } from 'src/models/Domain'
import { Preview } from 'src/models/Preview'

import icoClosed from '../../assets/icons/ico-closed.svg'
import icoIncorrect from '../../assets/icons/ico-incorrect.svg'

import classes from './PreviewBuilder.module.css'


interface Props {
  predefinedPreview?: Preview
  user: UserData | null
  setUser?: Callback<UserData | null>
}

export const placeholderPreview: Preview = {
  name: 'Untitled',
  title: 'Piar.io — No-code previews generator',
  body:
    'With Piar.io you can edit and experiment with your content then preview how your webpage will look on Facebook, Twitter, Telegram, and more!',
  image: 'https://cdn.piar.io/images/197/53mV6-preview-placeholder.png',
  link: 'https://app.piar.io',
  hash: '',
}

export const PreviewBuilder: React.FC<Props> = ({
  predefinedPreview,
  user,
}) => {
  const coreApi = useCoreApi()

  const auth = useAuth()

  const [previewImgURL, setPreviewImgURL] = useState('')

  const [availableChannels, selectedChannels] = useOrderedSet({
    allValues: [
      Channel.facebook,
      Channel.telegram,
      Channel.slack,
      Channel.twitter,
      Channel.viber,
      Channel.pinterest,
      Channel.linkedin,
      Channel.instagram,
      Channel.whatsapp,
    ],
    initiallySelectedValues: [
      Channel.facebook,
      Channel.telegram,
      Channel.slack,
      Channel.twitter,
      Channel.viber,
      Channel.pinterest,
      Channel.linkedin,
      Channel.instagram,
      Channel.whatsapp,
    ],
  })

  const facebookRef = useRef<HTMLElement | null>(null)
  const telegramRef = useRef<HTMLElement | null>(null)
  const slackRef = useRef<HTMLElement | null>(null)
  const twitterRef = useRef<HTMLElement | null>(null)
  const viberRef = useRef<HTMLElement | null>(null)
  const pinterestRef = useRef<HTMLElement | null>(null)
  const linkedinRef = useRef<HTMLElement | null>(null)
  const instagramRef = useRef<HTMLElement | null>(null)
  const whatsappRef = useRef<HTMLElement | null>(null)

  const [selectedChannel, setSelectedChannel] = useState<number>()

  const channelsRefsList = [
    facebookRef,
    telegramRef,
    slackRef,
    twitterRef,
    viberRef,
    pinterestRef,
    linkedinRef,
    instagramRef,
    whatsappRef,
  ]

  type FormValues = {
    title: string
    body: string
    name: string
    link: string
    hash: string
    // image: string
  }

  const [emptyFetch, setEmptyFetch] = useState(false)

  const [modalVisible, setModalVisible] = useState(false)

  const [requiredDomainModal, setRequiredDomainModal] = useState(false)

  const [editorInitImg, setEditorInitImg] = useState<any>()

  const [domainsList, setDomainsList] = useState<Domain[]>([])

  const [selectedDomain, setSelectedDomain] = useState<Domain>()

  const history = useHistory()

  const defaultPreview = () => {
    if (predefinedPreview) {
      return {
        name: predefinedPreview.name,
        title: predefinedPreview.title,
        body: predefinedPreview.body,
        link: predefinedPreview.link,
        hash: predefinedPreview.hash,
        // image: predefinedPreview.image,
      }
    } else {
      return {
        name: placeholderPreview.name,
        title: placeholderPreview.title,
        body: placeholderPreview.body,
        link: placeholderPreview.link,
        hash: placeholderPreview.hash,
        // image: '',
      }
    }
  }

  const methods = useForm<FormValues>({ defaultValues: defaultPreview() })

  const { watch } = methods

  const wrappedWatch = useCallback(watch, [])

  const onSubmit = (data: any) =>
    selectedDomain
      ? generatePreview({
        ...data,
        image: previewImgURL,
        domainId: selectedDomain.id,
      })
      : generatePreview({ ...data, image: previewImgURL })

  const closeModal = () => {
    methods.setValue('image', editorInitImg)
    setModalVisible(false)
  }

  const savePreviewToLocal = async (preview: Preview) => {
    if (!preview.image.length) {
      preview.image = placeholderPreview.image
    }
    localStorage.setItem('preview', JSON.stringify(preview))
    localStorage.setItem('generationPromise', 'true')
  }

  const onPreviewGenerateSuccess = useCallback(() => {
    // await coreApi.generatePreview(preview)
    history.push('/dashboard')
    toast.info('Preview generated', {
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
    })
  }, [history])

  const generatePreview = useCallback(
    async (preview: Preview) => {
      if (!auth.token) {
        savePreviewToLocal(preview)
        auth.setLoginFromGenerateButton(true)
        return auth.setShowAuthForm(true)
      }

      // if (user?.status === 'ACTIVE') {
      //   return history.push('/billing')
      // }

      if (
        !domainsList.length || 
        !selectedDomain
      ) {
        return setRequiredDomainModal(true)
      }

      if (
        user?.status === 'CLIENT' &&
        user.subscription.expiredAt.getTime() < Date.now()
      ) {
        return history.push('/billing')
      }

      if (!preview.image.length) {
        preview.image = placeholderPreview.image
      }
      try {
        if(preview.image && preview.image.startsWith('data:')){
          const file = new File(
            [await (await fetch(preview.image)).blob()],
            'userImage.jpeg',
            { type: 'image/jpeg' },
          )
          const { url } = await coreApi.uploadFile(file)
          if(selectedDomain?.id){
            await coreApi.generatePreview({ ...wrappedWatch(), image: url, domainId: selectedDomain.id })
          } else {
            await coreApi.generatePreview({ ...wrappedWatch(), image: url })
          }
          setPreviewImgURL(url)
        } else {
          await coreApi.generatePreview(preview)
        }
        onPreviewGenerateSuccess()
      } catch (error) {
        if (error?.error === 'This hash has already been used!') {
          methods.setError('hash', {
            type: 'manual',
            message: 'This custom link is taken',
          })
          return
        }
        if (error === 401) {
          savePreviewToLocal(preview)
          auth.signout()
          auth.setShowAuthForm(true)
          return
        }
        if (error?.error === 'Subscription expired!') {
          history.push('/billing')
          return
        }
        
        toast.error(error?.error || 'Something went wrong', {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
        })
        return
      }
    },
    [auth, user, history, onPreviewGenerateSuccess, coreApi, selectedDomain, wrappedWatch, methods, domainsList],
  )

  useEffect(() => {
    predefinedPreview && setPreviewImgURL(predefinedPreview.image)
  }, [predefinedPreview, setPreviewImgURL])

  useEffect(() => {
    (async () => {
      if (auth.token) {
        const domains = await coreApi.getDomainsList(auth.token)
        setDomainsList(domains)
      }
    })()
  }, [coreApi, auth.token])

  const editImage = (e: any) => {
    e.preventDefault()
    mixpanel.track('edit_image_click')
    setEditorInitImg(previewImgURL)
    setModalVisible(true)
  }

  const trackEditPreviewNameClick = () => {
    mixpanel.track('edit_preview_name')
  }

  return (
    <>
      <FormProvider {...methods}>
        <Modal
          visible={modalVisible}
          styles={{ width: '100%', maxWidth: '688px' }}
          onCloseAttempt={closeModal}
        >
          <div className={classes['modal-content']}>
            <button
              type="button"
              onClick={closeModal}
              className={classes['close-modal']}
              aria-label="Close modal"
            >
              <img src={icoClosed} alt="Close modal" width="18" />
            </button>

            <h4 className={classes.modal_caption}>Upload preview picture</h4>

            <ImageEditor
              setModalVisible={setModalVisible}
              setImageURL={setPreviewImgURL}
              imageURL={previewImgURL}
            />
          </div>
        </Modal>
        <BrandedModal
          visible={requiredDomainModal}
          onCloseAttempt={() => setRequiredDomainModal(false)}
          styles={{
            backgroundColor: '#40466E',
            overflow: 'hidden',
            padding: '12px',
          }}
        >
          <RequiredDomain onSaveTemplate={() => setRequiredDomainModal(false)} />
        </BrandedModal>
        <div className={classes.root}>
          <div className={classes.sticky_wrapper}>
            <form
              className={classes.metadata}
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <div className={classes['title-wrapper']}>
                <div className={classes.title}>
                  <h2>Create Preview</h2>
                  <span className={classes.secondary_title}>
                    Customize your preview link
                  </span>
                </div>
                <div
                  className={classnames(classes['information-toast'], {
                    [classes.hidden]: !emptyFetch,
                  })}
                >
                  <img alt="incorrect" src={icoIncorrect} />
                  <span>
                    Destination link includes incorrect data. Reason: additional link protection by the site owner. 
                    Please customize parametrs manualy
                  </span>
                </div>
              </div>
              <div className={classes.form_wrapper}>
                <PreviewBuilderForm
                  setEmptyFetch={setEmptyFetch}
                  setPreviewImgURL={setPreviewImgURL}
                  domains={domainsList}
                  setSelectedDomain={setSelectedDomain}
                  selectedDomain={selectedDomain}
                  onAddDomain={() => setRequiredDomainModal(true)}
                />
                <Button btn_class={classes.btn} onClick={editImage}>
                  <svg
                    style={{ fill: '#fff', height: '12px', marginRight: '8px' }}
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 1000 1000"
                    enableBackground="new 0 0 1000 1000"
                  >
                    <g>
                      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                        <path d="M7897.8,4996.3c-262.5-65.1-65.1,124.5-3609.2-3419.6L999.3-1710.6l-454-1367.8c-385.1-1157.1-454-1377.4-444.4-1444.4c17.2-132.2,124.5-239.5,256.7-256.7c67.1-9.6,287.4,59.4,1444.5,444.5l1367.8,454l3252.9,3251C8212,1157.2,9704.3,2662.9,9738.8,2714.7c189.6,285.4,212.6,632.2,65.1,948.3c-51.7,109.2-122.6,189.7-572.8,641.8c-333.3,335.3-553.7,542.1-624.5,584.3C8409.3,5009.7,8127.7,5051.9,7897.8,4996.3z M8265.6,4373.7c105.4-49.8,956-906.1,996.2-1001.9c36.4-90,36.4-170.5-1.9-260.5c-19.2-46-183.9-226.1-463.6-507.7l-434.9-434.9l-655.2,655.2L7049.1,3481l427.2,427.2c247.1,249,457.9,442.5,501.9,463.6C8074.1,4419.7,8166,4419.7,8265.6,4373.7z M7269.5,2379.4l651.3-651.3L5386.3-806.4L2853.7-3339l-969.4-323.8c-532.6-176.2-973.2-318-978.9-312.3c-5.7,5.7,134.1,444.5,310.4,975.1l319.9,963.6L4068.3,498.2c1390.8,1392.7,2534.5,2532.6,2540.2,2532.6S6911.2,2737.7,7269.5,2379.4z" />
                      </g>
                    </g>
                  </svg>
                  {previewImgURL.length ? 'Edit image' : 'Add image'}
                </Button>
              </div>
              <div className={classes.footer_wrapper}>
                <div className={classes.footer}>
                  <InputWithAction
                    name="name"
                    {...methods}
                    type="submit"
                    inputText={methods.watch().name}
                    inputDescription="Preview Name"
                    buttonText="Generate Link"
                    onInputFieldClick={trackEditPreviewNameClick}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className={classes.preview_container}>
            <div className={classes.preview}>
              <div>
                <div className={classes.title}>
                  <h2>Show Preview</h2>
                  <span className={classes.secondary_title}>
                    Click on icon to show/hide preview
                  </span>
                </div>
                <div className={classes.preview_content}>
                  <Channels
                    allChannels={availableChannels}
                    // activeChannels={selectedChannels}
                    onClick={async channel => {
                      channelsRefsList[channel].current?.scrollIntoView({
                        behavior: 'smooth',
                        block: window.innerWidth > 768 ? 'center' : 'end',
                      })
                      setSelectedChannel(channel)
                      setTimeout(setSelectedChannel, 1000)
                    }}
                  />
                  <PreviewList
                    channels={selectedChannels}
                    refsList={channelsRefsList}
                    selectedChannel={selectedChannel}
                    title={methods.watch().title}
                    body={methods.watch().body}
                    image={
                      previewImgURL.length
                        ? previewImgURL
                        : placeholderPreview.image
                    }
                    link={methods.watch().link}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </FormProvider>
    </>
  )
}
