import Pagination from '@material-ui/lab/Pagination'
import mixpanel from 'mixpanel-browser'
import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { HistoryResponse, UserData } from 'src/apis/core'
import { Callback } from 'src/common'
import { HistoryItemCard } from 'src/components/HistoryItemCard/HistoryItemCard'
import { StatisticModal } from 'src/components/StatisticModal/StatisticModal'
import { Button } from 'src/components/shared/button/Button'
import { useAuth } from 'src/hooks/useAuth'
import { useCoreApi } from 'src/hooks/useCoreApi'
import { Preview } from 'src/models/Preview'
import { PreviewHistoryItem } from 'src/models/PreviewHistoryItem'

import icoHistory from '../../assets/icons/ico-history.svg'
import { placeholderPreview } from '../PreviewBuilder/PreviewBuilder'

import classes from './History.module.css'


interface Props {
  setPreview: Callback<Preview>
  user: UserData | null
  setUser: Callback<UserData | null>
  status: 'ACTIVE' | 'ARCHIVED'
  favorite?: boolean
  setProgress: Callback<{active: number, limit: number}>
  page: number
  setPage: Callback<number>
  selectedAuthors: string[]
  name: string | undefined
  dateRange: Date[]|undefined
}

const getDate = (date: any) => {
  const dd = String(date.getDate()).padStart(2, '0')
  const mm = String(date.getMonth() + 1).padStart(2, '0') //January is 0!
  const yyyy = date.getFullYear()
  date = yyyy + '-' + mm + '-' + dd
  return date
}

export const History: React.FC<Props> = ({ setPreview, user, status, favorite, setProgress, page, setPage, selectedAuthors, name, dateRange }) => {
  const [content, setContent] = useState<PreviewHistoryItem[]>([])
  const [historyResponse, setHistoryResponse] = useState<HistoryResponse>()
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null)
  // const [page, setPage] = React.useState(0)
  const [error, setError] = useState({ description: '', error: '', status: '' })
  const coreApi = useCoreApi()
  const { signout, setShowAuthForm, token } = useAuth()
  const [isHistoryLoading, setIsHistoryLoading] = useState(false)
  const history = useHistory()

  const handlePageChange = useCallback((_, value: React.SetStateAction<number>) => {
    setPage(Number(value) - 1)
  }, [setPage])

  useEffect(() => {
    setPage(0)
  }, [setPage, status])

  const createNewPreview = () => {
    setPreview({ ...placeholderPreview, image: '' })
    localStorage.removeItem('preview')
    mixpanel.track('create_new_empty_history_btn_click')
    history.push('/builder')
  }

  const generatePreviewSuccess = useCallback(async (preview: Preview) => {
    await coreApi.generatePreview(preview)
    toast.info('Preview generated', {
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: false,
    })
  }, [coreApi])

  const updateHistory = useCallback(() => coreApi
    .getHistory({ page, status, favorite, name, author: selectedAuthors, from: dateRange && getDate(dateRange[0]), to: dateRange && getDate(dateRange[1]) })
    .then(history => {
      setContent(history.content)
      setHistoryResponse(history) 
      coreApi
        .getPreviewsProgress()
        .then(setProgress)
    }), [coreApi, page, setProgress, status, name, favorite, selectedAuthors, dateRange])

  useEffect(() => {
    (async () => {
      if(token){
        setIsHistoryLoading(true)
        if(localStorage.getItem('generationPromise')){
          const preview = localStorage.getItem('preview') ? (JSON.parse(localStorage.getItem('preview') as any)) : null
          if (preview){
            if(preview.image && preview.image.startsWith('data:')){
              try {
                const file = new File([(await (await fetch(preview.image)).blob())], 'userImage.jpeg', { type: 'image/jpeg' })
                const { url } = await coreApi.uploadFile(file)
                preview.image = url
                await generatePreviewSuccess(preview)
              } catch (error) {
                console.log(error)
                localStorage.removeItem('generationPromise')
              }
            } else {
              try {
                await generatePreviewSuccess(preview)
              } catch (error) {
                console.log(error)
                localStorage.removeItem('generationPromise')
              }
            }
          }
        }

        try {
          await updateHistory()
          setError({ description: '', error: '', status: '' })
        } catch (error) {
          if(error === 401){
            signout()
            setShowAuthForm(true)
          } else {
            setError(error)
          }
        } finally {
          setIsHistoryLoading(false)
        }
      }
    })()
  }, [coreApi, setShowAuthForm, page, signout, token, generatePreviewSuccess, status, updateHistory])

  const deleteItem = useCallback((id: number) => {
    coreApi.removePreview(id).then(() => {
      setContent(content.filter((i) => i.id !== id))
      setSelectedItemId(null)
      updateHistory()
    })
  }, [content, coreApi, updateHistory])

  const updateItemFavorite = useCallback(({ id, value } : {id: number, value: boolean}) => {
    coreApi.patchPreviewFavorite({ id, value }).then(() => {
      // setContent(content.filter((i) => i.id !== id))
      setSelectedItemId(null)
      // toast.info('Preview was moved to archive', {
      //   autoClose: 3000,
      //   hideProgressBar: false,
      //   closeOnClick: false,
      // })
      updateHistory()
    })
      .catch(err => console.log(err))
  }, [coreApi, updateHistory])

  const archivatePreview = useCallback((id: number) => {
    coreApi.archivePreview(id).then(() => {
      setContent(content.filter((i) => i.id !== id))
      setSelectedItemId(null)
      toast.info('Preview was moved to archive', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
      })
      updateHistory()
    })
  }, [content, coreApi, updateHistory])

  const restorePreview = useCallback((id: number) => {
    coreApi.restorePreview(id)
      .then(() => {
        setContent(content.filter((i) => i.id !== id))
        setSelectedItemId(null)
        toast.info('Preview was restored', {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
        })
        updateHistory()
      })
      .catch((error) => {
        if (error.error) {
          toast.error(error.error, {
            autoClose: 5000,
            closeOnClick: true,
            hideProgressBar: false,
          })
        }
      })
  }, [content, coreApi, updateHistory])

  return (
    <>
      <div className={classes.root}>
        {content && content.length ? <div className={classes.items_wrapper}>
          {content.map(item => (
            <HistoryItemCard
              setSelectedItemId={setSelectedItemId}
              setPreview={setPreview}
              item={item}
              key={item.id}
              deleteItem={deleteItem}
              updateItemFavorite={updateItemFavorite}
              setHistoryContent={ setContent }
              historyContent={ content }
              status={status}
              archive={archivatePreview}
              restore={restorePreview}
            />
          ))}
        </div> : isHistoryLoading ?
          <div className={classes['empty-history']}>
            <div>
              <img src={icoHistory} alt="history"/>
            </div>
            <p>Loading...</p>
          </div> :
          <div className={classes['empty-history']}>
            <div>
              <img src={icoHistory} alt="history"/>
            </div>
            <p>History is empty</p>
            <Button btn_class={classes.btn} isActive={true} onClick={createNewPreview}>+ Add a preview</Button>
          </div>}
        {error.error && (<p style={{ color: '#dc483a', fontSize: '12px', marginTop: '4px', textTransform: 'none' }}>{error.error}</p>)}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {historyResponse && (historyResponse.totalPages > 1 && (<Pagination count={historyResponse.totalPages} page={page + 1} onChange={handlePageChange} />)) }
        </div>
      </div>
      {content && content.find( item => item.id === selectedItemId) && <StatisticModal
        user={user}
        item={content.find( item => item.id === selectedItemId) as PreviewHistoryItem}
        setItemId={setSelectedItemId}
        deleteItem={deleteItem}
        setPreview={setPreview}
        archive={archivatePreview}
        setHistoryContent={ setContent }
        historyContent={ content }
        updateHistory={updateHistory}
      />}
    </>
  )
}
