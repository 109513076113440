import React, { FC } from 'react'

import { PreviewProps } from '../PreviewList'
import classes from '../TwitterPreview/TwitterPreview.module.css'


export const TwitterPreview: FC<PreviewProps> = ({ title, body, link, image }) => (
  <div className={classes.card_seo_twitter}>
    <div
      className={classes.card_seo_twitter__image}
      key={image}
      style={{ backgroundImage: `url(${image})` }}
    ></div>
    <div className={classes.card_seo_twitter__text}>
      <span className={classes.card_seo_twitter__title}>{title}</span>
      <span className={classes.card_seo_twitter__description}>
        {body}
      </span>
      <span className={classes.card_seo_twitter__link}>{link}</span>
    </div>
  </div>
)
