export function registerUserInGAByID(id: number) {
  (window as any).gtag('config', 'UA-176722513-1', {
    custom_map: { dimension1: id },
  })
  ;(window as any).gtag('set', { user_id: id })
}

export function trackPaymentIntentToGA(userId: number | undefined) {
  (window as any).gtag('event', 'payment_intent', { dimension4: userId } )
}
