import { ResponsivePie } from '@nivo/pie'
import React from 'react'

import { StatisticItem } from 'src/models/Statistic'


interface Props {
    data: StatisticItem[] | null
}

const mockData = [
  { id:'No data', value: 1 },
  { id:'No data ', value: 2 },
  { id:' No data ', value: 3 },
  // { id:'Android', value: 25 },
  // { id:'iOS', value: 7 },
  // { id:'Linux', value: 3 },
  // { id:'Other', value: 5 },
]

export const Pie: React.FC<Props> = ({ data }) => {
  let newData
  if(data){
    if(data.length <= 5){
      newData = data.sort((a, b) => b.amount - a.amount)
    } else {
      newData = data.sort((a, b) => b.amount - a.amount).slice(0, 4).concat(data.sort((a, b) => b.amount - a.amount).slice(4).reduce((acc, d) => {
        return ({ value: 'Other', amount: acc.amount + d.amount })
      }, ({ value: 'Other', amount: 0 })))
    }
  }
  return (
    <ResponsivePie data={newData ? newData.map( i => ({ id: i.value.toLowerCase(), value: i.amount })) : mockData} 
      margin={{ left:0, right: 100, bottom: 20 }} padAngle={2}
      enableSlicesLabels={!!newData}
      colors={{ scheme: 'set2' }} 
      radialLabelsSkipAngle={20}
      slicesLabelsSkipAngle={20}
      cornerRadius={1} innerRadius={0.4} enableRadialLabels={false}   radialLabelsLinkDiagonalLength={3} radialLabelsLinkHorizontalLength={0}
      theme={{ legends: { text: { fontFamily: 'Roboto', textTransform: 'capitalize', fontSize: 13 } } }}
      legends={[
        {
          anchor: 'top-right',
          direction: 'column',
          translateX: 120,
          translateY: 20,
          itemWidth: 110,
          itemHeight: 18,
          itemTextColor: '#999',
          symbolSize: 14,
          symbolShape: 'circle',
        },
      ]}/>
  )} 
