import React from 'react'


export const IcoSuccess = () => (
  <svg
    style={{ marginBottom: '10px' }}
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width='100px'
    height='100px'
    viewBox='0 0 215.434 215.434'
    enableBackground='new 0 0 215.434 215.434'
  >
    <g>
      <circle
        fill='#54C147'
        cx='107.717'
        cy='107.717'
        r='106.299'
      />
      <polygon
        fill='#FFFFFF'
        points='96.977,121.718 145.084,50.79 168.752,69.02 103.583,164.647 46.678,120.299 63.562,96.402  '
      />
    </g>
  </svg>
)
