import classnames from 'classnames'
import React, { FC, useEffect, useState } from 'react'

import classes from '../FacebookPreview/FacebookPreview.module.css'
import { PreviewProps } from '../PreviewList'



export const FacebookPreview: FC<PreviewProps> = ({ title, body, link, image }) => {
  const [previewType, setPreviewType] = useState<'big' | 'small'>('big')
  const [imageAlowed, setImageAlowed] = useState(true)
  const imageInstance = new Image()
  imageInstance.src = image
  
  useEffect(() => {
    imageInstance.onload = function() {
      if(imageInstance.width < 446 || imageInstance.height < 223){
        setPreviewType('small')
      } else {
        setPreviewType('big')
      }
      if(imageInstance.height < 200 || imageInstance.width < 200){
        setImageAlowed(false)
      } else {
        setImageAlowed(true)
      }
    }
  
  }, [imageInstance])
  
  return (
    <div className={classnames(classes.card__content, { [classes.small]: previewType === 'small' })}>
      {imageAlowed && <img className={classes.card__image} src={image} alt="" />}
      <div className={classes.card__text}>
        <div className={classes.card__link}>Piar.me</div>
        <div className={classes.card__text_content}>
          <div style={{ marginTop: '5px' }}>
            <div className={classes.card__title}>{title}</div>
          </div>
          <div className={classes.card__description}>{body}</div>
        </div>
      </div>
    </div>
  )}
