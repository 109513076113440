import classnames from 'classnames'
import React, { FC, useCallback } from 'react'

import { Callback } from 'src/common'
import { Channel } from 'src/models/Channels'

import classes from './ChannelIcon.module.css'



const fontAwesomeClassForChannel: Record<Channel, string> = {
  [Channel.facebook]: 'fa-facebook-f',
  [Channel.telegram]: 'fa-telegram-plane',
  [Channel.slack]: 'fa-slack-hash',
  [Channel.twitter]: 'fa-twitter',
  [Channel.viber]: 'fa-viber',
  [Channel.pinterest]: 'fa-pinterest-p',
  [Channel.linkedin]: 'fa-linkedin-in',
  [Channel.whatsapp]: 'fa-whatsapp',
  [Channel.instagram]: 'fa-instagram',
}

const socialMediaClassName: Record<Channel, string> = {
  [Channel.facebook]: classes.facebook,
  [Channel.telegram]: classes.telegram,
  [Channel.slack]: classes.slack,
  [Channel.twitter]: classes.twitter,
  [Channel.viber]: classes.viber,
  [Channel.pinterest]: classes.pinterest,
  [Channel.linkedin]: classes.linkedin,
  [Channel.whatsapp]: classes.whatsapp,
  [Channel.instagram]: classes.instagram,
}

interface Props {
  channel: Channel
  // active: boolean
  onClick: Callback<Channel>
}

export const ChannelIcon: FC<Props> = ({ channel, onClick }) => {
  const onClickWithChannel = useCallback(() => {
    onClick(channel)
  }, [channel, onClick])

  return (
    <button
      className={classnames(classes.type__item, classes.active, socialMediaClassName[channel])}
      onClick={onClickWithChannel}
    >
      <i className={`fab ${fontAwesomeClassForChannel[channel]}`}></i>
    </button>
  )
}
