import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import classnames from 'classnames'
// import mixpanel from 'mixpanel-browser'
import React, { useEffect, useState } from 'react'
import { Route, Switch, NavLink, Redirect, useRouteMatch } from 'react-router-dom'
import Select from 'react-select'

import { AuthorsResponse, UserData } from 'src/apis/core'
import { Callback } from 'src/common'
// import { Button } from 'src/components/shared/button/Button'
import { useCoreApi } from 'src/hooks/useCoreApi'
import { Preview } from 'src/models/Preview'

// import { Analytics } from '../Analytics/Analytics'
import { History } from '../History/History'
// import { placeholderPreview } from '../PreviewBuilder/PreviewBuilder'

import classes from './Dashboard.module.css'


interface Props {
  setPreview: Callback<Preview>
  user: UserData | null
  setUser: Callback<UserData | null>
}

export const Dashboard: React.FC<Props> = ({ setPreview, user,  setUser }) => {
  // const history = useHistory()
  const [progress, setProgress] = useState<{limit: number, active: number}>({ limit: 0, active: 0 })
  const [authors, setAuthors] = useState<AuthorsResponse>()
  const [selectedAuthors, setSelectedAuthors] = useState<Array<string>>([])
  const [nameQuery, setNameQuery] = useState<string>()
  const [dateRange, setDateRange] = useState<Date[]|undefined>()
  const [page, setPage] = useState<number>(0)
  const coreApi = useCoreApi()

  useEffect(() => {
    // coreApi.getAnalytics().then(setAnalytic)
    coreApi.getAuthors({}).then(setAuthors)

    return setAuthors(undefined)
  }, [coreApi])


  // const createNewPreview = () => {
  //   setPreview({ ...placeholderPreview, image: '' })
  //   localStorage.removeItem('preview')
  //   mixpanel.track('create_new_history_btn_click')
  //   history.push('/builder')
  // }

  const { path, url } = useRouteMatch()

  return (
    <div className={ classes['dashboard-wrapper'] }>
      <nav className={ classes['dashboard-nav'] }>
        <NavLink
          activeClassName={ classnames(classes.active)}
          className={ classnames(classes['nav-item'])}
          to={ `${ url }/active` }
        >Previews
          <span className={ classes.counter} >{`${progress?.active || 0}/${progress?.limit || 0}`}</span>
        </NavLink>
        {/* <NavLink
          activeClassName={ classnames(classes.active)}
          className={ classnames(classes['nav-item'])}
          to={ `${ url }/archive` }
        >
          Archive
        </NavLink> */}
        <NavLink
          activeClassName={ classnames(classes.active)}
          className={ classnames(classes['nav-item'])}
          to={ `${ url }/favorites` }
        >
          favorites
        </NavLink>
        <div className={ classes['create-new'] }>
          <div className={classes.filters}>
            <input className={classes.NameInput} type="text" placeholder="Search by name" value={nameQuery} name="" id="" onChange={e => {
              setPage(0)
              setNameQuery(e.target.value)
            }}/>
            <Select className={classes.select} onInputChange={(value, { action }) => {
              action === 'input-change' && coreApi.getAuthors({ email: value }).then(setAuthors)
            }} styles={{ control:(styles) => ({
              ...styles,
              border: '1px solid #DFE0E2',
              borderRadius: '32px',
              backgroundColor: '#FFFFFF',
            }), valueContainer: (styles) => ({
              ...styles,
              flexWrap: 'nowrap',
          
            }) }} isMulti onChange={(selected: any) => {
              setPage(0)
              setSelectedAuthors(selected ? selected.map((item: { value: any }) => item.value) : [])
              // coreApi.getAnalytics({author: selected.map((item: { value: any }) => item.value)}).then(setAnalytic)
            }} placeholder="Authors" options={authors?.content?.map(item => ({ value: item, label: item })) as any}/>
            {/* <input type="text" name="" id="" onChange={e=>coreApi.getAnalytics({author: []}).then(setAnalytic)}/> */}
            <DateRangePicker format="dd/MM/yyyy" onChange={setDateRange} value={dateRange} dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" className={classes['date-picker']}/>
          </div>
          {/* <Button
            btn_class={classes.btn}
            isActive={ true }
            onClick={ createNewPreview }
          >+ Create new</Button> */}
        </div>
      </nav>

      <Switch>
        <Route exact path={ `${path}` }>
          <Redirect to={ `${path}/active` } />
        </Route>
        <Route path={ `${path}/active` }>
          <History page={page} setPage={setPage} name={nameQuery} selectedAuthors={selectedAuthors} dateRange={dateRange} user={ user } setUser={ setUser } setPreview={ setPreview } status="ACTIVE" setProgress={setProgress}/>
        </Route>
        {/* <Route path={ `${path}/archive` }>
          <History page={page} setPage={setPage} name={nameQuery} selectedAuthors={selectedAuthors} dateRange={dateRange} user={ user } setUser={ setUser } setPreview={ setPreview } status="ARCHIVED" setProgress={setProgress}/>
        </Route> */}
        <Route path={ `${path}/favorites` }>
          <History page={page} setPage={setPage} name={nameQuery} selectedAuthors={selectedAuthors} dateRange={dateRange} user={ user } setUser={ setUser } setPreview={ setPreview } status="ACTIVE" favorite={true} setProgress={setProgress}/>
        </Route>
      </Switch>

    </div>
  )
}
