export const TARIFF_LIST_FEATURES: {
  [key: string]: {
    features: string[]
    comingSoon: boolean
  }
} = {
  basic: {
    features: ['20 active link previews',
      '10 custom domains on «piar.me»',
      '5 full custom domains',
      '10 team members',
      'Unlimited archived link previews',
      'Unlimited clicks',
      'Statistics for all links'],
    comingSoon: false,
  },
  standard: {
    features: ['50 active link previews',
      '20 custom domains on «piar.me»',
      '10 full custom domains',
      '20 team members',
      'Unlimited archived link previews',
      'Unlimited clicks',
      'Statistics for all links',
      'Advanced analytics'],
    comingSoon: false,
  },
  enterprise: {
    features: ['100 active link previews',
      '30 custom domains on «piar.me»',
      '20 full custom domains',
      '3 team members',
      'Unlimited archived link previews',
      'Unlimited clicks',
      'Rich statistics',
      'Sales team automation',
      'LinkedIn integration',
    ],
    comingSoon: true,
  },
  app_summo_1: {
    features: ['Unlimited links', 'unlimited clicks', 'link shortener', 'all social networks support', 'statistic for all links', 'image editing'],
    comingSoon: false,
  },
  app_summo_2: {
    features: ['Unlimited links', 'unlimited clicks', 'link shortener', 'all social networks support', 'statistic for all links', 'image editing'],
    comingSoon: false,
  },
  app_summo_3: {
    features: ['Unlimited links', 'unlimited clicks', 'link shortener', 'all social networks support', 'statistic for all links', 'image editing'],
    comingSoon: false,
  },
}
