import classnames from 'classnames'
import mixpanel from 'mixpanel-browser'
import React from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import Select from 'react-select'

import { UserSubscription } from 'src/apis/core'
import { Callback } from 'src/common'
import { useAuth } from 'src/hooks/useAuth'
import { Preview } from 'src/models/Preview'

import icoQuestion from '../../assets/icons/ico-question-mark.svg'
import icoLogo from '../../assets/icons/logo-piario.svg'
import { Button } from '../shared/button/Button'
import btnStyles from '../shared/button/Button.module.css'
// import { getDaysBetweenDates } from '../shared/utils'

import classes from './Header.module.css'


interface Props {
    subscription:UserSubscription | undefined
    setSelectedTab: Callback<string>
    setPreview: Callback<Preview | undefined>
}

export const Header: React.FC<Props> = ({ subscription, setSelectedTab, setPreview }) => {
  const { signout } = useAuth()
  const history = useHistory()
  const auth = useAuth()
  const location = useLocation()

  const login = () => {
    auth.setShowAuthForm(true)
    setSelectedTab('login')
    // auth.setLoginFromGenerateButton(true)
  }

  const signOut = () => {
    signout()
    history.push('/builder')
  }

  const createNewPreview = () => {
    setPreview(undefined)
    localStorage.removeItem('preview')
    mixpanel.track('create_new_header_click')
    history.push('/builder')
  }

  // const getTrialDaysLeft = () => getDaysBetweenDates((new Date()).getTime(), subscription?.expiredAt.getTime()).toFixed()

  // const getExpiredDate = () => {
  //   const expiredDate = new Date(subscription?.expiredAt)
  //   const expiredDay = expiredDate.getDate() < 10 ? `0${ expiredDate.getDate() }` : expiredDate.getDate()
  //   return `${ expiredDate.getFullYear() }/${ expiredDate.getMonth() + 1 }/${ expiredDay }`
  // }

  // const subscriptionExpired = subscription?.expiredAt.getTime() < Date.now()

  // const subscritionTillMsg = subscriptionExpired ? 'subscription has expired, upgrade to continue' : `till ${ getExpiredDate() }`

  // const subscritionTrialMsg = subscriptionExpired ? 'trial has expired, upgrade to continue' : `${ getTrialDaysLeft() } trial days left`

  const options = [
    { value: '/billing', label: 'Subscription' },
    { value: '/profile', label: 'Account' },
    { value: 'signout', label: 'Sign Out' },
  ]
  
  return (
    <header className={ classnames(classes.header) }>
      <div className={ classes['header-content'] }>
        <div className={ classes['left-side'] }>
          <a
            href={ auth.token ? '/' : 'https://piar.io'}
            target={ auth.token ? '' : '_blank'}  rel="noopener noreferrer"
            className={ classes['header-logo'] }
          >
            <img src={ icoLogo } alt="logo" height="32" />
          </a>
        </div>

        { auth.token ?
          <>
            { (location.pathname !== '/builder') ?
              <NavLink
                className={ classnames(classes['builder-link'], btnStyles.root, btnStyles['btn-accent-1'], btnStyles['btn-sm']) }
                to={ '/builder' }
                onClick={createNewPreview}
              >+ Create new</NavLink> : null
            }

            <div className={ classes['right-side'] }>
              <NavLink
                activeClassName={ classnames(classes.active)}
                className={ classes['header-link'] }
                to={ '/dashboard' }
              >Dashboard</NavLink>

              <NavLink
                activeClassName={ classnames(classes.active)}
                className={ classes['header-link'] }
                to="/analytics"
              >Analytics</NavLink>
              {/* <NavLink
                activeClassName={ classnames(classes.active)}
                className={ classes['header-link'] }
                to={ '/billing' }
                onClick={() => {
                  mixpanel.track('subscription_click')
                }}
              >Subscription
                { subscription &&
                  <span className={ classes['plan-note']}>
                    { subscription.trial ? subscritionTrialMsg : subscritionTillMsg }
                  </span>
                }
              </NavLink>


             <button
                type="button"
                onClick={ signOut }
                className={ classes['header-link'] }
              >Sign Out</button> */}

              <Select options={options}
                classNamePrefix="react-select"
                value={null}
                onChange={(data) => {
                  const selected = (data as {value: string, label: string})?.value
                  if(selected === 'signout'){
                    return signOut()
                  }
                  return history.push(selected)
                }}
                isSearchable={false}
                placeholder="Profile" className={classes['menu-select']} styles={{
                  container: (provided) => ({
                    ...provided,
                    width: '110px',
                    position: 'static',
                    // marginLeft: '28px',
                  }),
                  control: (provided) => ({
                    ...provided,
                    border: 'none',
                    boxShadow: 'none',
                    cursor: 'pointer',
                  }),
                  indicatorSeparator: () => ({
                    display: 'none',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    width: '120px',
                    top: '70%',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: '#40466E',
                  }),
                }} /> 
              <img className={classes.question} onClick={() => {
                const MC = (window as any).MC as any
                return MC && MC.getWidget(MC.getWidgetList()[0]?.widgetId)?.openDialog()
              }} src={icoQuestion} alt=""/>
            </div>
          </>
          :
          <Button
            btn_class={ classes['auth-button'] }
            isActive={ true }
            onClick={ login }
          >Log In</Button>
        }

      </div>
    </header>
  )
}
