import { ResponsiveBar } from '@nivo/bar'
import React from 'react'

import { StatisticItem } from 'src/models/Statistic'


interface Props {
  data: StatisticItem[][]
  period: string
  styles?: React.CSSProperties
}

interface HorizontalProps {
  data: StatisticItem[][]
}

const prepareDateForRender = (date: string, period: 'hourly' | 'daily') => {
  if(period === 'hourly'){
    const dateObj = new Date(date)
    return dateObj.toLocaleTimeString([], { hour: '2-digit', minute:'2-digit', hour12: false })
  }
  if(period === 'daily'){
    const dateObj = new Date(date)
    return dateObj.toLocaleDateString('nl', { month:'2-digit', day:'2-digit' })
  }
  return new Date(date).toLocaleDateString('nl', { month:'2-digit', day:'2-digit' })
}

// const prepareDataToRender = (data: StatisticItem[][]) => {
//   // console.log(data)
//   // data[0][data[0].findIndex(item => item.value === '2021-02-11T02:00:00')] = {value: '2021-02-11T02:00:00', amount: 5}
//   // data[1][data[1].findIndex(item => item.value === '2021-02-11T02:00:00')] = {value: '2021-02-11T02:00:00', amount: 8}
//   // data[2][data[2].findIndex(item => item.value === '2021-02-11T02:00:00')] = {value: '2021-02-11T02:00:00', amount: 4}
  
//   const keys = data.map((_, index) => `amount${index}`)
//   let arr: any[] = []
//   data.forEach((item, wrapperIndex) => {item && (!arr.length ? item.forEach((statItem, index) => {
//     arr[index] = { ...arr[index], [`amount${wrapperIndex}`]: statItem.amount, value: statItem.value }
//   })
//     :item.forEach((statItem, index) => arr.findIndex(i => i.value === statItem.value) !== -1 ? arr[arr.findIndex(i => i.value === statItem.value)] = { ...arr[index], [`amount${wrapperIndex}`]: statItem.amount } : arr = [{ [`amount${wrapperIndex}`]: statItem.amount, value: statItem.value }, ...arr]))
//   })
//   return { data: arr, keys }
// }

// const prepareDataForHorizontalBar = (data: StatisticItem[][]) => {
//   console.log(data)
//   return {data, keys: []}
// }

function merge(data: StatisticItem[][]){
  const buffer: any[] = []
  const keys = data.map((_, index) => `Preview${index+1}`)
  data.forEach((item, index) => {
    item?.forEach((obj, i) => {
      buffer.find(o => o.value === obj.value) 
        ? buffer[buffer.findIndex(o => o.value === obj.value)] = { ...buffer.find(o => o.value === obj.value), [`Preview${index+1}`]: obj.amount } 
        : buffer.push({ value: obj.value, [`Preview${index+1}`]: obj.amount })
    })
  })
  return { data: buffer, keys }
}

function sortTop4(data: any[]){
  const sorted =  data.sort((a, b) => Object.keys(b).reduce((acc, curr) => typeof b[curr] === 'number' ? acc + b[curr] : acc, 0) - Object.keys(a).reduce((acc, curr) => typeof a[curr] === 'number' ? acc + a[curr] : acc, 0))
  const start = sorted.slice(0, 3)
  const rest = sorted.slice(3).reduce((acc, curr) => {
    for (const [name, count] of Object.entries(curr)) {
      if (!acc[name]) {
        acc[name] = 0
      }

      acc[name] += count
    }

    return { ...acc, value: 'Other' }
  }, {})
  // console.log(rest.length ? [...start, rest] : start)
  const final = Object.keys(rest).length !== 0 ? [...start, rest] : start
  return final.reverse()
}

export const GroupedBar: React.FC<Props> = ({ data, period, styles }) => {
  return (
    <div style={{ width: '100%', height: '100%', minWidth: '400px', ...styles }}>
      <ResponsiveBar 
        // data={prepareDataToRender(data).data}
        data={merge(data).data}
        colors={{ scheme: 'set2' }} 
        enableLabel={true}
        keys={merge(data).keys} 
        indexBy="value"
        enableGridY={true} 
        margin={{ bottom: 70, left:30, top: 15, right: 10 }} 
        padding={0.2}
        borderRadius={2}
        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        groupMode="grouped"
        innerPadding={4}
        axisBottom={{ 
          tickSize: 0, 
          tickPadding: 3, 
          // legend: `Total clicks: ${totalClicks}`, 
          // legendOffset: 55, 
          // legendPosition: 'middle', 
          tickRotation: 45, 
          format: values => prepareDateForRender(String(values), period === 'LAST_DAY' || period === 'YESTERDAY' ? 'hourly' : 'daily'), 
        }}
        axisLeft={{
          format: value => Number.isInteger(value as number) ? String(value) : '',
          // tickValues: 3
        }}
        // gridYValues={3}
        // axisLeft={data ? totalClicks >= 10 ? { tickSize: 0, tickPadding: 6 } : { tickSize: 0, tickPadding: 6, tickValues: totalClicks } : { tickSize: 0, tickPadding: 6 }}
        // animate={false}
        motionStiffness={280}
        motionDamping={40}
        labelSkipHeight={12}
        labelSkipWidth={15}
        theme={{ axis: { legend: { text: { fontFamily: 'Roboto', textTransform: 'capitalize', fontSize: '14px' } }, ticks: { text: { fontSize: '10px' } } } }}
      />   
    </div>         
  )}

export const GroupedBarHorizontal: React.FC<HorizontalProps> = ({ data }) => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ResponsiveBar 
        layout="horizontal"
        data={sortTop4(merge(data).data)}
        // data={merge(data).data}

        colors={{ scheme: 'set2' }} 
        enableLabel={true}
        keys={merge(data).keys} 
        indexBy="value"
        enableGridY={false} 
        margin={{ left:100, top: 15, right: 10 }} 
        padding={0.2}
        borderRadius={2}
        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        groupMode="grouped"
        innerPadding={4}
        axisBottom={{ 
          tickSize: 3, 
          tickPadding: 3, 
          // legend: `Total clicks: ${totalClicks}`, 
          // legendOffset: 55, 
          // legendPosition: 'middle', 
          tickRotation: 45, 
          // format: values => values.toString().slice(0, 10), 
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 16,
          // format: value => Number.isInteger(value as number) ? String(value) : "",
          // tickValues: 3
        }}
        // gridYValues={3}
        // axisLeft={data ? totalClicks >= 10 ? { tickSize: 0, tickPadding: 6 } : { tickSize: 0, tickPadding: 6, tickValues: totalClicks } : { tickSize: 0, tickPadding: 6 }}
        // animate={false}
        motionStiffness={280}
        motionDamping={40}
        labelSkipHeight={12}
        labelSkipWidth={15}
        theme={{ axis: { legend: { text: { fontFamily: 'Roboto', textTransform: 'capitalize', fontSize: '14px' } }, ticks: { text: { color: '#40466E',
          fontFamily: 'Roboto',
          fontSize: '14px',
          letterSpacing: '0',
          lineHeight: '20px',
          textTransform: 'capitalize' } } } }}
      />   
    </div>         
  )}
