import classnames from 'classnames'
import mixpanel from 'mixpanel-browser'
import React from 'react'

import { UserSubscription } from 'src/apis/core'
import { Callback } from 'src/common'

import icoPlan from '../../assets/icons/ico-plan.svg'
import buttonClasses from '../shared/button/Button.module.css'
import { getDaysBetweenDates } from '../shared/utils'

import classes from './CurrentPlan.module.css'


interface Props {
  subscription?: UserSubscription
  planDetails?: {
    name:
      | 'basic' | 'standard' | 'enterprise'
      | 'app_summo_1'
      | 'app_summo_2'
      | 'app_summo_3'
    features: string[]
    monthlyPrice: number
    yearlyPrice: number
    comingSoon: boolean
  }
  frequency: 'monthly' | 'yearly'
  startTrial: Callback
  paymentHandler: Callback
}

export const CurrentPlan: React.FC<Props> = ({
  subscription,
  planDetails,
  startTrial,
  paymentHandler,
  frequency,
}) => {
  const getTrialDaysLeft = () =>
    getDaysBetweenDates(
      new Date().getTime(),
      subscription?.expiredAt.getTime()
    ).toFixed()

  const getExpiredDate = () => {
    const expiredDate = new Date(subscription?.expiredAt)
    const expiredDay =
      expiredDate.getDate() < 10
        ? `0${expiredDate.getDate()}`
        : expiredDate.getDate()
    return `${expiredDate.getFullYear()}/${
      expiredDate.getMonth() + 1
    }/${expiredDay}`
  }

  const price =
    frequency === 'monthly'
      ? `$ ${planDetails?.monthlyPrice}/month`
      : `$ ${planDetails?.yearlyPrice}/year`

  const subscriptionExpired = subscription?.expiredAt.getTime() < Date.now()

  const subscritionTillMsg = subscriptionExpired
    ? 'subscription has expired, upgrade to continue'
    : `till ${getExpiredDate()}`

  const subscritionTrialMsg = subscriptionExpired
    ? 'trial has expired, upgrade to continue'
    : `${getTrialDaysLeft()} trial days left`

  return (
    <div className={classes.box}>
      <img className={classes.icon} src={icoPlan} alt='Plan icon' width='63' />
      <div className={classes.details}>
        <div className={classes['details-heading']}>
          <h4 className={classes['plan-name']}>
            {' '}
            {planDetails?.name.startsWith('app_summo')
              ? 'AppSumo Lifetime Deal'
              : planDetails?.name + ' Plan'}
          </h4>
          {subscription && !planDetails?.name.startsWith('app_summo') && (
            <p className={classes['plan-note']}>
              {subscription.trial ? subscritionTrialMsg : subscritionTillMsg}
            </p>
          )}
        </div>
        <div className={classes['details-features']}>
          {planDetails?.features?.join(', ')}
          {planDetails?.name.startsWith('app_summo') ? (
            <div
              style={{
                display: 'flex',
                marginTop: '16px',
                gap: '16px',
                flexDirection: 'row',
                textTransform: 'none',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '12px',
                }}
              >
                <span style={{ fontWeight: 'bold' }}>Codes redeemed</span>
                <span style={{ fontWeight: 'bold' }}>Active link previews</span>
                <span style={{ fontWeight: 'bold' }}>
                  Custom domains on &quot;piar.me&quot;
                </span>
                <span style={{ fontWeight: 'bold' }}>Fully custom domains</span>
                <span style={{ fontWeight: 'bold' }}>Team members</span>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '12px',
                  alignItems: 'flex-end',
                  color:
                    planDetails?.name === 'app_summo_1' ? '#FF934F' : 'inherit',
                  justifyContent: 'space-between',
                }}
              >
                <span>1</span>
                <span>100</span>
                <span>20</span>
                <span>10</span>
                <span>20</span>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '12px',
                  alignItems: 'flex-end',
                  color:
                    planDetails?.name === 'app_summo_2' ? '#FF934F' : 'inherit',
                  justifyContent: 'space-between',
                }}
              >
                <span>2</span>
                <span>1000</span>
                <span>40</span>
                <span>15</span>
                <span>40</span>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '12px',
                  alignItems: 'flex-end',
                  color:
                    planDetails?.name === 'app_summo_3' ? '#FF934F' : 'inherit',
                  justifyContent: 'space-between',
                }}
              >
                <span>3</span>
                <span>10000</span>
                <span>75</span>
                <span>30</span>
                <span>80</span>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className={classes.actions}>
        <p className={classes.price}>
          {planDetails?.name.startsWith('app_summo')
            ? '$ 0/forever'
            : subscription
              ? price
              : '14 days free trial'}
        </p>
        {!planDetails?.name.startsWith('app_summo') ? (
          <button
            className={classnames(
              buttonClasses.root,
              classes['btn-accent-2'],
              classes.Button
            )}
            type='button'
            onClick={() => {
              mixpanel.track('upgrade_btn_click')
              subscription ? paymentHandler() : startTrial()
            }}
          >
            {subscription
              ? subscription.trial
                ? 'Upgrade'
                : 'Make Payment'
              : 'Start now'}
          </button>
        ) : null}
      </div>
    </div>
  )
}
