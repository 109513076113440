import React from 'react'

import { Callback } from 'src/common'

import { Backdrop } from '../backdrop/Backdrop'

import classes from './Modal.module.css'


interface Props {
    visible: boolean
    onCloseAttempt: Callback
    styles?: { [key: string]: string | number }
}

export const Modal: React.FC<Props> = ({ children, visible, onCloseAttempt, styles = {} }) => (
  <div>
    <Backdrop onClick={onCloseAttempt} visible={visible} />
    <div className={classes.Modal}
      style={{
        ...styles,
        transform: visible ? 'translate(-50%, -50%)' : 'translate(-50%, 200%)',
        opacity: visible ? '1' : '0',
      }}>
      {children}
    </div>
  </div>
)
