import classnames from 'classnames'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { Callback } from 'src/common'
import inputStyles from 'src/components/shared/inputs/inputs.module.css'
import { useAuth } from 'src/hooks/useAuth'
import { useCoreApi } from 'src/hooks/useCoreApi'

import icoClosed from '../../assets/icons/ico-closed.svg'
import icoLogo from '../../assets/icons/logo-piario.svg'
import previewMokups from '../../assets/images/preview-mokups.png'
import buttonClasses from '../shared/button/Button.module.css'

import classes from './ResetPassForm.module.css'




interface Props {
    onCloseForm: Callback
    hash: string
}

type FormValues = {
  password: string
  passwordConfirmation: string
}

export const ResetPassForm: React.FC<Props> = ({ onCloseForm, hash }) => {
  const auth = useAuth()
  const coreApi = useCoreApi()
  const { handleSubmit, register, errors, setError } = useForm<FormValues>()
  const history = useHistory()
  const reset:SubmitHandler<FormValues>  = async ({ password, passwordConfirmation }) => {
    if (password.localeCompare(passwordConfirmation) === 0){
      try {
        const signin = await coreApi.setNewPass({ hash: hash, pass: password })
        auth.setToken(signin.bearerToken)
        localStorage.setItem('bearerToken', signin.bearerToken)
        auth.setShowConfirmMessage(false)
        auth.setShowAuthForm(false)
        onCloseForm()
        history.replace('/')
      } catch (error) {
        console.log(error)
        if(error.error){
          auth.setError(error.error)
        } else if(error.causes) {
          auth.setError(error.causes[0])
        }
        return Promise.reject(error)
      }
    } else {
      setError('passwordConfirmation', {
        type: 'manual',
        message: 'Confirm password field has to be equal to Password.',
      })
    }
  }

  return (
    <div className={ classes.wrapper }>
      <div className={ classes['wrapper-left']}>
        <button
          type="button"
          onClick={ onCloseForm}
          className={ classes['modal-close']}
          aria-label="Close modal"
        ><img src={ icoClosed } alt="Close modal" /></button>
        <div className={ classes.logo }>
          <img src={ icoLogo } alt="Logo" height="28"  />
        </div>
        <div className={ classes.content }>
          <>
            <h2 className={ classes.title }>
                Reset your password
            </h2>
            <form
              className={ classes.form }
              onSubmit={ handleSubmit(reset) }
            >
              <div className={ classes['form-group'] }>
                <label htmlFor="auth-pass" className={ inputStyles.title }>Password</label>
                <input
                  onChange={() => auth.setError('')}
                  className={ classnames(inputStyles.input, errors.password && inputStyles.error) }
                  type="password"
                  placeholder="Input Password"
                  autoComplete="current-password"
                  name="password"
                  id="auth-pass"
                  ref={register({
                    required: 'Required',
                  }
                  )}
                />
              </div>
              <div className={ classes['form-group']}>
                <label htmlFor="confirm-auth-pass" className={ inputStyles.title }>Confirm Password</label>
                <input
                  onChange={() => auth.setError('')}
                  className={ classnames(inputStyles.input, errors.password && inputStyles.error) }
                  type="password"
                  placeholder="Input Password"
                  name="passwordConfirmation"
                  id="confirm-auth-pass"
                  ref={register({
                    required: 'Required',
                  }
                  )}
                />
              </div>

              <div>
                { auth.error && <span className={ classes.error_message }>{ auth.error }</span> }
              </div>
              <div>
                { errors.passwordConfirmation && <span className={ classes.error_message }>{ errors.passwordConfirmation?.message }</span> }
              </div>

              <div className={ classnames(classes['form-group'], classes['button-wrapper'], classes['mg-top-16px']) }>
                <button
                  className={ classnames(buttonClasses.root, classes['btn-accent-1'], classes.Button) }
                  autoFocus
                  type='submit'
                >
                    Login
                </button>
              </div>
            </form>
          </>
        </div>
      </div>

      <div className={ classes['wrapper-right']}>
        <p className={ classes.subtitle }>Design what your followers see when you share a link</p>
        <img src={ previewMokups } alt="Preview mokups" width="320" />
      </div>
    </div>
  )
}
