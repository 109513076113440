import React, { FC, useCallback, useLayoutEffect, useRef } from 'react'

import { Callback } from 'src/common'

import classes from '../InputWithAction/InputWithAction.module.css'
import { Button } from '../shared/button/Button'


interface Props {
    inputText: string
    inputDescription: string
    action?: Callback
    buttonText: string
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    btn_styles?: React.CSSProperties
    type?: string
    name?: string
    rules?: any
    register?: any
    onInputFieldClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void
}



export const InputWithAction: FC<Props> = ({ 
  inputText, 
  action, 
  buttonText, 
  inputDescription, 
  onChange, 
  btn_styles, 
  type, 
  name, 
  register, 
  rules,
  onInputFieldClick, 
}) => {

  // Bad implementation. Should rewrite to useForm Hook

  const cursorStart = useRef<number | null>(null)
  const cursorEnd = useRef<number | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)

  useLayoutEffect(() => {
    if (cursorStart.current && cursorEnd.current && inputRef.current) {
      inputRef.current.setSelectionRange(cursorStart.current, cursorEnd.current)
    }
  }, [inputText])

  const onRawChangeEvent = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    cursorStart.current = event.target.selectionStart
    cursorEnd.current = event.target.selectionEnd
    onChange && onChange(event)
  }, [onChange])

  return(
    <div className={classes.root}>
      <div className={classes.output}>
        <blockquote>
          <label 
            htmlFor="preview_name" 
            className={classes.description}>
            {inputDescription}
          </label>
          <input 
            onClick={onInputFieldClick} 
            id="preview_name" 
            name={name} 
            ref={register && register(rules)} 
            readOnly={!onChange && !register} 
            onChange={onRawChangeEvent} 
            value={inputText}>
          </input>
        </blockquote>
        <Button 
          type={type} 
          isActive={true} 
          btn_class={classes.button} 
          button_styles={btn_styles} 
          onClick={action ? action : console.log}>
          {buttonText}
        </Button>
      </div>
    </div>
  )}
