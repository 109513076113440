import Pagination from '@material-ui/lab/Pagination'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'

import { AnalyticsResponse, AuthorsResponse } from 'src/apis/core'
import { AnalyticItem } from 'src/components/AnalyticItem/AnalyticItem'
import { Button } from 'src/components/shared/button/Button'
import { useCoreApi } from 'src/hooks/useCoreApi'

// import analyticsSkeleton from '../../assets/images/analytics-skeleton.svg'
// import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle';

import classes from './Analytics.module.css'


const getDate = (date: any) => {
  const dd = String(date.getDate()).padStart(2, '0')
  const mm = String(date.getMonth() + 1).padStart(2, '0') //January is 0!
  const yyyy = date.getFullYear()
  date = yyyy + '-' + mm + '-' + dd
  return date
}


export const Analytics: React.FC = () => {
  const history = useHistory()
  const coreApi = useCoreApi()
  const [analytic, setAnalytic] = useState<AnalyticsResponse>()
  const [openedItemId, setOpenedItemId] = useState<number | null>(null)
  const [selectedItems, setSelectedItems] = useState<number[]>([])
  const [authors, setAuthors] = useState<AuthorsResponse>()
  const [selectedAuthors, setSelectedAuthors] = useState<Array<string>>([])
  const [nameQuery, setNameQuery] = useState<string>()
  const [page, setPage] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [dateRange, setDateRange] = useState<Date[]|undefined>(undefined)

  useEffect(() => {
    // coreApi.getAnalytics().then(setAnalytic)
    coreApi.getAuthors({}).then(setAuthors)
  }, [coreApi])

  useEffect(() => {
    // if(nameQuery || selectedAuthors.length){
    setLoading(true)
    coreApi.getAnalytics({ name: nameQuery, author: selectedAuthors, page, from: dateRange && getDate(dateRange[0]), to: dateRange && getDate(dateRange[1]) }).then(setAnalytic).finally(() => setLoading(false))
    // }
  }, [coreApi, nameQuery, selectedAuthors, page, dateRange])

  return (
    <div className={classes.root}>
      <div className={classes.filters}>
        <input className={classes.NameInput} type="text" placeholder="Search by name" value={nameQuery} name="" id="" onChange={e => {
          setPage(0)
          setNameQuery(e.target.value)
        }}/>
        <Select onInputChange={(value, { action }) => {
          action === 'input-change' && coreApi.getAuthors({ email: value }).then(setAuthors)
        }} styles={{ control:(styles) => ({
          ...styles,
          border: '1px solid #DFE0E2',
          borderRadius: '32px',
          backgroundColor: '#FFFFFF',
        }), valueContainer: (styles) => ({
          ...styles,
          flexWrap: 'nowrap',
          
        }) }} placeholder="Authors" isMulti onChange={(selected: any) => {
          setPage(0)
          setSelectedAuthors(selected ? selected.map((item: { value: any }) => item.value) : [])
          // coreApi.getAnalytics({author: selected.map((item: { value: any }) => item.value)}).then(setAnalytic)
        }} options={authors?.content?.map(item => ({ value: item, label: item })) as any}/>
        {/* <input type="text" name="" id="" onChange={e=>coreApi.getAnalytics({author: []}).then(setAnalytic)}/> */}
        <DateRangePicker format="dd/MM/yyyy" onChange={setDateRange} value={dateRange} dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" className={classes['date-picker']}/>
        <Button isActive={selectedItems.length > 1} onClick={() => history.push(`/comparing?ids=${selectedItems.toString()}`)}>Compare</Button>
      </div>
      <div className={classes['content-table']}>
        <div className={classes['table-header']}>
          <input disabled type="checkbox" name="" id="" style={{ visibility: 'hidden' }}/>
          <span className={classes.name}>Name</span>
          <span className={classes.created}>Created</span>
          <span className={classes.destination}>Destination</span>
          <span className={classes.total}>Total clicks</span>
          <span className={classes.day}>Last 24H</span>
          <span className={classes.week}>Last Week</span>
          <span className={classes.month}>Last Month</span>
          {/* <span style={{ justifySelf: 'end' }}>Actions</span> */}
        </div>
        {loading ? 'Loading...' : !analytic?.empty ? analytic?.content.map(item => <AnalyticItem key={item.id} selectedItems={selectedItems} setSelectedItems={setSelectedItems} item={item} isOpen={item.id === openedItemId} onOpen={setOpenedItemId} />) : 'No results found'}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {analytic && (analytic.totalPages > 1 && (<Pagination count={analytic.totalPages} page={page + 1} onChange={(_, value: React.SetStateAction<number>) => {
          setPage(Number(value) - 1)
        }} />)) }
      </div>
    </div>
  )
}
