import { useState, useCallback } from 'react'


interface Parameters<T> {
  allValues: T[]
  initiallySelectedValues: T[]
}

export const useOrderedSet = <T, >({ allValues, initiallySelectedValues }: Parameters<T>): [T[], T[], (t: T) => void] => {
  const [presentValues, setPresentValues] = useState(initiallySelectedValues)

  const removeValue = useCallback((rejectedValue: T) => {
    setPresentValues(currentValues =>
      allValues.filter(value => currentValues.includes(value) && value !== rejectedValue)
    )
  }, [allValues])

  const addValue = useCallback((newValue: T) => {
    setPresentValues(currentValues =>
      allValues.filter(value => [...currentValues, newValue].includes(value))
    )
  }, [allValues])

  const toggleValue = useCallback((toggledValue: T) => {
    if (presentValues.includes(toggledValue)) {
      removeValue(toggledValue)
    } else {
      addValue(toggledValue)
    }
  }, [addValue, presentValues, removeValue])

  return [allValues, presentValues, toggleValue]
}
