export const mockedData = [
  [
    {
      'value': '2021-02-03T02:00:00',
      'amount': 4,
    },
    {
      'value': '2021-02-04T02:00:00',
      'amount': 3,
    },
    {
      'value': '2021-02-05T02:00:00',
      'amount': 2,
    },
    {
      'value': '2021-02-06T02:00:00',
      'amount': 8,
    },
    {
      'value': '2021-02-07T02:00:00',
      'amount': 5,
    },
    {
      'value': '2021-02-08T02:00:00',
      'amount': 1,
    },
    {
      'value': '2021-02-09T02:00:00',
      'amount': 3,
    },
    {
      'value': '2021-02-10T02:00:00',
      'amount': 0,
    },
    {
      'value': '2021-02-11T02:00:00',
      'amount': 2,
    },
    {
      'value': '2021-02-12T02:00:00',
      'amount': 2,
    },
    {
      'value': '2021-02-13T02:00:00',
      'amount': 8,
    },
    {
      'value': '2021-02-14T02:00:00',
      'amount': 2,
    },
    {
      'value': '2021-02-15T02:00:00',
      'amount': 1,
    },
    {
      'value': '2021-02-16T02:00:00',
      'amount': 1,
    },
    {
      'value': '2021-02-17T02:00:00',
      'amount': 3,
    },
    {
      'value': '2021-02-18T02:00:00',
      'amount': 3,
    },
    {
      'value': '2021-02-19T02:00:00',
      'amount': 2,
    },
    {
      'value': '2021-02-20T02:00:00',
      'amount': 8,
    },
    {
      'value': '2021-02-21T02:00:00',
      'amount': 7,
    },
    {
      'value': '2021-02-22T02:00:00',
      'amount': 6,
    },
    {
      'value': '2021-02-23T02:00:00',
      'amount': 2,
    },
    {
      'value': '2021-02-24T02:00:00',
      'amount': 1,
    },
    {
      'value': '2021-02-25T02:00:00',
      'amount': 5,
    },
    {
      'value': '2021-02-26T02:00:00',
      'amount': 4,
    },
    {
      'value': '2021-02-27T02:00:00',
      'amount': 3,
    },
    {
      'value': '2021-02-28T02:00:00',
      'amount': 4,
    },
    {
      'value': '2021-03-01T02:00:00',
      'amount': 4,
    },
    {
      'value': '2021-03-02T02:00:00',
      'amount': 1,
    },
    {
      'value': '2021-03-03T02:00:00',
      'amount': 1,
    },
  ],
  [
    {
      'value': '2021-02-03T02:00:00',
      'amount': 1,
    },
    {
      'value': '2021-02-04T02:00:00',
      'amount': 2,
    },
    {
      'value': '2021-02-05T02:00:00',
      'amount': 1,
    },
    {
      'value': '2021-02-06T02:00:00',
      'amount': 0,
    },
    {
      'value': '2021-02-07T02:00:00',
      'amount': 0,
    },
    {
      'value': '2021-02-08T02:00:00',
      'amount': 2,
    },
    {
      'value': '2021-02-09T02:00:00',
      'amount': 1,
    },
    {
      'value': '2021-02-10T02:00:00',
      'amount': 1,
    },
    {
      'value': '2021-02-11T02:00:00',
      'amount': 0,
    },
    {
      'value': '2021-02-12T02:00:00',
      'amount': 0,
    },
    {
      'value': '2021-02-13T02:00:00',
      'amount': 3,
    },
    {
      'value': '2021-02-14T02:00:00',
      'amount': 5,
    },
    {
      'value': '2021-02-15T02:00:00',
      'amount': 2,
    },
    {
      'value': '2021-02-16T02:00:00',
      'amount': 3,
    },
    {
      'value': '2021-02-17T02:00:00',
      'amount': 1,
    },
    {
      'value': '2021-02-18T02:00:00',
      'amount': 1,
    },
    {
      'value': '2021-02-19T02:00:00',
      'amount': 0,
    },
    {
      'value': '2021-02-20T02:00:00',
      'amount': 4,
    },
    {
      'value': '2021-02-21T02:00:00',
      'amount': 5,
    },
    {
      'value': '2021-02-22T02:00:00',
      'amount': 4,
    },
    {
      'value': '2021-02-23T02:00:00',
      'amount': 3,
    },
    {
      'value': '2021-02-24T02:00:00',
      'amount': 3,
    },
    {
      'value': '2021-02-25T02:00:00',
      'amount': 3,
    },
    {
      'value': '2021-02-26T02:00:00',
      'amount': 2,
    },
    {
      'value': '2021-02-27T02:00:00',
      'amount': 5,
    },
    {
      'value': '2021-02-28T02:00:00',
      'amount': 4,
    },
    {
      'value': '2021-03-01T02:00:00',
      'amount': 4,
    },
    {
      'value': '2021-03-02T02:00:00',
      'amount': 3,
    },
    {
      'value': '2021-03-03T02:00:00',
      'amount': 4,
    },
  ],
  [
    {
      'value': '2021-02-03T02:00:00',
      'amount': 3,
    },
    {
      'value': '2021-02-04T02:00:00',
      'amount': 3,
    },
    {
      'value': '2021-02-05T02:00:00',
      'amount': 5,
    },
    {
      'value': '2021-02-06T02:00:00',
      'amount': 2,
    },
    {
      'value': '2021-02-07T02:00:00',
      'amount': 1,
    },
    {
      'value': '2021-02-08T02:00:00',
      'amount': 5,
    },
    {
      'value': '2021-02-09T02:00:00',
      'amount': 6,
    },
    {
      'value': '2021-02-10T02:00:00',
      'amount': 7,
    },
    {
      'value': '2021-02-11T02:00:00',
      'amount': 10,
    },
    {
      'value': '2021-02-12T02:00:00',
      'amount': 11,
    },
    {
      'value': '2021-02-13T02:00:00',
      'amount': 10,
    },
    {
      'value': '2021-02-14T02:00:00',
      'amount': 8,
    },
    {
      'value': '2021-02-15T02:00:00',
      'amount': 6,
    },
    {
      'value': '2021-02-16T02:00:00',
      'amount': 12,
    },
    {
      'value': '2021-02-17T02:00:00',
      'amount': 14,
    },
    {
      'value': '2021-02-18T02:00:00',
      'amount': 7,
    },
    {
      'value': '2021-02-19T02:00:00',
      'amount': 8,
    },
    {
      'value': '2021-02-20T02:00:00',
      'amount': 5,
    },
    {
      'value': '2021-02-21T02:00:00',
      'amount': 5,
    },
    {
      'value': '2021-02-22T02:00:00',
      'amount': 7,
    },
    {
      'value': '2021-02-23T02:00:00',
      'amount': 9,
    },
    {
      'value': '2021-02-24T02:00:00',
      'amount': 10,
    },
    {
      'value': '2021-02-25T02:00:00',
      'amount': 9,
    },
    {
      'value': '2021-02-26T02:00:00',
      'amount': 7,
    },
    {
      'value': '2021-02-27T02:00:00',
      'amount': 11,
    },
    {
      'value': '2021-02-28T02:00:00',
      'amount': 8,
    },
    {
      'value': '2021-03-01T02:00:00',
      'amount': 8,
    },
    {
      'value': '2021-03-02T02:00:00',
      'amount': 10,
    },
    {
      'value': '2021-03-03T02:00:00',
      'amount': 5,
    },
  ],
  [
    {
      'value': '2021-02-03T02:00:00',
      'amount': 5,
    },
    {
      'value': '2021-02-04T02:00:00',
      'amount':0,
    },
    {
      'value': '2021-02-05T02:00:00',
      'amount': 4,
    },
    {
      'value': '2021-02-06T02:00:00',
      'amount': 3,
    },
    {
      'value': '2021-02-07T02:00:00',
      'amount': 0,
    },
    {
      'value': '2021-02-08T02:00:00',
      'amount': 0,
    },
    {
      'value': '2021-02-09T02:00:00',
      'amount': 5,
    },
    {
      'value': '2021-02-10T02:00:00',
      'amount': 2,
    },
    {
      'value': '2021-02-11T02:00:00',
      'amount': 3,
    },
    {
      'value': '2021-02-12T02:00:00',
      'amount': 4,
    },
    {
      'value': '2021-02-13T02:00:00',
      'amount': 4,
    },
    {
      'value': '2021-02-14T02:00:00',
      'amount': 3,
    },
    {
      'value': '2021-02-15T02:00:00',
      'amount': 8,
    },
    {
      'value': '2021-02-16T02:00:00',
      'amount': 7,
    },
    {
      'value': '2021-02-17T02:00:00',
      'amount': 6,
    },
    {
      'value': '2021-02-18T02:00:00',
      'amount': 6,
    },
    {
      'value': '2021-02-19T02:00:00',
      'amount': 4,
    },
    {
      'value': '2021-02-20T02:00:00',
      'amount': 4,
    },
    {
      'value': '2021-02-21T02:00:00',
      'amount': 4,
    },
    {
      'value': '2021-02-22T02:00:00',
      'amount': 3,
    },
    {
      'value': '2021-02-23T02:00:00',
      'amount': 0,
    },
    {
      'value': '2021-02-24T02:00:00',
      'amount': 4,
    },
    {
      'value': '2021-02-25T02:00:00',
      'amount': 3,
    },
    {
      'value': '2021-02-26T02:00:00',
      'amount': 2,
    },
    {
      'value': '2021-02-27T02:00:00',
      'amount': 0,
    },
    {
      'value': '2021-02-28T02:00:00',
      'amount': 0,
    },
    {
      'value': '2021-03-01T02:00:00',
      'amount': 1,
    },
    {
      'value': '2021-03-02T02:00:00',
      'amount': 3,
    },
    {
      'value': '2021-03-03T02:00:00',
      'amount': 3,
    },
  ],
  [
    {
      'value': '2021-02-03T02:00:00',
      'amount': 0,
    },
    {
      'value': '2021-02-04T02:00:00',
      'amount': 0,
    },
    {
      'value': '2021-02-05T02:00:00',
      'amount': 0,
    },
    {
      'value': '2021-02-06T02:00:00',
      'amount': 0,
    },
    {
      'value': '2021-02-07T02:00:00',
      'amount': 0,
    },
    {
      'value': '2021-02-08T02:00:00',
      'amount': 0,
    },
    {
      'value': '2021-02-09T02:00:00',
      'amount': 4,
    },
    {
      'value': '2021-02-10T02:00:00',
      'amount': 7,
    },
    {
      'value': '2021-02-11T02:00:00',
      'amount': 10,
    },
    {
      'value': '2021-02-12T02:00:00',
      'amount': 12,
    },
    {
      'value': '2021-02-13T02:00:00',
      'amount': 11,
    },
    {
      'value': '2021-02-14T02:00:00',
      'amount': 13,
    },
    {
      'value': '2021-02-15T02:00:00',
      'amount': 10,
    },
    {
      'value': '2021-02-16T02:00:00',
      'amount': 9,
    },
    {
      'value': '2021-02-17T02:00:00',
      'amount': 11,
    },
    {
      'value': '2021-02-18T02:00:00',
      'amount': 8,
    },
    {
      'value': '2021-02-19T02:00:00',
      'amount': 14,
    },
    {
      'value': '2021-02-20T02:00:00',
      'amount': 12,
    },
    {
      'value': '2021-02-21T02:00:00',
      'amount': 7,
    },
    {
      'value': '2021-02-22T02:00:00',
      'amount': 15,
    },
    {
      'value': '2021-02-23T02:00:00',
      'amount': 11,
    },
    {
      'value': '2021-02-24T02:00:00',
      'amount': 7,
    },
    {
      'value': '2021-02-25T02:00:00',
      'amount': 13,
    },
    {
      'value': '2021-02-26T02:00:00',
      'amount': 11,
    },
    {
      'value': '2021-02-27T02:00:00',
      'amount': 9,
    },
    {
      'value': '2021-02-28T02:00:00',
      'amount': 9,
    },
    {
      'value': '2021-03-01T02:00:00',
      'amount': 10,
    },
    {
      'value': '2021-03-02T02:00:00',
      'amount': 10,
    },
    {
      'value': '2021-03-03T02:00:00',
      'amount': 3,
    },
  ],
]
