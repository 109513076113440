import classnames from  'classnames'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { StatisticResponse, UserData } from 'src/apis/core'
import { Callback } from 'src/common'
import { useCoreApi } from 'src/hooks/useCoreApi'
import { Preview } from 'src/models/Preview'
import { PreviewHistoryItem } from 'src/models/PreviewHistoryItem'

import icoClosed from '../../assets/icons/ico-closed.svg'
import { HistoryItem } from '../HistoryItem/HistoryItem'
import { Button } from '../shared/button/Button'
import { ButtonGroup } from '../shared/buttonGroup/ButtonGroup'
import { Bar } from '../shared/charts/Bar'
import { Pie } from '../shared/charts/Pie'
import { Modal } from '../shared/modal/Modal'

import classes from './StatisticModal.module.css'



interface Props {
    user: UserData | null
    item: PreviewHistoryItem | null
    setItemId: Dispatch<SetStateAction<number | null>>
    deleteItem: Callback<number>
    setPreview: Callback<Preview>
    setHistoryContent: Callback<PreviewHistoryItem[]>
    historyContent: PreviewHistoryItem[]
    archive: Callback<number>
    updateHistory: Callback
}

export const StatisticModal: React.FC<Props> = ({ user, item, setItemId, deleteItem, setPreview, setHistoryContent, historyContent, archive, updateHistory }) => {
  const [statisticPeriod, setStatisticPeriod] = useState<'LAST_DAY' | 'YESTERDAY' | 'WEEK' | 'MONTH' | 'LIFETIME'>('LIFETIME')
  const [stat, setStat] = useState<StatisticResponse>()
  const [loading, setLoading] = useState(false)
  const coreApi = useCoreApi()
  const history = useHistory()

  const periodButtons = [
    { title: 'Last day', action: () => setStatisticPeriod('LAST_DAY'), isSelected: statisticPeriod === 'LAST_DAY' },
    { title: 'Yesterday', action: () => setStatisticPeriod('YESTERDAY'), isSelected: statisticPeriod === 'YESTERDAY' },
    { title: 'Week', action: () => setStatisticPeriod('WEEK'), isSelected: statisticPeriod === 'WEEK' },
    { title: 'Month', action: () => setStatisticPeriod('MONTH'), isSelected: statisticPeriod === 'MONTH' },
    { title: 'All time', action: () => setStatisticPeriod('LIFETIME'), isSelected: statisticPeriod === 'LIFETIME' },
  ]

  const closeModal = () => setItemId(null)

  useEffect(() => {
    (async () => {
      setStat(undefined)
      if(!item){
        return
      }
      if(user?.subscription?.expiredAt && user.subscription.expiredAt.getTime() < Date.now()){
        return setStat(undefined)
      }
      setLoading(true)
      try {
        const stat = await coreApi.getPreviewStatistic({ id: item.id, period: statisticPeriod })
        if(stat.totalClicks !== 0){
          setStat(stat)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    })()
  }, [coreApi, item, statisticPeriod, user])

  return (
    <Modal
      visible={!!item}
      onCloseAttempt={closeModal}
      styles={{ width: '100%' }}
    >
      <div className={ classes['modal-content'] }>
        <button
          type="button"
          onClick={closeModal}
          className={ classes['close-modal']}
          aria-label="Close modal"
        ><img src={ icoClosed } alt="Close modal" /></button>

        <h4 className={classes.modal_caption}>Preview statistic</h4>
        <div className={classes['preview-content']}>
          { item &&
            <HistoryItem
              item={item}
              deleteItem={deleteItem}
              setPreview={setPreview}
              setHistoryContent={ setHistoryContent }
              historyContent={ historyContent }
              archive={archive}
              updateHistory={updateHistory}
            />
          }

          <div className={classes.charts_container}>
            { !stat &&
              <div className={classes.Statistic__overlay}>
                { 
                  (user?.status === 'CLIENT' && (user.subscription.expiredAt.getTime() < Date.now()))
                    ?
                    <>
                      <p style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>Select your plan to see statistic<br/>
                        <Button button_styles={{ marginTop: '10px' }} onClick={() => history.push('/billing')}>Select plan</Button>
                      </p>
                    </>
                    :
                    loading ?
                      <p>Stats is loading...</p> :
                      <p>There is no statistics yet. Share the link to your clients to see more...</p>
                }
              </div>
            }
            <div className={classes.Bar_container} style={stat ? {} : { filter: 'opacity(0.3) grayscale(1)' }}>
              <div className={classes['period-picker']}>
                <h5 className={ classes.caption }>Clicks</h5>
                <ButtonGroup
                  list={ periodButtons }
                />
              </div>
              {!stat?.discreteClicks?.find(i => i?.amount > 0) && stat?.totalClicks && (<div className={classes.empty_stat_message}>
                <p>There is no statistic by this period</p>
              </div>)}
              <div className={classes.Bar_wrapper} style={!stat?.discreteClicks?.find(i => i?.amount > 0) && stat?.totalClicks ? { filter: 'opacity(0.3) grayscale(1)' }: {}}>
                <Bar data={stat ? stat.discreteClicks : null} totalClicks={stat ? stat.totalClicks : 0}/>
              </div>
            </div>
            <div className={classnames(classes.Pies_container)} style={stat ? {} : { filter: 'opacity(0.3) grayscale(1)' }}>
              <div className={classes.Pie_wrapper}>
                <Pie data={stat && stat.operationSystems ? stat.operationSystems : null}/>
                <p>OS</p>
              </div>
              <div className={classes.Pie_wrapper}>
                <Pie data={stat && stat.devices ? stat.devices : null}/>
                <p>Platforms</p>
              </div>
              <div className={classes.Pie_wrapper}>
                <Pie data={(stat && stat.browsers) ? stat.browsers : null}/>
                <p>Browsers</p>
              </div>
              <div className={classes.Pie_wrapper}>
                <Pie data={stat && stat.countries ? stat.countries : null}/>
                <p>Countries</p>
              </div>
              <div className={classes.Pie_wrapper}>
                <Pie data={stat && stat.cities ? stat.cities : null}/>
                <p>Cities</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
