import mixpanel from 'mixpanel-browser'
import React from 'react'

import { ErrorMessage } from 'src/components/shared/errorMessage/ErrorMessage'
import { TextArea } from 'src/components/shared/inputs/TextArea'


interface Props {
  methods: any
}

export const Description: React.FC<Props> = ({ methods }) => {
  const trackEditDescription = () => {
    mixpanel.track('edit_description_click')
  }
  return (
    <>
      <TextArea
        {...methods}
        withCounter
        rules={{ maxLength: 255 }}
        name="body"
        title="Description"
        placeholder="Input Description"
        onClick={trackEditDescription}
      />
      {methods.errors.body?.type === 'maxLength' ? (
        <ErrorMessage>
            Your input exceed maxLength {methods.watch().body.length}/255
        </ErrorMessage>
      ) : null}
    </>
  )
}
