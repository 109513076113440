import classnames from 'classnames'
import React from 'react'

import { Callback } from 'src/common'

import classes from './ButtonGroup.module.css'


interface Button {
  title: string
  action: Callback
  isSelected?: boolean
}

interface Props {
  className?: string
  list: Button[]
}

export const ButtonGroup: React.FC<Props> = ({ className,  list }) => {
  return (
    <div className={ classnames(classes['button-group'], className) }>
      { list.map((btn, i) =>
        <button
          key={ i }
          type="button"
          className={ classnames({ [classes['button-item']]: true, [classes.selected]: btn.isSelected }) }
          onClick={ btn.action }
        >{ btn.title }</button>
      )}
    </div>
  )
}
