import classnames from 'classnames'
import React from 'react'

import classes from './Button.module.css'


interface Props {
  isActive?: boolean
  onClick: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
  button_styles?: React.CSSProperties
  btn_class?: string
  type?: any
}

export const Button: React.FC<Props> = ({ isActive = true, onClick, children, button_styles, btn_class, type }) => {

  return (
    <button
      className={classnames(classes.root, { [classes.active]: isActive }, btn_class)}
      onClick={onClick}
      disabled={!isActive}
      style={button_styles}
      type={type}
    >
      {children}
    </button>
  )
}
