
export const configs: Record<typeof process.env.REACT_APP_ENVIRONMENT, Config> = {
  dev: {
    apis: {
      core: {
        implementation: 'mock',
      },
    },
  },
  prd: {
    apis: {
      core: {
        implementation: 'fetch',
        baseUrl: 'https://api.piar.io',
      },
    },
  },
}

export type CoreApiConfig = {
  implementation: 'fetch'
  baseUrl: string
} | {
  implementation: 'mock'
}

export interface Config {
  apis: {
    core: CoreApiConfig
  }
}
