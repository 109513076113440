import React from 'react'


interface Props {
    className?: string
}

export const IcoDelete:React.FC<Props> = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 16 16.9995"><defs/><path d="M12.9639,16.9995H3.0356L2.1978,3.9092H0v-1h4.9541L5.7744,0h4.4521l0.8193,2.9092H16v1h-2.1982 L12.9639,16.9995z M3.9741,15.9995h8.0522l0.7734-12.0903H3.2002L3.9741,15.9995z M5.9932,2.9092h4.0137L9.4688,1H6.5322 L5.9932,2.9092z" /></svg>
)
