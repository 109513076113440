import React from 'react'


interface Props {
    className?: string
}

export const IcoClose:React.FC<Props> = ({ className }) => (
  <svg className={ className }  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 18.8281 18.8281"><defs/><path d="M17.4141 18.8281L9.4141 10.8281 1.4141 18.8281 0 17.4141 8 9.4141 0 1.4141 1.4141 0 9.4141 8 17.4141 0 18.8281 1.4141 10.8281 9.4141 18.8281 17.4141z"/></svg>
)
