// import { resolve } from 'dns'

import { CoreApi } from 'src/apis/core'
import { fetchCoreApi } from 'src/apis/core/fetch'
import { CoreApiConfig, configs, Config } from 'src/config'

import { DomainStatus } from './models/Domain'
import { PreviewStatus } from './models/PreviewHistoryItem'


const mockedMembers = {
  'content':[{
    'id':1206,
    'email':'y.ohrimenko+demo12345@gravitec.net',
    'status':'NEW',
    'createdAt':'2021-02-24T17:58:31.274244Z',
  }],
  'pageable':{ 'sort':{ 'sorted':false,
    'unsorted':true,
    'empty':true },
  'offset':0,
  'pageNumber':0,
  'pageSize':5,
  'paged':true,
  'unpaged':false },
  'totalPages':1,
  'totalElements':1,
  'last':true,
  'size':5,
  'number':0,
  'sort':{ 'sorted':false,
    'unsorted':true,
    'empty':true },
  'numberOfElements':1,
  'first':true,
  'empty':false,
}

const mockedStat = {
  'totalClicks': 14,

  'browsers':[{ 'value':'Chrome', 'amount':8 }, { 'value':'Chrome Webview', 'amount':5 }, { 'value':'Safari', 'amount':1 }], 'devices':[{ 'value':'MOBILE', 'amount':8 }, { 'value':'DESKTOP', 'amount':6 }], 'operationSystems':[{ 'value':'Android', 'amount':6 }, { 'value':'Windows NT', 'amount':4 }, { 'value':'iOS', 'amount':2 }, { 'value':'Mac OS X', 'amount':2 }], 'referrers':[{ 'value':'empty', 'amount':14 }], 'cities':[{ 'value':'Kyiv', 'amount':5 }, { 'value':'Lviv', 'amount':2 }, { 'value':'Frankfurt am Main', 'amount':2 }, { 'value':'Sarasota', 'amount':1 }, { 'value':'Korostyshiv', 'amount':1 }, { 'value':'Menlo Park', 'amount':1 }, { 'value':'Mariupol', 'amount':1 }, { 'value':'Dublin', 'amount':1 }], 'countries':[{ 'value':'Ukraine', 'amount':9 }, { 'value':'Germany', 'amount':2 }, { 'value':'United States', 'amount':2 }, { 'value':'Ireland', 'amount':1 }],

  'discreteClicks': [
    {
      'value': '2020-08-28T00:00:00Z',
      'amount': 0,
    },
    {
      'value': '2020-08-28T01:00:00Z',
      'amount': 0,
    },
    {
      'value': '2020-08-28T02:00:00Z',
      'amount': 0,
    },
    {
      'value': '2020-08-28T03:00:00Z',
      'amount': 0,
    },
    {
      'value': '2020-08-28T04:00:00Z',
      'amount': 0,
    },
    {
      'value': '2020-08-28T05:00:00Z',
      'amount': 0,
    },
    {
      'value': '2020-08-28T06:00:00Z',
      'amount': 1,
    },
    {
      'value': '2020-08-28T07:00:00Z',
      'amount': 1,
    },
    {
      'value': '2020-08-28T08:00:00Z',
      'amount': 0,
    },
    {
      'value': '2020-08-28T09:00:00Z',
      'amount': 3,
    },
    {
      'value': '2020-08-28T10:00:00Z',
      'amount': 2,
    },
    {
      'value': '2020-08-28T11:00:00Z',
      'amount': 4,
    },
    {
      'value': '2020-08-28T12:00:00Z',
      'amount': 2,
    },
    {
      'value': '2020-08-28T13:00:00Z',
      'amount': 1,
    },
    {
      'value': '2020-08-28T14:00:00Z',
      'amount': 0,
    },
    {
      'value': '2020-08-28T15:00:00Z',
      'amount': 0,
    },
    {
      'value': '2020-08-28T16:00:00Z',
      'amount': 0,
    },
    {
      'value': '2020-08-28T17:00:00Z',
      'amount': 0,
    },
    {
      'value': '2020-08-28T18:00:00Z',
      'amount': 0,
    },
    {
      'value': '2020-08-28T19:00:00Z',
      'amount': 0,
    },
    {
      'value': '2020-08-28T20:00:00Z',
      'amount': 0,
    },
    {
      'value': '2020-08-28T21:00:00Z',
      'amount': 0,
    },
    {
      'value': '2020-08-28T22:00:00Z',
      'amount': 0,
    },
    {
      'value': '2020-08-28T23:00:00Z',
      'amount': 0,
    },

  ],
}

const mockedPreviewPayload = {
  'id': 0,
  'hash': '91qtB',
  'link': 'https://app.piar.io/',
  'title': 'Piar.io — No-code previews generator',
  'image': 'https://i.ibb.co/B3nRtsf/Screenshot-2020-09-03-at-13-51-48.png',
  'body': 'With Piar.io you can edit and experiment with your content then preview how your webpage will look on Facebook, Twitter, Telegram and more!',
  'domain': 'https://piar.me',
  'name': 'MyPreview',
  'createdAt': '2021-02-11T20:51:46.483Z',
}

const mockedPreview = {
  createdAt: '2020-09-24T09:08:53.73617Z',
  body: 'With Piar.io you can edit and experiment with your content then preview how your webpage will look on Facebook, Twitter, Telegram and more!',
  hash: '91qtB',
  updatedAt: '2021-02-24T20:21:29.800378Z',
  id: 42,
  image: 'https://i.ibb.co/B3nRtsf/Screenshot-2020-09-03-at-13-51-48.png',
  link: 'https://app.piar.io/',
  name: 'MyPreview',
  domain: 'https://piar.me',
  title: 'Piar.io — No-code previews generator',
  stat: {
    totalClicks: 42,
  },
  status: 'ACTIVE' as PreviewStatus,
  statShared: true,
  externalStatHash: 'somehash',
  createdBy: 'nvipus24@gmail.com',
  favorite: false,
}

const mockedAnalytic = {
  content: [{
    'id': 17,
    'hash': 'IeBa3',
    'link': 'https://app.piar.io',
    'name': 'Untitled',
    title: 'Piar.io — No-code previews generator',
    image: 'https://i.ibb.co/B3nRtsf/Screenshot-2020-09-03-at-13-51-48.png',
    body: 'With Piar.io you can edit and experiment with your content then preview how your webpage will look on Facebook, Twitter, Telegram and more!',
    'createdAt': '2021-01-19T20:55:40.784108+02:00',
    'domain': 'https://piar.io',
    'stat': {
      'total': 0,
      'yesterday': 0,
      'lastWeek': 0,
      'lastMonth': 0,
    },
  }],
  empty: true,
  first: true,
  last: false,
  number: 1,
  numberOfElements: 10,
  pageable: {
    page: 1,
    size: 10,
    offset: 1,
    pageNumber: 1,
    pageSize: 10,
    paged: false,
    unpaged: true,
  },
  size: 10,
  sort: { sorted: true, unsorted: false, empty: true },
  totalElements: 10,
  totalPages: 1,
}

const mockedAuthors = { 'content':['nvipus24@gmail.com'], 'pageable':{ 'sort':{ 'sorted':false, 'unsorted':true, 'empty':true }, 'offset':0, 'pageNumber':0, 'pageSize':5, 'paged':true, 'unpaged':false }, 'last':true, 'totalPages':1, 'totalElements':1, 'size':5, 'number':0, 'sort':{ 'sorted':false, 'unsorted':true, 'empty':true }, 'first':true, 'numberOfElements':1, 'empty':false }


const initCoreApi = (config: CoreApiConfig): CoreApi => {
  switch (config.implementation) {
    case 'fetch':
      return fetchCoreApi({ baseUrl: config.baseUrl })
    case 'mock':
      return {
        confirmMember: () => new Promise(resolve => setTimeout(() => {
          console.log('[MOCKED REQUEST]', 'confirmMember')
          return resolve({
            id: 123,
            email: 'v.maystrovy1@gravitec.net',
            status: 'NEW',
            bearerToken: 'asdadjglf',
          })
        }, 500)),
        inviteMember: () => new Promise(resolve => setTimeout(() => {
          console.log('[MOCKED REQUEST]', 'inviteMember')
          return resolve(null)
        }, 500)),
        deactivateMember: () => new Promise(resolve => setTimeout(() => {
          console.log('[MOCKED REQUEST]', 'deactivateMember')
          return resolve(null)
        }, 500)),
        reInviteMember: () => new Promise(resolve => setTimeout(() => {
          console.log('[MOCKED REQUEST]', 'reInviteMember')
          return resolve(null)
        }, 500)),
        restoreMember: () => new Promise(resolve => setTimeout(() => {
          console.log('[MOCKED REQUEST]', 'restoreMember')
          return resolve(null)
        }, 500)),
        getMembers: () => new Promise(resolve => setTimeout(() => {
          console.log('[MOCKED REQUEST]', 'getMembers')
          return resolve(mockedMembers)
        }, 500)),
        getAuthors: () => new Promise(resolve => setTimeout(() => {
          console.log('[MOCKED REQUEST]', 'getAuthors')
          return resolve(mockedAuthors)
        }, 500)),
        getPreviewPayload: () => new Promise(resolve => setTimeout(() => {
          console.log('[MOCKED REQUEST]', 'getPreviewPayload')
          return resolve(mockedPreviewPayload) 
        }, 500)),
        getAnalytics: () => new Promise(resolve => setTimeout(() => {
          console.log('[MOCKED REQUEST]', 'getAnalytics')
          return resolve(mockedAnalytic)}, 500)),
        getPreviewPublicContent: () => new Promise(resolve => setTimeout(() => {
          console.log('[MOCKED REQUEST]', 'getPreviewPublicContent')
          return resolve(mockedPreview)}, 500)),
        getPreviewPublicStat: () => new Promise(resolve => setTimeout(() => {
          console.log('[MOCKED REQUEST]', 'getPreviewPublicStat')
          return resolve(mockedStat)}, 500)),
        getPreviewsProgress: () => new Promise(resolve => setTimeout(() => resolve({
          limit: 40,
          active: 10,
        }), 500)),
        patchExternalStat: () => new Promise((resolve) => setTimeout(() => resolve({
          content: [mockedPreview],
          empty: true,
          first: true,
          last: false,
          number: 1,
          numberOfElements: 10,
          pageable: {
            page: 1,
            size: 10,
            offset: 1,
            pageNumber: 1,
            pageSize: 10,
            paged: false,
            unpaged: true,
          },
          size: 10,
          sort: { sorted: true, unsorted: false, empty: true },
          totalElements: 10,
          totalPages: 1,
        }), 1500)),
        restorePreview: () => Promise.resolve(null),
        archivePreview: () => Promise.resolve(null),
        generatePreview: () => new Promise( resolve => setTimeout(() => resolve({ generatedLink: 'http://example.com' }), 500)),
        removePreview: () => Promise.resolve(null),
        getPreviewStatistic: (payload) => {
          console.log('[MOCKED REQUEST]', 'getStatistic')
          if(payload?.period === 'LAST_DAY' || payload?.period === 'YESTERDAY'){
            return Promise.resolve(mockedStat)
          }
          if(payload?.period === 'WEEK'){
            return Promise.resolve({
              'totalClicks': 3,
              'discreteClicks': [
                {
                  'value': '2020-08-27T00:00:00Z',
                  'amount': Math.floor(Math.random()*10),
                },
                {
                  'value': '2020-08-27T00:00:00Z',
                  'amount': Math.floor(Math.random()*10),
                },
                {
                  'value': '2020-08-28T00:00:00Z',
                  'amount': Math.floor(Math.random()*10),
                },
                {
                  'value': '2020-08-29T00:00:00Z',
                  'amount': Math.floor(Math.random()*10),
                },
                {
                  'value': '2020-08-30T00:00:00Z',
                  'amount': Math.floor(Math.random()*10),
                },
                {
                  'value': '2020-08-31T00:00:00Z',
                  'amount': Math.floor(Math.random()*10),
                },
                {
                  'value': '2020-09-01T00:00:00Z',
                  'amount': Math.floor(Math.random()*10),
                },
                {
                  'value': '2020-09-02T00:00:00Z',
                  'amount': Math.floor(Math.random()*10),
                },
              ],
            })
          }
          return Promise.resolve({
            'totalClicks': 3,
            'discreteClicks': [
              {
                'value': '2020-08-02T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-08-03T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-08-04T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-08-05T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-08-06T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-08-07T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-08-08T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-08-09T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-08-10T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-08-11T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-08-12T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-08-13T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-08-14T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-08-15T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-08-16T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-08-17T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-08-18T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-08-19T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-08-20T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-08-21T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-08-22T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-08-24T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-08-25T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-08-26T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-08-27T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-08-28T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-08-29T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-08-30T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-08-31T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-09-01T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
              {
                'value': '2020-09-02T00:00:00Z',
                'amount': Math.floor(Math.random()*10),
              },
            ],
          })
        },

        signin: () => {
          console.log('[MOCKED REQUEST]', 'signin')
          return new Promise( (resolve, reject) => setTimeout(() => resolve({
            id: 123,
            email: 'v.maystrovy1@gravitec.net',
            status: 'NEW',
            bearerToken: 'asdadjglf',
          }), 500 ))},

        setNewPass: () => {
          console.log('[MOCKED REQUEST]', 'signin')
          return new Promise( (resolve, reject) => setTimeout(() => resolve({
            id: 123,
            email: 'v.maystrovy1@gravitec.net',
            status: 'NEW',
            bearerToken: 'asdadjglf',
          }), 500 ))},
        // signin: () => new Promise( (resolve, reject) => setTimeout(() => reject({
        //   error: 'Unconfirmed user!',
        // }), 500 )),

        signup: (payload) => {
          console.log('[MOCKED REQUEST]', 'signup', payload)
          return new Promise( resolve => setTimeout(() => resolve(null), 500))
        },

        authoriseWithProvider: () => {
          console.log('[MOCKED REQUEST]', 'authoriseWithProvider')
          return new Promise( (resolve, reject) => setTimeout(() => resolve({
            id: 123,
            email: 'local-test@gravitec.net',
            status: 'NEW',
            bearerToken: 'asdadjglf',
          }), 500 ))},

        getHistory: () => {
          console.log('[MOCKED REQUEST]', 'getHistory')
          // return new Promise((resolve, reject) => setTimeout(() => reject({error: 'Invalid creds!'}), 500))
          return new Promise((resolve) => setTimeout(() => resolve({
            content: [{
              createdAt: '2020-09-24T09:08:53.73617Z',
              updatedAt: '2021-02-24T20:21:29.800378Z',
              body: 'With Piar.io you can edit and experiment with your content then preview how your webpage will look on Facebook, Twitter, Telegram and more!',
              hash: '91qtB',
              id: 42,
              image: 'https://i.ibb.co/B3nRtsf/Screenshot-2020-09-03-at-13-51-48.png',
              link: 'https://app.piar.io/',
              name: 'MyPreview',
              domain: 'https://piar.me',
              title: 'Piar.io — No-code previews generator',
              stat: {
                totalClicks: 42,
              },
              status: 'ACTIVE',
              statShared: false,
              createdBy: 'nvipus24@gmail.com',
              favorite: false,
            }],
            empty: true,
            first: true,
            last: false,
            number: 1,
            numberOfElements: 10,
            pageable: {
              page: 1,
              size: 10,
              offset: 1,
              pageNumber: 1,
              pageSize: 10,
              paged: false,
              unpaged: true,
            },
            size: 10,
            sort: { sorted: true, unsorted: false, empty: true },
            totalElements: 10,
            totalPages: 1,
          }), 500))},

        confirmAccount: (payload) => {
          console.log('[MOCKED REQUEST]', 'confirm', payload)
          return new Promise((resolve, reject) => setTimeout(() => {
            if (payload === 'fail'){
              return reject({ 
                'status':'BAD_REQUEST',
                'error':'ServerWebInputException',
                'description':'Failed', 
              })
            }
            return resolve({
              id: 123,
              email: 'v.maystrovy1@gravitec.net',
              status: 'NEW',
              bearerToken: 'asdadjglf',
            })
          }, 500))
        },

        resendConfirmation: (email) => {
          console.log('[MOCKED REQUEST]', email)
          return new Promise((resolve, reject) => setTimeout(() => resolve(null), 500))
        },

        fetchMeta: (url) => {
          console.log('[MOCKED REQUEST]', url)
          return new Promise((resolve, reject) => setTimeout(() => resolve({
            title: 'Test title',
            description: 'Test body',
            images: [
              'https://sntch.com/uploads/2020/06/kathryn.kampovsky_66660256_945420249183357_8954746044461350565_n.jpg',
              'https://i.ibb.co/8XQ1fDq/12df243.png',
            ],
          }), 500))
        },

        getUserData: () => {
          console.log('[MOCKED REQUEST]', 'getUserData')
          return new Promise((resolve, reject) => setTimeout(() => resolve({
            'id': 1,
            'email': 'maystrovyy@gmail.com',
            'status': 'CLIENT',
            'createdAt': '2020-08-11T09:39:58.071537Z',
            isParent: true,
            'subscription': {
              'name': 'basic',
              'trial': true,
              'strategy': 'MONTHLY',
              'expiredAt': new Date('2020-09-07T13:54:47.944471Z'),
            },
            'payment': {
              'completed': true,
              'amount': 19.99,
            },
          }), 500))
        },
        startTrial: (payload) => {
          console.log('[MOCKED REQUEST]', 'startTrial', payload)
          return new Promise((resolve) => setTimeout(() => resolve({
            'id': 1,
            'email': 'maystrovyy@gmail.com',
            'status': 'PROSPECT',
            'createdAt': '2020-08-11T09:39:58.071537Z',
            isParent: true,

            'subscription': {
              'name': 'basic',
              'trial': true,
              'strategy': 'MONTHLY',
              'expiredAt': new Date('2020-09-11T09:39:58.071537Z'),
            },
            'payment': {
              'completed': true,
              'amount': 19.99,
            },
          }), 500))
        },
        startNewPlan: (payload) => {
          console.log('[MOCKED REQUEST]', 'startNewPlan', payload)
          return new Promise((resolve) => setTimeout(() => resolve({ redirectUrl:'https://google.com' }), 500))
        },
        completePayment: () => {
          console.log('[MOCKED REQUEST]', 'completePayment')
          return new Promise((resolve) => setTimeout(() => resolve({
            'id': 1,
            'email': 'maystrovyy@gmail.com',
            'status': 'CLIENT',
            isParent: true,

            'createdAt': '2020-08-11T09:39:58.071537Z',
            'subscription': {
              'name': 'basic',
              'trial': false,
              'strategy': 'MONTHLY',
              'expiredAt': new Date('2020-09-07T13:54:47.944471Z'),
            },
            'payment': {
              'completed': true,
              'amount': 19.99,
            },
          }), 500))
        },
        checkoutPayment: (payload) => {
          console.log('[MOCKED REQUEST]', 'checkoutPayment', payload)
          return new Promise((resolve) => setTimeout(() => resolve({ redirectUrl: 'https://google.com',
          }), 500))
        },
        getBillingPlans: () => {
          console.log('[MOCKED REQUEST]', 'getBillingPlans')
          return new Promise((resolve) => setTimeout(() => resolve([
            {
              'name':'basic',
              'monthlyPrice':19.99,
              'yearlyPrice':200.00 },
            {
              'name':'standard',
              'monthlyPrice':49.99,
              'yearlyPrice':500.00 },
            {
              'name':'enterprise',
              'monthlyPrice':79.99, 'yearlyPrice':800.00,
            },
          ]), 500))
        },
        uploadFile: () => {
          console.log('[MOCKED REQUEST]', 'uploadFile')
          return new Promise((resolve) => setTimeout(() => resolve({ url: 'https://s3.amazonaws.com/appforest_uf/f1556023187450x335485999270687100/zqlabmain.png' }), 500))
        },
        patchPreviewName: () => {
          console.log('[MOCKED REQUEST]', 'patchPreviewName')
          return new Promise( resolve => setTimeout(() => resolve(null), 500))
        },
        patchPreviewFavorite: () => {
          console.log('[MOCKED REQUEST]', 'patchPreviewFavorite')
          return new Promise( resolve => setTimeout(() => resolve(null), 500))
        },
        deleteAccount: () => {
          console.log('[MOCKED REQUEST]', 'deleteAccount')
          return new Promise( resolve => setTimeout(() => resolve(null), 500))
        },
        getDomainsList: () => {
          console.log('[MOCKED REQUEST]', 'getDomainsList')
          return new Promise( resolve => setTimeout(() => resolve([{
            createdAt: '2020-10-11T20:57:16.008Z',
            default: false,
            host: 'https://demo.piar.me',
            id: 10011,
            status: DomainStatus.ACTIVE,
          }, {
            createdAt: '2020-10-11T20:57:16.008Z',
            default: true,
            host: 'https://11111.piar.me',
            id: 10012,
            status: DomainStatus.ACTIVE,
          }, {
            createdAt: '2020-10-11T20:57:16.008Z',
            default: false,
            host: 'https://sdvasdvasfdv.piar.me',
            id: 10012,
            status: DomainStatus.PENDING,
          }]), 500))
        },
        setDefaultDomain: (number) => {
          console.log('[MOCKED REQUEST]', 'setDefaultDomain', number)
          return new Promise( resolve => setTimeout(() => resolve(null), 500))
        },
        createNewDomain: (host) => {
          console.log('[MOCKED REQUEST]', 'createNewDomain', host)
          return new Promise( resolve => setTimeout(() => resolve({
            createdAt: '2020-10-11T20:57:16.008Z',
            default: false,
            host: 'https://sdvasdvasfdv.piar.me',
            id: 10012,
            status: DomainStatus.PENDING,
          }), 500))
        },
        deleteDomain: (id) => {
          console.log('[MOCKED REQUEST]', 'deleteDomain', id)
          return new Promise( resolve => setTimeout(() => resolve(null), 500))
        },
        refreshDomain: (id) => {
          console.log('[MOCKED REQUEST]', 'refreshDomain', id)
          return new Promise( resolve => setTimeout(() => resolve({
            createdAt: '2020-10-11T20:57:16.008Z',
            default: false,
            host: 'https://sdvasdvasfdv.piar.me',
            id: 10012,
            status: DomainStatus.PENDING,
          }), 500))
        },
        resetPassword: (string) => {
          console.log('[MOCKED REQUEST]', 'resetPassword', string)
          return new Promise( resolve => setTimeout(() => resolve(null), 500))
        },
        signUpWithAppSumoCodes: (payload) => {
          console.log('[MOCKED REQUEST]', 'signUpWithAppSumoCodes', payload)
          return new Promise( (resolve, reject) => setTimeout(() => resolve({
            id: 123,
            email: 'v.maystrovy1@gravitec.net',
            status: 'NEW',
            bearerToken: 'asdadjglf',
          }), 500 ))
        },
        updateAppSumoCodes: (payload) => {
          console.log('[MOCKED REQUEST]', 'updateAppSumoCodes', payload)
          return new Promise((resolve) => setTimeout(() => resolve(null), 500))
        },
        getRESTAPIApps: () => {
          console.log('[MOCKED REQUEST]', 'getRESTAPIApps')
          return new Promise((resolve) => setTimeout(() => resolve(null), 500))
        },
        createRESTAPIApp: () => {
          console.log('[MOCKED REQUEST]', 'createRESTAPIApp')
          return new Promise((resolve) => setTimeout(() => resolve({ apiKey: 'test' }), 500))
        },
      }
  }
}

const initDependenciesFromConfig = (config: Config) =>
  ({
    coreApi: initCoreApi(config.apis.core),
  })

export const initDependencies = () => initDependenciesFromConfig(configs[process.env.REACT_APP_ENVIRONMENT])
