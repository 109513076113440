import Tippy from '@tippyjs/react'
import mixpanel from 'mixpanel-browser'
import React from 'react'

import { Callback } from 'src/common'
import { ButtonWithLoader } from 'src/components/shared/buttonWithLoader/buttonWithLoader'
import { ErrorMessage } from 'src/components/shared/errorMessage/ErrorMessage'
import { IcoMagic } from 'src/components/shared/icons/IcoMagic'
import { Input } from 'src/components/shared/inputs/Input'


interface Props {
    methods: any
    loading: boolean
    fetchMeta: Callback
}

export const DestinationURL:React.FC<Props> = ({
  methods, 
  loading, 
  fetchMeta, 
}) => {
  const trackEditDestinationURL = () => {
    mixpanel.track('edit_destinationurl_click')
  }
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Input
          {...methods}
          inputId="dest-url"
          rules={{ required: 'Required', maxLength: 1024 }}
          name="link"
          input_styles={{
            borderBottomRightRadius: '0px',
            borderTopRightRadius: '0px',
          }}
          style={{ width: 'calc(100% - 50px)' }}
          title="Destination URL"
          placeholder="Input URL"
          onClick={trackEditDestinationURL}
        />
        <Tippy 
          content='
            Automatically fill in link previews parameters (title, description, picture) 
            from the destination URL
          '
          placement='bottom'
        >
          <ButtonWithLoader
            button_styles={{
              height: '48px',
              borderRadius: '0px 4px 4px 0px',
              width: '50px',
              display: 'flex',
              alignItems: 'center',
              padding: '8px 10px 8px 10px',
            }}
            loader_styles={{ marginLeft: '-85px' }}
            isActive={!loading}
            isLoading={!loading}
            onClick={fetchMeta}
          >
            <IcoMagic />
          </ButtonWithLoader>
        </Tippy>
      </div>
      {methods.errors.link && 
        <ErrorMessage>
          {methods.errors.link.message}
        </ErrorMessage>}
    </>
  )}
