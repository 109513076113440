import classnames from 'classnames'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'

import buttonStyles from 'src/components/shared/button/Button.module.css'
import { ErrorMessage } from 'src/components/shared/errorMessage/ErrorMessage'
import inputStyles from 'src/components/shared/inputs/inputs.module.css'
import { useAuth } from 'src/hooks/useAuth'
import { useCoreApi } from 'src/hooks/useCoreApi'



export const MemberSetPass = () => {
  const coreApi = useCoreApi()
  const history = useHistory()
  const auth = useAuth()
  const { hash } = useParams() as {hash: string}
  const { handleSubmit, register, errors, setError } = useForm<any>()
  const [apiError, setApiError] = useState<string>()
  const submit = ({ pass, passConfirm }: {pass: string, passConfirm: string}) => {
    if(pass !== passConfirm){
      return setError('passConfirm', { message: 'The values should be equal' })
    }
    setIsSubmiting(true)
    coreApi.confirmMember({ hash, pass }).then((resp) => {
      auth.setToken(resp?.bearerToken)
      localStorage.setItem('bearerToken', resp.bearerToken)
      history.push('/builder')
    }).catch((error) => error.causes ? error.causes.length && setApiError(error.causes[0]) : error.error && setApiError(error.error)).finally(() => setIsSubmiting(false))
  }
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false)
  return (
    <form style={{
      display: 'grid',
      rowGap: '24px',
      maxWidth: '500px',
      margin: 'auto',
      marginTop: '32px',
      width: '300px',
      backgroundColor: '#fff',
      padding: '24px',
      borderRadius: '16px',
    }} onSubmit={ handleSubmit(submit) } action="">
      <h3 style={{
        color: '#40466E',
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: '1px',
        lineHeight: '16px',
        textTransform: 'uppercase',
      }}>Set your password</h3>
      <div>
        <label htmlFor="pass" className={ inputStyles.title }>Password</label>
        <input
          className={ classnames(inputStyles.input) }
          type="password"
          placeholder="Input Password"
          name="pass"
          id="pass"
          // autoComplete="password"
          ref={ register({
            required: 'Required',
          })}
        />
        {errors.pass ? <ErrorMessage>{errors.pass.message}</ErrorMessage> : null}
      </div>
      <div>
        <label htmlFor="pass-confirm" className={ inputStyles.title }>Confirm password</label>
        <input
          className={ classnames(inputStyles.input) }
          type="password"
          placeholder="Confirm password"
          name="passConfirm"
          id="pass-confirm"
          // autoComplete="password"
          ref={ register({
            required: 'Required',
          })}
        />
        {errors.passConfirm ? <ErrorMessage>{errors.passConfirm.message}</ErrorMessage> : null}
      </div>
      {apiError ? <div><ErrorMessage>{apiError}</ErrorMessage></div> : null}
      <div>
        {isSubmiting ? <span>Loading...</span> : null}
        <button
          className={ classnames(buttonStyles['root'], buttonStyles['btn-accent-1']) }
          type="submit"
          // onClick={ () => setInviteModalVisible(false) }
        >Submit</button>
      </div>
    </form>
  )
}
