import classnames from 'classnames'
import React, { useState } from 'react'
import { toast } from 'react-toastify'

import { Callback } from 'src/common'
import buttonStyles from 'src/components/shared/button/Button.module.css'
import { Domain, DomainStatus } from 'src/models/Domain'

import { IcoCircleCheck } from '../shared/icons/icoCircleCheck'
import { IcoCircleXmark } from '../shared/icons/icoCircleXmark'
import { IcoExternal } from '../shared/icons/icoExternal'

import classes from './DomainsSettings.module.css'


interface Props {
    deleteDomain: (id: number) => void
    setDefaultDomain: (id: number) => void
    refreshDomain: (id: number) => Promise<Domain>
    domain: Domain
}

export const DomainSettings: React.FC<Props> = ({ deleteDomain, refreshDomain, domain, setDefaultDomain }: Props) => {
  const [refreshing, setRefreshing] = useState<boolean>(false)
  const isDomainActive = domain.status === DomainStatus.ACTIVE

  const refresh = () => {
    setRefreshing(true)

    refreshDomain(domain.id)
      .finally(() => setRefreshing(false))
  }

  const DeleteAlert = ({ onUndo, closeToast }: { onUndo: Callback, closeToast?: Callback }) => {
    const handleClick = () => {
      onUndo()
      closeToast && closeToast()
    }

    return (
      <div className={ classes['delete-alert']}>
        Domain is deleting
        <button
          type="button"
          onClick={ handleClick }
        >UNDO</button>
      </div>
    )
  }

  const onDelete = () => {
    let enableToDelete = true
    const autoClose = 5000

    toast(<DeleteAlert onUndo={ () => enableToDelete = false } />, {
      autoClose,
      hideProgressBar: false,
      closeOnClick: false,
      closeButton: false,
      toastId: domain.id,
      onClose: () => {
        if (enableToDelete) {
          deleteDomain(domain.id)
        }
      },
    })
  }

  return ( 
    <>
      <div className={ classes['settings-header'] }>
        <a className={ classes['domain-url'] } href={domain.host} target='_blank' rel='noopener noreferrer'>
          <h3>{domain.host}</h3>
          <IcoExternal className={ classes['icon'] } />
        </a>
        <div className={ classes['actions-wrapper'] }>
          <button 
            disabled={refreshing} 
            onClick={refresh} 
            className={classnames(
              buttonStyles['root'], 
              buttonStyles['btn-accent-1'], 
              buttonStyles['btn-sm']
            )}>
            { refreshing ? 'Refreshing...' : 'Refresh' }
          </button>
          {/* <button className={classnames(
            buttonStyles['root'], 
            buttonStyles['btn-accent-1'], 
            buttonStyles['btn-sm']
          ) }>Edit</button> */}
          {
            domain.status === DomainStatus.ACTIVE ? (
              <button 
                disabled={domain.default}
                onClick={() => setDefaultDomain(domain.id)} 
                className={ classnames(
                  buttonStyles['root'], 
                  buttonStyles['btn-accent-1'], 
                  buttonStyles['btn-sm']
                )}
              >
                { domain.default ? 'Default' : 'Set as default' }
              </button>
            ) : null
          }
          <button 
            style={{ backgroundColor: '#dc483a' }} 
            onClick={onDelete} 
            className={ classnames(
              buttonStyles['root'], 
              buttonStyles['btn-accent-1'], 
              buttonStyles['btn-sm']
            ) }
          >
            Delete
          </button>
        </div>
      </div>
      <div className={ classnames( classes['status'], !isDomainActive ? classes['failed'] : '' ) }>
        <p>
          {
            !isDomainActive ? (
              <>
                <IcoCircleXmark className={classes['status-icon']} />
                Configuration is not  active now
              </>
            ) : (
              <>
                <IcoCircleCheck className={classes['status-icon']} />
                Valid configuration
              </>
            )
          } 
        </p>
      </div>
    </>
  )
}
