import React from 'react'

import classes from './ErrorMessage.module.css'


export const ErrorMessage: React.FC = ({ children }) => (
  <span className={classes.root}>
    {children}
  </span>
)
