import React, { FC } from 'react'

import classes from '../PinterestPreview/PinterestPreview.module.css'
import { PreviewProps } from '../PreviewList'


export const PinterestPreview: FC<PreviewProps> = ({ title, image }) => (
  <div className={classes.card_seo_pinterest}>
    <div className={classes.card_seo_pinterest__image}>
      <img
        className={classes.pinterest_image}
        src={image}
        alt="preview"
      />
    </div>

    <div className={classes.card_seo_pinterest__content}>
      <div className={classes.card_seo_pinterest__title}>
        {title}
      </div>
      <div className={classes.card_seo_pinterest__dots}>
        <div className={classes.card_seo_pinterest__dot}></div>
        <div className={classes.card_seo_pinterest__dot}></div>
        <div className={classes.card_seo_pinterest__dot}></div>
      </div>
    </div>
  </div>
)
