import React, { useEffect, useState } from 'react'

import { PreviewPayloadResponse, StatisticResponse } from 'src/apis/core'
import chartsClasses from 'src/components/StatisticModal/StatisticModal.module.css'
import { ButtonGroup } from 'src/components/shared/buttonGroup/ButtonGroup'
// import { Bar } from 'src/components/shared/charts/Bar'
import { GroupedBar, GroupedBarHorizontal } from 'src/components/shared/charts/GroupedBar'
import { mockedBrowsers, mockedCities, mockedCountries, mockedDevices, mockedOs } from 'src/components/shared/charts/mockedBars'
import { mockedData } from 'src/components/shared/charts/mockedDiscreteClicksChartData'
import { useCoreApi } from 'src/hooks/useCoreApi'
import { useQuery } from 'src/hooks/useQuery'
import { StatisticItem } from 'src/models/Statistic'

import classes from './PreviewsComparing.module.css'



// const prepareDataToRender = (data: StatisticItem[][]) => {
//   // console.log(data)
//   // data[0][data[0].findIndex(item => item.value === '2021-02-11T02:00:00')] = {value: '2021-02-11T02:00:00', amount: 5}
//   // data[1][data[1].findIndex(item => item.value === '2021-02-11T02:00:00')] = {value: '2021-02-11T02:00:00', amount: 8}
//   // data[2][data[2].findIndex(item => item.value === '2021-02-11T02:00:00')] = {value: '2021-02-11T02:00:00', amount: 4}
    
//   const keys = data.map((_, index) => `amount${index}`)
//   let arr: any[] = []
//   data.forEach((item, wrapperIndex) => {!arr.length ? item.forEach((statItem, index) => {
//     arr[index] = { ...arr[index], [`amount${wrapperIndex}`]: statItem.amount, value: statItem.value }
//   })
//     :item.forEach((statItem, index) => arr.findIndex(i => i.value === statItem.value) !== -1 ? arr[arr.findIndex(i => i.value === statItem.value)] = { ...arr[index], [`amount${wrapperIndex}`]: statItem.amount } : arr = [{ [`amount${wrapperIndex}`]: statItem.amount, value: statItem.value }, ...arr])
//   }) 
//   return { data: arr, keys }
// }

const getCreatedDate = (date: string) => new Date(date).toLocaleString('en-GB', { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })

const noStatsAtAll = (previewsStats: Array<StatisticResponse>) => previewsStats.every(item => item.totalClicks === 0)
const noDiscreteStats = (previewsStats: Array<StatisticResponse>) => previewsStats.every(item => item.discreteClicks.every(clicks => clicks.amount === 0))

export const PreviewsComparing: React.FC = () => {    
  const query = useQuery('ids')?.split(',')
  const [ids] = useState(() => query && query?.length >= 5 ? query?.slice(0, 5) : query)
  const coreApi = useCoreApi()
  const [previewsPayload, setPreviewsPayload] = useState<Array<PreviewPayloadResponse>>([])
  const [previewsStats, setPreviewsStats] = useState<Array<StatisticResponse>>([])
  const [loading, setLoading] = useState(false)
  const colors = ['rgb(102, 194, 165)', 'rgb(252, 141, 98)', 'rgb(141, 160, 203)', 'rgb(231, 138, 195)', 'rgb(166, 216, 84)']
  const [statisticPeriod, setStatisticPeriod] = useState<'LAST_DAY' | 'YESTERDAY' | 'WEEK' | 'MONTH' | 'LIFETIME'>('LAST_DAY')
  const periodButtons = [
    { title: 'Last day', action: () => setStatisticPeriod('LAST_DAY'), isSelected: statisticPeriod === 'LAST_DAY' },
    { title: 'Yesterday', action: () => setStatisticPeriod('YESTERDAY'), isSelected: statisticPeriod === 'YESTERDAY' },
    { title: 'Week', action: () => setStatisticPeriod('WEEK'), isSelected: statisticPeriod === 'WEEK' },
    { title: 'Month', action: () => setStatisticPeriod('MONTH'), isSelected: statisticPeriod === 'MONTH' },
    // { title: 'All time', action: () => setStatisticPeriod('LIFETIME'), isSelected: statisticPeriod === 'LIFETIME' },
  ]
  useEffect(() => {
    Promise.all((ids as string[]).map(id => coreApi.getPreviewPayload({ id:Number(id) })))
      .then(payloads => setPreviewsPayload(payloads))
  }, [coreApi, ids])

  useEffect(() => {
    setPreviewsStats([])
    setLoading(true)
    Promise.all(
      (ids as string[]).map(id => coreApi.getPreviewStatistic({ id: Number(id), period: statisticPeriod }))
    ).then(stat => {
      setPreviewsStats(stat as unknown as StatisticResponse[])
    }).finally(() => setLoading(false))
  }, [coreApi, ids, statisticPeriod])

  // previewsStats.length && console.log(prepareDataToRender(previewsStats.map(stat => stat.browsers) as StatisticItem[][]))
  return (
    <div className={classes.root}>
      <div className={classes.cards}>{previewsPayload.map((preview, index) => (
        <div className={classes.card} key={preview.id} style={{
          border: `2px solid ${colors[index]}`,
        }}>
          <img src={preview.image} alt="" className={classes.image}/>
          <div className={classes['card-content']}>
            <span>{getCreatedDate(preview.createdAt)}</span>
            <span>Clicks: {previewsStats[index] ? previewsStats[index].totalClicks : '...waiting'}</span>
            <h2>{preview.name}</h2>
            <span style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}>{preview.link}</span>
          </div>
        </div>))}
      </div>
      <div>
        <div className={classes['charts-wrapper']} style={{
          filter: noStatsAtAll(previewsStats) || loading ? 'grayscale(1)' : 'none',
          pointerEvents: noStatsAtAll(previewsStats) || loading ? 'none' : 'all',
        }}>
          {noStatsAtAll(previewsStats) || loading ? <div className={classes.Statistic__overlay}>
            { 
              loading ? 
                <p>Stats is loading...</p> 
                :
                <p>There is no statistics yet. Share the link to your clients to see more...</p>
            }
          </div> : null}
          <div className={chartsClasses['period-picker']}>
            <h5 className={ chartsClasses.caption }>Clicks</h5>
            <ButtonGroup
              list={ periodButtons }
            />
          </div>
          <div style={{ height: '300px', position:'relative', filter: noDiscreteStats(previewsStats) && !noStatsAtAll(previewsStats) ? 'grayscale(1)' : 'none', pointerEvents: noDiscreteStats(previewsStats) && !noStatsAtAll(previewsStats) ? 'none' : 'all' }}>
            {noDiscreteStats(previewsStats) && !noStatsAtAll(previewsStats) ? <div className={classes.Statistic__overlay}>
                  
              <p>There is no statistics by this period.</p>
            
            </div>: null}
            <GroupedBar styles={{ minWidth: 'auto' }} period={statisticPeriod} 
              data={(noStatsAtAll(previewsStats) || noDiscreteStats(previewsStats)) && !loading ? mockedData : previewsStats.map(stat => stat.discreteClicks)}
            />
          </div>
          <div className={classes['bars-wrapper']}>
            <div><span style={{
              color: '#8A8F98',
              fontSize: '12px',
              fontWeight: 500,
              letterSpacing: '0.5px',
              lineHeight: '16px',
              textTransform: 'uppercase',
            }}>Browsers</span>
            <div style={{ height: '250px', 
            // width: '216px'
            }}>
              <GroupedBarHorizontal 
                data={noStatsAtAll(previewsStats) && !loading ? mockedBrowsers : previewsStats.map(stat => stat.browsers) as StatisticItem[][]}
              />
            </div>
            </div>
            <div><span style={{
              color: '#8A8F98',
              fontSize: '12px',
              fontWeight: 500,
              letterSpacing: '0.5px',
              lineHeight: '16px',
              textTransform: 'uppercase',
            }}>Cities</span>
            <div style={{ height: '250px',
            //  width: '216px'
            }}>
              <GroupedBarHorizontal 
                data={noStatsAtAll(previewsStats) && !loading ? mockedCities : previewsStats.map(stat => stat.cities) as StatisticItem[][]}
              />
            </div></div>
            <div><span style={{
              color: '#8A8F98',
              fontSize: '12px',
              fontWeight: 500,
              letterSpacing: '0.5px',
              lineHeight: '16px',
              textTransform: 'uppercase',
            }}>Countries</span>
            <div style={{ height: '250px', 
            // width: '216px' 
            }}>
              <GroupedBarHorizontal 
                data={noStatsAtAll(previewsStats) && !loading ? mockedCountries : previewsStats.map(stat => stat.countries) as StatisticItem[][]}
              /></div></div>
            <div><span style={{
              color: '#8A8F98',
              fontSize: '12px',
              fontWeight: 500,
              letterSpacing: '0.5px',
              lineHeight: '16px',
              textTransform: 'uppercase',
            }}>Devices</span>
            <div style={{ height: '250px', 
            // width: '216px' 
            }}>
              <GroupedBarHorizontal 
                data={noStatsAtAll(previewsStats) && !loading ? mockedDevices : previewsStats.map(stat => stat.devices) as StatisticItem[][]}
              /></div></div>
            <div><span style={{
              color: '#8A8F98',
              fontSize: '12px',
              fontWeight: 500,
              letterSpacing: '0.5px',
              lineHeight: '16px',
              textTransform: 'uppercase',
            }}>OS</span>
            <div style={{ height: '250px', 
            // width: '216px' 
            }}>
              <GroupedBarHorizontal 
                data={noStatsAtAll(previewsStats) && !loading ? mockedOs : previewsStats.map(stat => stat.operationSystems) as StatisticItem[][]}
              />
            </div></div>

          </div>
        </div>
      </div>
    </div>
  )
}
