import React from 'react'

import { Callback } from 'src/common'

import { Button } from '../button/Button'

import classes from './buttonWithLoader.module.css'


interface Props {
  isActive?: boolean
  isLoading?: boolean
  onClick: Callback
  button_styles?: React.CSSProperties
  loader_styles?: React.CSSProperties
  style?: React.CSSProperties
}

export const ButtonWithLoader: React.FC<Props> = React.forwardRef<HTMLDivElement, Props>(({ isActive = true, onClick, children, button_styles, loader_styles, style, isLoading }, ref) => {
  return (
    <div className={classes.root} style={style} ref={ref}>
      <Button isActive={isActive} onClick={onClick} button_styles={button_styles}>{children}</Button>
      {isLoading ? '' : <svg style={{ background: 'none', display: 'block', shapeRendering: 'auto', ...loader_styles }} width="30px" height="30px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" fill="none" stroke="#0d4d96" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138">
          <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"/>
        </circle>
      </svg>}
    </div>
  )
})

ButtonWithLoader.displayName = 'ButtonWithLoader'
