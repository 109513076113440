import React from 'react'


interface Props {
    className?: string
}

export const IcoStats:React.FC<Props> = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 16.333 16.3335"><defs/><path d="M13.2568,13.3335H1.0767C-0.0684,13.3335-1,12.4019-1,11.2563V-0.9233C-1-2.0684-0.0684-3,1.0767-3 h12.1802c1.1445,0,2.0762,0.9316,2.0762,2.0767v12.1797C15.333,12.4019,14.4014,13.3335,13.2568,13.3335z M1.0767-2 C0.4829-2,0-1.5171,0-0.9233v12.1797c0,0.5938,0.4829,1.0771,1.0767,1.0771h12.1802c0.5938,0,1.0762-0.4834,1.0762-1.0771V-0.9233 C14.333-1.5171,13.8506-2,13.2568-2H1.0767z" transform="translate(1 3)"/><path d="M3.4175 7.145c-.8271 0-1.5-.6729-1.5-1.5s.6729-1.5 1.5-1.5c.8276 0 1.5005.6729 1.5005 1.5S4.2451 7.145 3.4175 7.145zM3.4175 5.145c-.2759 0-.5.2246-.5.5s.2241.5.5.5.5005-.2246.5005-.5S3.6934 5.145 3.4175 5.145zM7.1904 10.3062c-.8271 0-1.5-.6729-1.5-1.5s.6729-1.5 1.5-1.5 1.5.6729 1.5 1.5S8.0176 10.3062 7.1904 10.3062zM7.1904 8.3062c-.2754 0-.5.2246-.5.5s.2246.5.5.5.5-.2246.5-.5S7.4658 8.3062 7.1904 8.3062zM10.9629 3.9766c-.8271 0-1.5-.6729-1.5-1.5s.6729-1.5 1.5-1.5 1.5.6729 1.5 1.5S11.79 3.9766 10.9629 3.9766zM10.9629 1.9766c-.2754 0-.5.2241-.5.5s.2246.5.5.5.5-.2241.5-.5S11.2383 1.9766 10.9629 1.9766z" transform="translate(1 3)"/><path d="M-0.1797,9.2983l-0.6406-0.7676L2.4365,5.814l0.6406,0.7676L-0.1797,9.2983z M6.21,8.6372 L3.7559,6.5806l0.6426-0.7656l2.4541,2.0566L6.21,8.6372z M8.0605,8.3228L7.2012,7.811l2.8926-4.8521l0.8594,0.5117L8.0605,8.3228z M14.5098,6.1333l-3.2109-2.7222l0.6465-0.7627l3.2109,2.7212L14.5098,6.1333z" transform="translate(1 3)"/></svg>
)
