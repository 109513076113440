import Tippy from '@tippyjs/react'
import classnames from 'classnames'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Callback } from 'src/common'
import { useClipboard } from 'src/hooks/useClipboard'
import { useCoreApi } from 'src/hooks/useCoreApi'
import { Preview } from 'src/models/Preview'
import { PreviewHistoryItem } from 'src/models/PreviewHistoryItem'

import { InputWithAction } from '../InputWithAction/InputWithAction'
// import { Button } from '../shared/button/Button'
import buttonClasses from '../shared/button/Button.module.css'
import { IcoDelete } from '../shared/icons/IcoDelete'
import { IcoDuplicate } from '../shared/icons/IcoDuplicate'
import { IcoEdit } from '../shared/icons/IcoEdit'
import { IcoSave } from '../shared/icons/IcoSave'
import { IcoStats } from '../shared/icons/IcoStats'
import { IcoClose } from '../shared/icons/icoClose'
import inputStyles from '../shared/inputs/inputs.module.css'

import '../shared/tippyjs/tippyjs.css'
import classes from './HistoryItem.module.css'

import mixpanel from 'mixpanel-browser'

import { IcoArchive } from '../shared/icons/icoArchive'



interface Props {
    item: PreviewHistoryItem
    deleteItem: Callback<number>
    setPreview: Callback<Preview>
    setSelectedItemId?: Callback<number | null>
    setHistoryContent: Callback<PreviewHistoryItem[]>
    historyContent: PreviewHistoryItem[]
    archive: Callback<number>
    updateHistory: Callback
}

export const HistoryItem: React.FC<Props> = ({ item, deleteItem, setPreview, setSelectedItemId, setHistoryContent, historyContent, archive, updateHistory }) => {
  const copyLinkToClipboard = useClipboard(`https://app.piar.io/sharing/${item.externalStatHash}`)
  const history = useHistory()
  const coreApi = useCoreApi()
  const [isPreviewNameEditable, setPreviewNameEditable] = useState<boolean>(false)
  const [previewName, setPreviewName] = useState<string>(item.name)
  const [sharingToggle, setSharingToggle] = useState(item.statShared)
  // const [statHash, setStatHash] = useState<string | undefined>(item.externalStatHash ? item.externalStatHash : undefined)

  const DeleteAlert = ({ onUndo, closeToast }: { onUndo: Callback, closeToast?: Callback }) => {
    const handleClick = () => {
      onUndo()
      closeToast && closeToast()
    }

    return (
      <div className={ classes['delete-alert']}>
        Preview is deleting
        <button
          type="button"
          onClick={ handleClick }
        >UNDO</button>
      </div>
    )
  }

  const deletePreview = () => {
    mixpanel.track('delete_link_click')

    let enableToDelete = true
    const autoClose = 5000

    toast(<DeleteAlert onUndo={ () => enableToDelete = false } />, {
      autoClose,
      hideProgressBar: false,
      closeOnClick: false,
      closeButton: false,
      toastId: item.id,
      onClose: () => {
        if (enableToDelete) {
          deleteItem(item.id)
        }
      },
    })
  }

  const duplicate = () => {
    mixpanel.track('open_btn_click')
    setPreview({
      name: item.name,
      title: item.title,
      body: item.body,
      image: item.image,
      link: item.link,
      hash: item.hash,
    })
    history.push('/builder')
  }

  const showStats = () => {
    mixpanel.track('show_statistic_click')
    setSelectedItemId && setSelectedItemId(item.id)
  }

  const getCreatedDate = (date: string) => new Date(date).toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' })

  const savePreviewName = useCallback(async (value: string) => {
    try {
      await coreApi.patchPreviewName({ id: item.id, value })
      const newHistoryContent = historyContent.map((historyItem) => {
        return historyItem.id !== item.id ? historyItem : {
          ...historyItem,
          name: value,
        }
      })
      setHistoryContent(newHistoryContent)
      setPreviewNameEditable(false)
    } catch (error) {
      console.error(error)
      toast.error('Something went wrong. Try again later.', {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
      })
    }
  }, [coreApi, item, historyContent, setHistoryContent, setPreviewNameEditable])

  const archivatePreview = () => {
    archive(item.id)
  }

  const toggleSharing = () => {
    setSharingToggle((prev) => {
      coreApi
        .patchExternalStat({ id: item.id, enabled: !prev })
        .then(() => {
          updateHistory()
        })
      return !prev
    })
  }

  return(
    <div key={item.id} className={classes.root}>
      <div className={classes.preview_facebook}>
        <div className={classes.image}>
          <img alt="preview" src={item.image}/>
          {/* <div className={classes.overlay_container} onClick={duplicate}>
            <Button isActive={true} btn_class={classes.overlay_button} onClick={console.log}>Open preview</Button>
            <div className={classes.overlay}></div>
          </div> */}
        </div>
        <div className={classes.preview_caption}>
          <span>Piar.me</span>
          <p className={classes.title}>{item.title}</p>
          <p className={classes.secondary}>{item.body}</p>
        </div>
      </div>
      <div className={classes.caption}>
        <div className={classes['preview-meta']}>
          <span>
            Generated {getCreatedDate(item.createdAt)}
          </span>
          <span>
            Total clicks: {item.stat.totalClicks}
          </span>
        </div>
        <div className={ classnames({ [classes['heading-group']]: true, [classes.editable]: isPreviewNameEditable }) }>
          { !isPreviewNameEditable ?
            <>
              <Tippy
                content={ item.name }
                placement="top-start"
              >
                <h2>{ item.name }</h2>
              </Tippy>
              <button
                type="button"
                className={ buttonClasses['btn-icon-only'] }
                aria-label="Edit preview name"
                onClick={ () => setPreviewNameEditable(true) }
              ><IcoEdit /></button>
            </> :
            <>
              <input
                className={ inputStyles.input }
                style={{ padding: '8px 12px', fontSize: '14px', lineHeight: '16px' }}
                type="text"
                name="preview-name"
                placeholder="Preview name"
                maxLength={ 255 }
                id="preview-name"
                value={ previewName }
                autoFocus
                onChange={ (e) => setPreviewName(e.target.value) }
              />
              <button
                type="button"
                className={ buttonClasses['btn-icon-only'] }
                aria-label="Save changes"
                title="Save changes"
                onClick={ () => savePreviewName(previewName) }
              ><IcoSave /></button>
              <button
                type="button"
                className={ buttonClasses['btn-icon-only'] }
                aria-label="Cancel changes"
                title="Cancel changes"
                onClick={ () => {
                  setPreviewName(item.name)
                  setPreviewNameEditable(false)
                }}
              ><IcoClose /></button>
            </>
          }
        </div>
        <Tippy
          content={ item.link }
          placement="top-start"
        >
          <a
            href={ item.link }
            target="_blank"
            rel="noopener noreferrer"
            className={classes.target_link}
          >{ item.link }</a>
        </Tippy>
      </div>
      <div className={classes.actions}>
        { setSelectedItemId &&
          <button type="button" onClick={showStats} className={classes['caption-action']}>
            <IcoStats className={classes.icon}/>
            <span>Statistic</span>
          </button>
        }
        <button type="button" onClick={duplicate} className={classes['caption-action']}>
          <IcoDuplicate className={classes.icon}/>
          <span>Duplicate</span>
        </button>
        <button type="button" onClick={archivatePreview} className={classes['caption-action']}>
          <IcoArchive className={classes.icon}/>
          <span>Archive</span>
        </button>
        <button type="button" onClick={deletePreview} className={classes['caption-action']}>
          <IcoDelete className={classes.icon}/>
          <span>Delete</span>
        </button>
      </div>
      <div className={classes['sharing']}>
        <div className={classes['toggle-container']}>
          <span className={classes['toggle-caption']}>Allow access to statistic with URL</span>
          <label className={classes['toggle']}>
            <input type="checkbox" checked={sharingToggle} onChange={toggleSharing}/>
            <span className={classes['slider']}></span>
          </label>
        </div>
        {sharingToggle && item.externalStatHash ? <InputWithAction inputText={`https://app.piar.io/sharing/${item.externalStatHash}`} inputDescription="Private URL to share statistic" action={copyLinkToClipboard} buttonText="Copy"/> : null}
      </div>
    </div>
  )}
