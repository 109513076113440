import React from 'react'

import { Callback } from 'src/common'

import icoClosed from '../../../../assets/icons/ico-closed.svg'
import icoLogo from '../../../../assets/icons/logo-piario.svg'
import previewMokups from '../../../../assets/images/preview-mokups.png'
import { Modal } from '../Modal'

import classes from './BrandedModal.module.css'



type Props = {
    visible: boolean
    onCloseAttempt: Callback
    styles?: { [key: string]: string | number }
}

export const BrandedModal: React.FC<Props> = ({ children, visible, onCloseAttempt, styles = {} }) => {
  return ( 
    <Modal visible={visible} onCloseAttempt={onCloseAttempt} styles={styles}>
      <div className={ classes.wrapper }>
        <div className={ classes['wrapper-left']}>
          <button
            type="button"
            onClick={ onCloseAttempt }
            className={ classes['modal-close']}
            aria-label="Close modal"
          ><img src={ icoClosed } alt="Close modal" /></button>
          <div className={ classes.logo }>
            <img src={ icoLogo } alt="Logo" height="28"  />
          </div>
          <div className={ classes.content }>
            {children }
          </div>
        </div>

        <div className={ classes['wrapper-right']}>
          <p className={ classes.subtitle }>Design what your followers see when you share a link</p>
          <img src={ previewMokups } alt="Preview mokups" width="320" />
        </div>
      </div>
    </Modal>
  )
}
