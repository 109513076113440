
import classnames from 'classnames'
import React from 'react'

import classes from './inputs.module.css'


interface Props {
  title?: string
  placeholder?: string
  type?: 'text' | 'email' | 'password'
  input_styles?: React.CSSProperties
  style?: React.CSSProperties
  autoComplete?: string
  name:string
  register?: any
  rules?: any
  inputId: string
  errors?: any
  watch?: any
  withCounter?: boolean
  onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void
}

export const Input: React.FC<Props> = ({
  title, 
  placeholder = '', 
  type = 'text', 
  input_styles, 
  style, 
  autoComplete, 
  name, 
  register, 
  rules, 
  inputId, 
  errors, 
  watch, 
  withCounter = false,
  onClick, 
}) => {
  return (
    <div className={classes.root} style={style}>
      <div className={classes['input-title']}>
        { title && 
          <label
            htmlFor={ inputId }
            className={classes.title}
          >{title}</label> 
        }

        {withCounter 
          ? <label
            htmlFor={ inputId }
            className={classes.title}
          >{watch()[name]?.length}</label> 
          : null
        }
      </div>
      <input
        ref={register && register(rules)}
        id={ inputId }
        className={
          classnames(classes.input, errors && errors[name] && classes.error)
        }
        type={type}
        placeholder={placeholder}
        // value={value}
        // onChange={onRawChangeEvent}
        style={input_styles}
        autoComplete={autoComplete}
        name={name}
        onClick={onClick}
      />
    </div>
  )
}
