import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useAuth } from 'src/hooks/useAuth'
import { useCoreApi } from 'src/hooks/useCoreApi'

import { IcoSuccess } from '../shared/icons/icoSuccess'
import { Modal } from '../shared/modal/Modal'

import classes from './EmailConfirmationModal.module.css'


export const EmailConfirmationModal: React.FC = React.memo(
  function EmailConfirmationModal() {
    const currentURLParams = React.useCallback(
      () => new URLSearchParams(window.location.search),
      []
    )

    const { replace } = useHistory()

    const coreApi = useCoreApi()

    const { setToken } = useAuth()

    const closeEmailConfirmation = useCallback(() => {
      replace('/')
      setConfirmaitionProcess(false)
    }, [replace])

    const [confirmationProcess, setConfirmaitionProcess] = useState(
      () => window.location.pathname === '/' && currentURLParams().has('hash')
    )

    const [confirmationStatus, setConfirmationStatus] = useState<
      'loading' | 'confirmed' | 'rejected'
    >('loading')

    useEffect(() => {
      (async () => {
        if (confirmationProcess) {
          try {
            const currentHash = currentURLParams().get('hash')
            if(!currentHash){
              console.error('Confirmation hash is empty')
              return
            }
            const response = await coreApi.confirmAccount(currentHash)
            setConfirmationStatus('confirmed')
            setToken(response.bearerToken)
            localStorage.setItem('bearerToken', response.bearerToken)
          } catch (error) {
            setConfirmationStatus('rejected')
          }
          return new Promise((resolve) =>
            setTimeout(() => resolve(null), 5000)
          ).then(closeEmailConfirmation)
        }
      })()
    }, [
      closeEmailConfirmation,
      confirmationProcess,
      coreApi,
      currentURLParams,
      setConfirmationStatus,
      setToken,
    ])
    return (
      <Modal
        visible={confirmationProcess}
        onCloseAttempt={closeEmailConfirmation}
      >
        <div className={classes.root}>
          <div className={classes.content}>
            {confirmationStatus !== 'loading' ? (
              confirmationStatus === 'confirmed' ? (
                <>
                  <IcoSuccess />
                  <span className={classes.title}>Account activated!</span>
                  <span>This window will be closed soon automatically</span>
                </>
              ) : (
                <>
                  <span className={classes.title}>Activation error!</span>
                  <span>Some error was occurred</span>
                </>
              )
            ) : (
              <p>Please wait...</p>
            )}
          </div>
        </div>
      </Modal>
    )
  }
)
