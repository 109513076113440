import React from 'react'


interface Props {
    className?: string
}

export const IcoEdit:React.FC<Props> = ({ className }) => (
  <svg className={ className } xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 12.4262 12.4124"><defs/><path d="M0.895,12.4124c-0.2231,0-0.4536-0.0918-0.6279-0.2671c-0.2559-0.2568-0.3364-0.6279-0.2046-0.9463 c0.5977-1.6885,0.8911-2.5137,1.0498-2.9316L0.9898,8.1448L1.3325,7.802C1.336,7.7986,1.3399,7.7947,1.3438,7.7908l7.4121-7.4126 c0.5044-0.5039,1.3257-0.5044,1.8301-0.0005l1.4624,1.4619c0.5039,0.5054,0.5039,1.3262-0.0005,1.8306l-7.4111,7.4106 c-0.0049,0.0049-0.0093,0.0093-0.0137,0.0137l-0.3418,0.3418l-0.1245-0.124c-0.4214,0.1572-1.2573,0.4487-2.9736,1.0444 C1.0923,12.3943,0.9947,12.4124,0.895,12.4124z M0.9986,11.5496c-0.001,0.0024-0.002,0.0049-0.0029,0.0073 C0.9966,11.5544,0.9976,11.552,0.9986,11.5496z M0.8448,11.4163c-0.0049,0.0015-0.0098,0.0034-0.0146,0.0054 C0.835,11.4202,0.8399,11.4182,0.8448,11.4163z M1.8926,9.0476c-0.2275,0.6284-0.5605,1.5615-0.8188,2.2891 c0.7412-0.2578,1.6729-0.584,2.3008-0.8071L1.8926,9.0476z M2.4038,8.1448l1.8774,1.8774l7.0596-7.0591 c0.1147-0.1147,0.1147-0.3018-0.0005-0.4165L9.8794,1.0852C9.7642,0.97,9.5762,0.9705,9.4629,1.0857L2.4038,8.1448z" /></svg>
)
