import classnames from 'classnames'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { Callback } from 'src/common'
import inputStyles from 'src/components/shared/inputs/inputs.module.css'
import { useAuth } from 'src/hooks/useAuth'
import { useCoreApi } from 'src/hooks/useCoreApi'

import icoFacebook from '../../assets/icons/ico-facebook.svg'
import icoGoogle from '../../assets/icons/ico-google.svg'
import icoLinkedin from '../../assets/icons/ico-linkedin.svg'
import buttonClasses from '../shared/button/Button.module.css'
import { ErrorMessage } from '../shared/errorMessage/ErrorMessage'

import classes from './AuthForm.module.css'




interface Props {
    selectedTab: string
    setSelectedTab: Dispatch<SetStateAction<string>>
    onCloseAuthForm: Callback
}

type FormValues = {
  email: string
  password: string
}

export const AuthForm: React.FC<Props> = ({ selectedTab, setSelectedTab }) => {
  const auth = useAuth()
  const coreApi = useCoreApi()
  const [resendEmail, setResendEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const { handleSubmit, register, errors } = useForm<FormValues>()
  const history = useHistory()
  const signin:SubmitHandler<FormValues>  = ({ email, password }) => {
    setResendEmail(email)
    auth.signin({ email, password }).then(() => {
      console.log(auth.loginFromGenerateButton)
      if(auth.loginFromGenerateButton){
        auth.setLoginFromGenerateButton(false)
        return history.replace('/builder')
      }
      history.replace('/')
    }).catch((error) => {
      console.log(error)
    })
  }
  const signup:SubmitHandler<FormValues> = ({ email, password }) => {
    setResendEmail(email)
    auth.signup({ email, password })
  }

  const loginWithGoogle = (event:any) => {
    event.preventDefault()
    window.location.assign('https://api.piar.io/oauth2/authorization/google')
  }

  const loginWithFacebook = (event: any) => {
    event.preventDefault()
    window.location.assign('https://api.piar.io/oauth2/authorization/facebook')
  }

  const loginWithLinkedin = (event:any) => {
    event.preventDefault()
    window.location.assign('https://api.piar.io/oauth2/authorization/linkedin')
  }

  return (
    <div className={ classes.content }>
      { auth.showConfirmMessage || auth.error === 'Unconfirmed user!' ? (
        <>
          <h2 className={ classes.title }><small>Check your email to confirm registration</small></h2>
          <p className={ classes.subtitle }>If you still don&apos;t see it, you can resend the confirmation letter</p>
          <div className={ classes.form }>
            <div className={ classes['form-group'] }>
              <label htmlFor="resend-email" className={ inputStyles.title }>Email</label>
              <input
                className={ inputStyles.input }
                type="email"
                placeholder="Email"
                name="email"
                id="resend-email"
                autoComplete="email"
                value={ resendEmail }
                onChange={ (e) => setResendEmail(e.target.value) }
              />
            </div>
            <div className={ classes['form-group'] }>
              <button
                type="button"
                className={ classnames(buttonClasses.root, classes['btn-accent-1'], classes.Button) }
                disabled={ loading }
                onClick={
                  () => {
                    setLoading(true)
                    coreApi.resendConfirmation(resendEmail)
                      .then(() => setLoading(false))
                      .catch(() => setLoading(false))
                  }
                }
              >
                Resend
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <h2 className={ classes.title }>
            { selectedTab === 'login' ? 'Welcome to Piar.io!' : 'Create an Account' }
          </h2>
          <p className={ classes.subtitle }>
            Make sure in preview of your link
          </p>
          <form
            className={ classes.form }
            onSubmit={ handleSubmit(selectedTab === 'login' ? signin : signup) }
          >
            <div className={ classes['form-group'] }>
              <label htmlFor="auth-email" className={ inputStyles.title }>Email</label>
              <input
                className={ classnames(inputStyles.input, errors.email && inputStyles.error) }
                type="email"
                placeholder="Input Email"
                name="email"
                id="auth-email"
                autoComplete="username"
                ref={ register({
                  required: 'Required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  } }
                )}
              />
              { errors.email ? <ErrorMessage>{ errors.email.message }</ErrorMessage> : null }
            </div>

            <div className={ classes['form-group'] }>
              <label htmlFor="auth-pass" className={ inputStyles.title }>Password</label>
              <input
                onChange={() => auth.setError('')}
                className={ classnames(inputStyles.input, errors.password && inputStyles.error) }
                type="password"
                placeholder="Input Password"
                autoComplete="current-password"
                name="password"
                id="auth-pass"
                ref={register({
                  required: 'Required',
                  // pattern: {
                  //   value: /^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$).{6,50}$/g,
                  //   message: 'Password should contain at least 1 digit and 1 Latin letter, and the length must be between 6 and 50',
                  // }
                }
                )}
              />
              {/* { errors.password && <span className={ classes.error_message }>{ errors.password.message }</span> } */}
              <div>
                { auth.error ? <ErrorMessage>{ auth.error }</ErrorMessage> : null }
              </div>
            </div>

            <div className={ classes['form-notice'] }>
              { selectedTab === 'login' ?
                // <span>Forgot password? TODO</span> :
                null :
                <span className={ classes.notice }>By signing up, you accept our <a href="https://piar.io/terms" target="_blank" rel="noreferrer noopener">Terms&nbsp;of&nbsp;Use</a> and <a href="https://piar.io/privacy-policy" target="_blank" rel="noreferrer noopener">Privacy&nbsp;Policy</a></span>
              }
            </div>

            <div className={ classnames(classes['form-group'], classes['button-wrapper']) }>
              <button
                className={ classnames(classes['btn-accent-1'], buttonClasses.root, classes.Button) }
                autoFocus
                type='submit'
              >
                { selectedTab === 'login' ? 'Login' : 'Sign Up' }
              </button>

              <span>or</span>
              <div className={classes['socials-wrapper']}>
                <button
                  className={ classnames(classes['btn-accent-2'], buttonClasses.root, classes.Button) }
                  onClick={loginWithGoogle}
                >
                  <img src={icoGoogle} alt="google"/>
                </button>

                <button
                  className={ classnames(classes['btn-accent-2'], buttonClasses.root,  classes.Button) }
                  onClick={loginWithFacebook}
                >
                  <img src={icoFacebook} alt="facebook"/>
                </button>

                <button
                  className={ classnames(classes['btn-accent-2'], buttonClasses.root,  classes.Button) }
                  onClick={loginWithLinkedin}
                >
                  <img src={icoLinkedin} alt="linkedin"/>
                </button>
              </div>
            </div>
            <div className={ classes['tab-switcher']}>
              <span>{ selectedTab === 'login' ? 'No account?' : 'Have an account?' }&nbsp;</span>
              <button
                type="button"
                onClick={ () => {
                  setSelectedTab(selectedTab === 'login' ? 'signup' : 'login')
                  auth.setShowConfirmMessage(false)
                  auth.setError('')
                }}
              >{ selectedTab === 'login' ? 'Register' : 'Login' }</button>
            </div>
          </form>
        </>
      )}
    </div>
  )
}
