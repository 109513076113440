import classnames from 'classnames'
import React, { FC } from 'react'

import { Callback } from 'src/common'
import { useAuth } from 'src/hooks/useAuth'
import { Channel } from 'src/models/Channels'

import classes from '../Channels/Channels.module.css'

import { ChannelIcon } from './ChannelIcon/ChannelIcon'


interface Props {
  allChannels: Channel[]
  // activeChannels: Channel[]
  onClick: Callback<Channel>
}

export const Channels: FC<Props> = ({ allChannels, onClick }) => {
  const auth = useAuth()
  return (
    <div className={classnames(classes.preview__types, !auth.token && classes.unauthorized)}>
      {allChannels.map((channel, key) => (
        <ChannelIcon
          key={key}
          channel={channel}
          // active={activeChannels.includes(channel)}
          onClick={() => onClick(channel)}
        />
      ))}
    </div>
  )}
