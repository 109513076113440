import classnames from 'classnames'
import React, { FC } from 'react'

import { Channel } from 'src/models/Channels'

import classes from '../PreviewList/PreviewList.module.css'

import { FacebookPreview } from './FacebookPreview/FacebookPreview'
import { InstagramPreview } from './InstagramPreview/InstagramPreview'
import { LinkedinPreview } from './LinkedinPreview/LinkedinPreview'
import { PinterestPreview } from './PinterestPreview/PinterestPreview'
import { SlackPreview } from './SlackPreview/SlackPreview'
import { TelegramPreview } from './TelegramPreview/TelegramPreview'
import { TwitterPreview } from './TwitterPreview/TwitterPreview'
import { WhatsappPreview } from './WhatsappPreview/WhatsappPreview'



const nameForChannel: Record<Channel, string> = {
  [Channel.facebook]: 'Facebook',
  [Channel.telegram]: 'Telegram',
  [Channel.slack]: 'Slack',
  [Channel.twitter]: 'Twitter',
  [Channel.viber]: 'Viber',
  [Channel.pinterest]: 'Pinterest',
  [Channel.linkedin]: 'Linkedin',
  [Channel.instagram]: 'Instagram',
  [Channel.whatsapp]: 'Whatsapp',
}

export interface PreviewProps {
  title: string
  body: string
  image: string
  link: string
}

const Preview: FC<{channel: Channel} & PreviewProps> = ({ channel, ...previewProps }) => {
  switch (channel) {
    case Channel.telegram:
      return <TelegramPreview {...previewProps} />
    case Channel.facebook:
      return <FacebookPreview {...previewProps}/>
    case Channel.twitter:
      return <TwitterPreview {...previewProps}/>
    case Channel.slack:
      return <SlackPreview {...previewProps}/>
    case Channel.pinterest:
      return <PinterestPreview {...previewProps}/>
    case Channel.linkedin:
      return <LinkedinPreview {...previewProps}/>
    case Channel.whatsapp:
      return <WhatsappPreview {...previewProps}/>
    case Channel.instagram:
      return <InstagramPreview {...previewProps}/>
    default:
      return <FacebookPreview {...previewProps}/>
  }
}

export const PreviewList: FC<{channels: Channel[], refsList: any[], selectedChannel: number | undefined} & PreviewProps> = ({ channels, refsList, selectedChannel, ...previewProps }) => {
  return <>
    {channels.map(channel => (
      <div key={channel} ref={refsList[channel]} className={classnames(classes.preview__card, selectedChannel === channel && classes.selected)}>
        <h4 className={classes.preview__title}>
          <span>{nameForChannel[channel]}</span>
          <div className={classes.preview__line}></div>
        </h4>
        <Preview channel={channel} {...previewProps} />
      </div>
    ))}
  </>
}
