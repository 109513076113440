import classnames from 'classnames'
import React, { useCallback, useRef, useLayoutEffect } from 'react'

import { Callback } from 'src/common'

import classes from './inputs.module.css'


interface Props {
  title: string
  value?: string
  placeholder?: string
  onChange?: Callback<string>
  name: string
  register?: any
  rules?: any
  errors?: any
  watch?: any
  withCounter?: boolean
  onClick?: (event: React.MouseEvent<HTMLTextAreaElement, MouseEvent>) => void
}

export const TextArea: React.FC<Props> = ({
  title,
  value,
  onChange,
  placeholder = '',
  name,
  register,
  rules,
  errors,
  watch,
  withCounter = false,
  onClick,
}) => {
  const cursorStart = useRef<number | null>(null)
  const cursorEnd = useRef<number | null>(null)
  const inputRef = useRef<HTMLTextAreaElement | null>(null)

  useLayoutEffect(() => {
    if (cursorStart.current && cursorEnd.current && inputRef.current) {
      inputRef.current.setSelectionRange(cursorStart.current, cursorEnd.current)
    }
  }, [value])

  const onRawChangeEvent = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      cursorStart.current = event.target.selectionStart
      cursorEnd.current = event.target.selectionEnd
      onChange && onChange(event.target.value)
    },
    [onChange]
  )

  return (
    <div className={classes.root}>
      <div className={classes['input-title']}>
        <label className={classes.title}>{title}</label>
        {withCounter ? (
          <label className={classes.title}>{watch()[name]?.length}</label>
        ) : null}
      </div>
      <textarea
        ref={register && register(rules)}
        className={classnames(classes.textarea, errors[name] && classes.error)}
        cols={30}
        rows={10}
        placeholder={placeholder}
        value={value}
        onChange={onRawChangeEvent}
        name={name}
        onClick={onClick}
      ></textarea>
    </div>
  )
}
