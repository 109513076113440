import { Pagination } from '@material-ui/lab'
import classnames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { 
  // Member,
  MembersResponse,
  UserData,
} from 'src/apis/core'
import { Callback } from 'src/common'
import { CurrentPlan } from 'src/components/CurrentPlan/CurrentPlan'
import { DomainsSettings } from 'src/components/DomainSettings/DomainsSettings'
import { InputWithAction } from 'src/components/InputWithAction/InputWithAction'
import buttonStyles from 'src/components/shared/button/Button.module.css'
import { ErrorMessage } from 'src/components/shared/errorMessage/ErrorMessage'
// import { Input } from 'src/components/shared/inputs/Input'
import inputStyles from 'src/components/shared/inputs/inputs.module.css'
import { Modal } from 'src/components/shared/modal/Modal'
import { useClipboard } from 'src/hooks/useClipboard'
import { useCoreApi } from 'src/hooks/useCoreApi'
import { Domain } from 'src/models/Domain'
import { TARIFF_LIST_FEATURES } from 'src/models/TariffsListFeatures'

import icoClosed from '../../assets/icons/ico-closed.svg'
import icoDanger from '../../assets/icons/ico-danger.svg'
// import icoDomain from '../../assets/icons/ico-domain.svg'
import icoRestAPI from '../../assets/icons/ico-restapi.svg'
import icoTeam from '../../assets/icons/ico-team.svg'


import classes from './AccountSettings.module.css'


interface Props {
  user: UserData | null
  setUser: Callback<UserData | null>
}

type FormValues = {
  email: string
}

export const AccountSettings: React.FC<Props> = ({ user, setUser }) => {
  const coreApi = useCoreApi()
  const history = useHistory()
  const { register } = useForm()

  const [pricingList, setPricingList] = useState<{
    [key: string]: {
      name: 'basic' | 'standard' | 'enterprise'
      features: string[]
      monthlyPrice: number
      yearlyPrice: number
      comingSoon: boolean
    }
  }>({})

  const [domainsList, setDomainsList] = useState<Domain[]>([])
  const [RESTapiApps, setRESTApiApps] = useState<{apiKey: string} | null>(null)
  const copyToken = useClipboard(RESTapiApps ? RESTapiApps.apiKey : '')
  const [inviteModalVisible, setInviteModalVisible] = useState<boolean>(false)

  const { handleSubmit, register: registerEmail, errors, setError } = useForm<FormValues>()
  const [isInviting, setIsInviting] = useState<boolean>(false)
  const [members, setMembers] = useState<MembersResponse>()
  const [membersPage, setMembersPage] = useState<number>(0)

  
  const invite = ({ email }: any) => {
    setIsInviting(true)
    coreApi.inviteMember({ email }).then(() => {
      setInviteModalVisible(false)
      toast.success('Invite was sent', {
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      }) 
      coreApi.getMembers({ page: membersPage }).then(setMembers)
    })
      .catch(error => {
        error.error ? setError('email', { message: error.error }) : setError('email', { message: 'Something went wrong' })
      }).finally(() => setIsInviting(false))
  }

  const reinvite = (id: number) => {
    coreApi.reInviteMember({ id }).then(() => {
      toast.success('Invite was sent', {
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      })
    }).catch(() => {
      toast.error('Something went wrong. Try again later.', {
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      })
    })
  } 

  const deactivate = (id: number) => {
    coreApi.deactivateMember({ id }).then(() => {
      toast.success('User was deactivated', {
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      })
      coreApi.getMembers({ page: membersPage }).then(setMembers)
    }).catch(() => {
      toast.error('Something went wrong. Try again later.', {
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      })
    })
  } 

  const restore = (id: number) => {
    coreApi.restoreMember({ id }).then(() => {
      toast.success('User was restored', {
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      })
      coreApi.getMembers({ page: membersPage }).then(setMembers)
    }).catch(() => {
      toast.error('Something went wrong. Try again later.', {
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      })
    })
  }

  const createNewDomain = (domain: string) => {
    coreApi.createNewDomain(domain)
      .then((domain) => {
        setDomainsList([...domainsList, domain])
        toast.success('Domain was added', {
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
        })
      })
      .catch((err) => {
        console.log('[createNewDomain]', err)
        toast.error( err.description || 'Something went wrong. Please try again', {
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
        })
      })
  }

  const deleteDomain = (id: number) => {
    coreApi.deleteDomain(id)
      .then(() => {
        setDomainsList(domainsList.filter((i) => i.id !== id))
      })
      .catch((err) => {
        console.log('[deleteDomain]', err)
        toast.error( err.description || 'Something went wrong. Please try again', {
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
        })
      })
  }

  const refreshDomain = (id: number): Promise<Domain> => {
    return new Promise ((resolve, reject) => {
      coreApi.refreshDomain(id)
        .then((domain) => {
          setDomainsList(domainsList.map((i) => i.id === domain.id ? domain : i))
          resolve(domain)
        })
        .catch((err) => {
          console.log('[deleteDomain]', err)
          toast.error( err.description || 'Something went wrong. Please try again', {
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
          })
          reject(err)
        })
    })
  }

  useEffect(() => {
    if(user?.isParent){
      coreApi.getMembers({ page: membersPage }).then(setMembers)
    }
  }, [coreApi, user, membersPage])

  useEffect(() => {
    (async () => {
      const plans = await coreApi.getBillingPlans()
      let list: {
        [key: string]: {
          name: 'basic' | 'standard' | 'enterprise'
          features: string[]
          monthlyPrice: number
          yearlyPrice: number
          comingSoon: boolean
        }
      } = { }
      plans.forEach(({ name, monthlyPrice, yearlyPrice }) => {
        list = {
          ...list,
          [name]: {
            ...TARIFF_LIST_FEATURES[name], name, monthlyPrice, yearlyPrice,
          },
        }
      })
      setPricingList(list)
    })()
  }, [coreApi])

  useEffect(() => {
    (async () => {
      const domains = await coreApi.getDomainsList()
      setDomainsList(domains)
      const apps = await coreApi.getRESTAPIApps()
      setRESTApiApps(apps)
    })()
  }, [coreApi])

  const setDefaultDomain = async (id: number) => {
    await coreApi.setDefaultDomain(id)

    const newDomainList = domainsList.map((el) => {
      if (el.default) {
        return {
          ...el,
          default: false,
        }
      }
      if (el.id === id) {
        return {
          ...el,
          default: true,
        }
      }
      return el
    })
    setDomainsList(newDomainList)
  }

  const startTrial = async () => {
    const userData = await coreApi.startTrial('basic')
    setUser(userData)
  }

  const paymentHandler = () => history.push('/billing')

  const ressetPassword = useCallback(async () => {
    try {
      await coreApi.resetPassword(user?.email || '')
      toast.info('Confirmation link was sent to your email', {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
      })
    } catch {
      toast.error('Something went wrong. Try again later.', {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
      })
    }
  }, [coreApi, user])

  const deleteAccount = useCallback(async () => {
    try {
      await coreApi.deleteAccount(user?.email || '')
      toast.success('Request was sent. Your account will be deleted soon.', {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
      })
    } catch {
      toast.error('Something went wrong. Try again later.', {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
      })
    }
  }, [coreApi, user])

  const createRESTAPIApp = (async () => {
    try {
      const app = await coreApi.createRESTAPIApp()
      setRESTApiApps(app)
      toast.success('API is ready to connect.', {
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      }) 
    } catch (error) {
      console.error(error)
      toast.error('Something went wrong. Try again later.', {
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      })
    }
  })

  return (
    <>
      <article className={ classes.settings }>
        <h2 className={ classes.title }>Account Settings</h2>
        <p className={ classes.subtitle }>Customize your preview link</p>
        <div className={ classes.content }>
          <section>
            <h3 className={ classes['section-title']}>Your plan</h3>
            <CurrentPlan
              subscription={ user?.subscription }
              planDetails={ (user?.subscription && pricingList[user.subscription.name]) || pricingList['basic'] }
              startTrial={ startTrial }
              paymentHandler={  paymentHandler }
              frequency={ 'monthly' }
            />
          </section>

          <section>
            <h3 className={ classes['section-title'] }>Account details</h3>
            <div className={ classnames(classes['section-card'], classes['settings-details']) }>
              <div className={ classes['form-group'] }>
                <label htmlFor="email" className={ inputStyles.title }>Email</label>
                <input
                  className={ classnames(inputStyles.input) }
                  type="email"
                  placeholder="Input Email"
                  name="email"
                  id="auth-email"
                  autoComplete="username"
                  defaultValue={ user?.email || '' }
                  disabled
                  ref={ register({
                    required: 'Required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address',
                    },
                  })}
                />
              </div>
              <div className={ classes['form-group'] }>
                <button
                  className={ classnames(buttonStyles['btn-link'], buttonStyles['btn-accent-1'], classes['resset-pass']) }
                  type="button"
                  style={{ paddingTop: '16px', paddingBottom: '16px' }}
                  onClick={ ressetPassword }
                >Reset password</button>
              </div>
            </div>
          </section>

          <DomainsSettings
            setDefaultDomain={setDefaultDomain} 
            domainsList={domainsList} 
            createNewDomain={createNewDomain}
            deleteDomain={deleteDomain}
            refreshDomain={refreshDomain}
          />

          <section>
            <h3 className={ classes['section-title'] }>REST API</h3>
            <div className={ classnames(classes['section-card'], classes['settings-domains'])}>
              <img className={ classes.icon } src={ icoRestAPI } alt="Rest API icon" width="63" />
              <div className={ classes.details }>
                <h4 className={ classes.subtitle }>Create previews directly from your server</h4>
                <p className={ classes.text }>Piario&apos;s server API provides possibility to create previews by direct requests from your server.</p>
              </div>
              <div className={ classes.actions }>
                {!RESTapiApps 
                  ? <button
                    className={ classnames(buttonStyles['root'], buttonStyles['btn-accent-1']) }
                    type="button"
                    onClick={createRESTAPIApp}
                  >Connect REST API</button> 
                  : 
                  <div className={ classes['copy-token-input'] }>
                    <InputWithAction 
                      btn_styles={{ backgroundColor:'#40466e' }} 
                      inputText={RESTapiApps.apiKey} 
                      inputDescription="REST API Token" 
                      action={copyToken} 
                      buttonText="Copy"/>
                  </div>
                }
              </div>
            </div>
          </section>

          {user?.isParent ? <section>
            <h3 className={ classes['section-title'] }>TEAM</h3>
            <div className={ classnames(classes['section-card'], classes['settings-team'])}>
              <img className={ classes.icon } src={ icoTeam } alt="Team icon" width="63" />
              <div className={classes['team-header']}>
                <p className={classes.text}>Create your own team, add new users, who will be an author. Author can’t invite new members.</p>
                <button
                  className={ classnames(buttonStyles['root'], buttonStyles['btn-accent-1']) }
                  type="button"
                  onClick={ () => setInviteModalVisible(true) }
                  style={{ height: 'fit-content' }}
                >Invite</button>
              </div>
              <div className={classes['team-table']}>
                {members?.content?.length ? <div className={classes['table-header']}>
                  <span>Email</span>
                  <span>Status</span>
                  <span>Actions</span>
                </div> : null}
                {members?.content?.map(member => (
                  <div key={member.id} className={classes['table-row']}>
                    <span className={classes.email}>{member.email}</span>
                    <span className={classnames(classes.status, classes[member.status.toLowerCase()])}>{member.status}</span>
                    <div className={classes.action}>{(member.status === 'NEW') ? <span onClick={() => reinvite(member.id)}>Re-invite</span> : null}{member.status !== 'DEACTIVATED' ? <span onClick={() => deactivate(member.id)}>Dectivate</span> : <span onClick={() => restore(member.id)}>Restore</span>}</div>
                  </div>
                ))}
              </div>
              {members && (members.totalPages > 1 && (<Pagination className={classes.pagination} count={members.totalPages} page={membersPage + 1} onChange={(_, value: React.SetStateAction<number>) => {
                setMembersPage(Number(value) - 1)
              }} />)) }
            </div>
          </section> : null}

          <section>
            <h3 className={ classes['section-title']}>Danger Zone</h3>
            <div className={ classnames(classes['section-card'], classes['settings-delete']) }>
              <img className={ classes.icon } src={ icoDanger } alt="Danger icon" width="63" />
              <div className={ classes.details }>
                <h4 className={ classes.subtitle}> Once you delete your account, you lost all data, it cannot be undone.</h4>
                <p className={ classes.text }>Need a help or have some questions or feedback?</p>
                <p className={ classes.text }>Please <a href="mailto:support@piar.io">contact our support</a>, our team would like to hear from you.</p>
              </div>
              <div className={ classes.actions }>
                <button
                  className={ classnames(buttonStyles['btn-link'], buttonStyles['btn-accent-2']) }
                  type="button"
                  style={{ paddingTop: '16px', paddingBottom: '16px' }}
                  onClick={ deleteAccount }
                >Delete account</button>
              </div>
            </div>
          </section>
        </div>
      </article>
      <Modal visible={inviteModalVisible} onCloseAttempt={() => setInviteModalVisible(false)}>
        <div className={classes['invite-modal']}>
          <button
            type='button'
            onClick={() => setInviteModalVisible(false)}
            className={classes['modal-close']}
            aria-label='Close modal'
          >
            <img src={icoClosed} alt='Close modal' />
          </button>
          <form className={classes['invite-modal-content']}
            onSubmit={ handleSubmit(invite) }
          >
            <h3>Invite new member</h3>
            <div className={ classes['form-group'] }>
              <label htmlFor="member-email" className={ inputStyles.title }>Email</label>
              <input
                className={ classnames(inputStyles.input) }
                type="email"
                placeholder="Input Email"
                name="email"
                id="member-email"
                autoComplete="username"
                ref={ registerEmail({
                  required: 'Required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                })}
              />
              {errors.email ? <ErrorMessage>{errors.email.message}</ErrorMessage> : null}
            </div>
            <div className={classes['modal-button']}>
              {isInviting ? <span>Loading...</span> : null}
              <button
                className={ classnames(buttonStyles['root'], buttonStyles['btn-accent-1']) }
                type="submit"
                // onClick={ () => setInviteModalVisible(false) }
              >Invite</button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  )
}
