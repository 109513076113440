import mixpanel from 'mixpanel-browser'
import React from 'react'

import { ErrorMessage } from 'src/components/shared/errorMessage/ErrorMessage'
import { Input } from 'src/components/shared/inputs/Input'


interface Props {
    methods: any
}

export const Title: React.FC<Props> = ({ methods }) => {
  const trackEditTitle = () => {
    mixpanel.track('edit_title_click')
  }
  return (
    <>
      <Input
        {...methods}
        withCounter
        inputId="preview-title"
        rules={{ maxLength: 255 }}
        name="title"
        title="Title"
        placeholder="Input Title"
        onClick={trackEditTitle}
      />
      {methods.errors.title ? 
        <ErrorMessage>
        Your input exceed maxLength {methods.watch().title.length}/255
        </ErrorMessage> : null}
    </>
  )
}
