import classnames from 'classnames'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import appsumoImg from 'src/assets/images/as-appsumo-logo.png'
import authFormStyles from 'src/components/AuthForm/AuthForm.module.css'
import buttonClasses from 'src/components/shared/button/Button.module.css'
import { IcoLogo } from 'src/components/shared/icons/icoLogo'
import inputStyles from 'src/components/shared/inputs/inputs.module.css'
import { useAuth } from 'src/hooks/useAuth'
import { useCoreApi } from 'src/hooks/useCoreApi'

import { ErrorMessage } from '../shared/errorMessage/ErrorMessage'


type FormValues = {
  email: string
  pass: string
  code: string
}

export const AppSumoRedemption = () => {
  const { handleSubmit, register, errors, getValues } = useForm<FormValues>()
  const coreApi = useCoreApi()
  const auth = useAuth()
  const history = useHistory()
  const [responseError, setResponseError] = React.useState<string | null>(null)
  const [codes, setCodes] = React.useState<string[]>([])
  const [codesError, setCodesError] = React.useState<string | null>(null)

  const submit: SubmitHandler<FormValues> = ({ email, pass }) => {
    if(!codes.length){
      setCodesError('You have to add at least one code')
      return
    }
    coreApi.signUpWithAppSumoCodes({ email, pass, codes }).then(
      (signInData) => {
        auth.setToken(signInData.bearerToken)
        localStorage.setItem('bearerToken', signInData.bearerToken)
        setResponseError(null)
        history.push('/builder')
      },
      ({ error, description }) =>
        setResponseError(description ? description : error)
    )
  }

  return (
    <div
      style={{
        backgroundColor: '#40466E',
        minHeight: '100vh',
      }}
    >
      <div
        style={{
          padding: '24px',
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <div>
          <IcoLogo />
        </div>
        <div>
          <span
            style={{
              color: '#fff',
              fontSize: '28px',
              lineHeight: '36px',
              verticalAlign: 'super',
            }}
          >
            {' '}
          & 
            {' '}
          </span>
        </div>
        <div>
          <img style={{
            height: '24px',
          }} src={appsumoImg} alt="appsumo"/>
        </div>
      </div>
      <div
        style={{
          display: 'grid',
          justifyContent: 'center',
          marginBottom: '48px',
        }}
      >
        <div
          style={{
            maxWidth: '500px',
          }}
          className={authFormStyles['wrapper-left']}
        >
          <div className={authFormStyles.content}>
            <h2 className={authFormStyles.title}>
              Create an Account
            </h2>
            <p className={authFormStyles.subtitle}>
              Enter your AppSumo code below to apply a discount
            </p>
            <form
              onSubmit={handleSubmit(submit)}
              className={authFormStyles.form}
            >
              <div className={authFormStyles['form-group']}>
                <label htmlFor='email' className={inputStyles.title}>
                  Email
                </label>
                <input
                  className={inputStyles.input}
                  type='email'
                  placeholder='Email'
                  name='email'
                  id='email'
                  autoComplete='email'
                  ref={register({
                    required: 'Required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address',
                    },
                  })}
                />
                {errors.email ? <ErrorMessage>{errors.email.message}</ErrorMessage> : null}
              </div>
              <div className={authFormStyles['form-group']}>
                <label htmlFor='pass' className={inputStyles.title}>
                  Password
                </label>
                <input
                  className={inputStyles.input}
                  type='password'
                  placeholder='Password'
                  autoComplete='current-password'
                  name='pass'
                  id='pass'
                  ref={register({
                    required: 'Required',
                  })}
                />
                {errors.pass ? <ErrorMessage>{errors.pass.message}</ErrorMessage> : null}
              </div>
              <div className={authFormStyles['form-group']}>
                <label htmlFor='appsumo_code' className={inputStyles.title}>
                    AppSumo Code
                </label>
                <div style={{
                  display: 'flex',
                }}>
                  <input
                    className={inputStyles.input}
                    type='text'
                    placeholder='AppSumo Code'
                    name='code'
                    id='appsumo_code'
                    ref={register()}
                  />
                  <button style={{
                    backgroundColor: '#40466e',
                    border: 'none',
                    color: '#fff',
                    padding: '0 12px',
                    cursor: 'pointer',
                    borderRadius: '0 2px 2px 0', 
                  }} type='button' onClick={() => {
                    setCodes(current => !current.includes(getValues().code) && getValues().code ? [...current, getValues().code] : current) 
                    setCodesError(null)
                  }}>Add</button>
                </div>
                <span style={{
                  fontWeight: 'normal',
                  marginTop: '16px',
                  display: 'block',
                }}>Your codes:</span>
                <ul style={{
                  margin: 0,
                  listStyle: 'none',
                  padding: 0,
                }}>
                  {codes?.map(code => <li key={code}>{code}</li>)}
                </ul>
                {codesError ? <ErrorMessage>{codesError}</ErrorMessage> : null}
              </div>
              
              {responseError ? <ErrorMessage>{responseError}</ErrorMessage> : null}
              <div
                style={{ justifyContent: 'space-between' }}
                className={classnames(
                  authFormStyles['form-group'],
                  authFormStyles['button-wrapper']
                )}
              >
                <button
                  className={classnames(
                    buttonClasses.root,
                    authFormStyles['btn-accent-1'],
                    authFormStyles.Button
                  )}
                  autoFocus
                  type='submit'
                >
                  Sign Up
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
