import classnames from 'classnames'
import React from 'react'

import buttonClasses from '../shared/button/Button.module.css'

import classes from './RequiredDomain.module.css'


const guideLink = 'https://piar.io/blog'

interface Props {
    onSaveTemplate: () => void
}

export const RequiredDomain: React.FC<Props> = ({ onSaveTemplate }: Props) => {
  const openSettings = () => {
    // eslint-disable-next-line no-restricted-globals
    const newWindow = window.open('/profile', '_blank', 'noopener noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return ( 
    <div className={ classes.content }>
      <h2 className={ classes.title }><small>Connected domain required</small></h2>
      <div>
        <h4 className={ classes.subtitle }>Friendly notice!</h4>
        <p>
            You can work fully with piar.io. 
            You can change the texts and images, and view the results in preview mode.
        </p>
        <p>
            But to create a link, you need to connect a domain. 
            This is a very simple process that can be done at any time.
        </p>
        <p>
            Step-by-step instructions are available <a href={guideLink} target='_blank' rel='noreferrer' >here</a>
        </p>
      </div>
        
      <div className={ classes['actions-wrapper'] }>
        <button
          type='button'
          onClick={ openSettings }
          className={ classnames( buttonClasses.root, buttonClasses['btn-accent-1'] ) }
        >Add domain</button>

        <button onClick={onSaveTemplate} className={ classes['save-template-btn'] }>Save preview as template without link</button>
      </div>
    </div>
  )
}
