import React from 'react'


interface Props {
    className?: string
}

export const IcoArchive:React.FC<Props> = ({ className }) => (
  <svg className={ className } version="1.1" id="Capa_1" x="0px" y="0px"
    viewBox="0 0 56 56">
    <g>
      <path d="M45.713,3H10.287L0,32.833v0.398v16.143C0,51.374,1.626,53,3.625,53h48.749C54.374,53,56,51.374,56,49.375V33.231V32.83
            L45.713,3z M11.713,5h32.574l9.311,27H40.486h0c-0.857,0-1.614,0.436-2.061,1.097C38.157,33.494,38,33.972,38,34.486v4.028
            C38,38.782,37.782,39,37.514,39H18.486C18.218,39,18,38.782,18,38.514v-4.028c0-0.514-0.157-0.992-0.425-1.389
            C17.128,32.436,16.371,32,15.514,32h0H2.403L11.713,5z M54,49.375C54,50.271,53.271,51,52.375,51H3.625C2.729,51,2,50.271,2,49.375
            V34h13.514C15.782,34,16,34.218,16,34.486v4.028c0,0.514,0.157,0.992,0.425,1.389C16.872,40.564,17.629,41,18.486,41h0h19.027h0
            c0.857,0,1.613-0.436,2.061-1.097C39.843,39.506,40,39.028,40,38.514v-4.028C40,34.218,40.218,34,40.486,34H54V49.375z"/>
      <polygon points="46,30 48,30 48,24 8,24 8,30 10,30 10,26 46,26 	"/>
      <polygon points="13,18 43,18 43,22 45,22 45,16 11,16 11,22 13,22 	"/>
      <polygon points="16,10 40,10 40,14 42,14 42,8 14,8 14,14 16,14 	"/>
    </g>
  </svg>
)
