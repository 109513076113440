import React, { useState, useContext, createContext, useCallback } from 'react'

import { UserData } from 'src/apis/core'
import { Callback } from 'src/common'
import { registerUserInGAByID } from 'src/google-analytics'

import { useCoreApi } from './useCoreApi'


const useProvideAuth = () => {
  const [token, setToken] = useState(localStorage.getItem('bearerToken'))
  const [showAuthForm, setShowAuthForm] = useState(false)
  const [showConfirmMessage, setShowConfirmMessage] = useState(false)
  const [error, setError] = useState('')
  const [loginFromGenerateButton, setLoginFromGenerateButton] = useState(false)
  const coreApi = useCoreApi()
  
  const signin = async (payload:{email: string, password:string}) => {
    try {
      const signin = await coreApi.signin({ email: payload.email, pass: payload.password })
      setToken(signin.bearerToken)
      localStorage.setItem('bearerToken', signin.bearerToken)
      setShowConfirmMessage(false)
      setShowAuthForm(false)
      registerUserInGAByID(signin.id)    
      return Promise.resolve()   
    } catch (error) {
      if(error.error){
        setError(error.error)
      } else if(error.causes) {
        setError(error.causes[0])
      }
      return Promise.reject(error)
    }
  }

  const signup = async (payload:{email: string, password:string}) => {
    try {
      await coreApi.signup({ email: payload.email, pass: payload.password })
      setShowConfirmMessage(true)
      return Promise.resolve()
    } catch (error) {
      if(error.error){
        setError(error.error)
      } else if(error.causes) {
        setError(error.causes[0])
      }
      return Promise.reject(error)
    }
  }

  const signout = useCallback(() => {
    console.log('signing out...')
    setToken(null)
    localStorage.removeItem('bearerToken')
  }, [])

  const authoriseWithProvider = useCallback(async (payload: { provider: string, token: string }) => {
    try {
      const signin = await coreApi.authoriseWithProvider(payload)
      setToken(signin.bearerToken)
      localStorage.setItem('bearerToken', signin.bearerToken)
      registerUserInGAByID(signin.id)
      const currentUser = await coreApi.getUserData(signin.bearerToken)
      if(currentUser.status === 'ACTIVE' || currentUser.status === 'NEW'){
        const updatedUser = await coreApi.startTrial('basic')
        return Promise.resolve(updatedUser)
      }
      return Promise.resolve(currentUser)   
    } catch (error) {
      if(error.error){
        setError(error.error)
      } else if(error.causes) {
        setError(error.causes[0])
      } else {
        console.log(error)
      }
      return Promise.reject(error)
    }
  }, [coreApi])

  return {
    token,
    error, 
    setToken,
    setError,
    showAuthForm,
    setShowAuthForm,
    signin,
    authoriseWithProvider,
    signup,
    signout,
    showConfirmMessage,
    setShowConfirmMessage,
    loginFromGenerateButton,
    setLoginFromGenerateButton,
  }
}

const authContext = createContext({} as {
    token: string | null
    error: string | null
    setError: React.Dispatch<React.SetStateAction<string>>
    setToken: React.Dispatch<React.SetStateAction<string | null>>
    showAuthForm: boolean
    setShowAuthForm: React.Dispatch<React.SetStateAction<boolean>>
    setShowConfirmMessage: React.Dispatch<React.SetStateAction<boolean>>
    signin: (payload: {email: string, password:string}) => Promise<void>
    authoriseWithProvider: (payload: { provider: string, token: string }) => Promise<UserData>
    signup: (payload: {email: string, password:string}) => Promise<void>
    signout: Callback
    showConfirmMessage: boolean
    loginFromGenerateButton: boolean
    setLoginFromGenerateButton: React.Dispatch<React.SetStateAction<boolean>>
})

export const ProvideAuth: React.FC = ({ children }) => {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => useContext(authContext)
