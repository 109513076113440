  
import React from 'react'

import { Callback } from 'src/common'

import classes from './Backdrop.module.css'


interface Props {
    visible: boolean
    onClick: Callback
  }

export const Backdrop: React.FC<Props> = ({ visible, onClick }) => visible ? <div onClick={onClick} className={classes.Backdrop}></div> : null
