import classnames from 'classnames'
import React from 'react'

import { Callback } from 'src/common'

import classes from './PricingPlan.module.css'


interface Props {
  planDetails?: {
    name: 'basic' | 'standard' | 'enterprise' | 'app_summo_1' | 'app_summo_2' | 'app_summo_3'
    features: string[]
    monthlyPrice: number
    yearlyPrice: number
    comingSoon: boolean
  }
  frequency: 'monthly' | 'yearly'
  selectedPlan: 'basic' | 'standard' | 'enterprise' | 'app_summo_1' | 'app_summo_2' | 'app_summo_3'
  selectPlan: Callback
}

export const PricingPlan: React.FC<Props> = ({ planDetails, selectedPlan, selectPlan, frequency }) => {
  return (
    <button
      className={ classnames({ [classes.box]: true, [classes.selected]: selectedPlan ===  planDetails?.name }) }
      type="button"
      onClick={ selectPlan }
      disabled={ planDetails?.comingSoon }
    >
      <div className={ classes.heading }>
        <h4 className={ classes.title }>{ planDetails?.name }</h4>
        { planDetails?.comingSoon ? <span className={ classes.soon }>Coming soon</span> : null }
      </div>
      <ul className={ classnames({ [classes.features]: true }) }>
        { planDetails?.features.map((feature, i) =>
          <li key={ i }>
            <span>{ feature }</span>
          </li>
        )}
      </ul>
      <div className={ classes.price }>
        <span className={ classes['price-sign'] }>$</span>
        <span className={ classes['price-val'] }> { frequency === 'monthly' ? `${ planDetails?.monthlyPrice }` : `${ planDetails?.yearlyPrice }`} </span>
        <span className={ classes['price-period'] }>{ frequency === 'monthly' ? '/ month' : '/ year' }</span>
      </div>
    </button>
  )
}
