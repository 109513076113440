import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import { App } from 'src/App'
import { CoreApiContext } from 'src/hooks/useCoreApi'
import { initDependencies } from 'src/init'
import * as serviceWorker from 'src/serviceWorker'

import './index.css'
import { ProvideAuth } from './hooks/useAuth'

import mixpanel from 'mixpanel-browser'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'


const { coreApi } = initDependencies()

mixpanel.init('68976e4b742dbeb2cd666c37173acce9')
const stripePromise = loadStripe('pk_test_51HiI6GIHqP0KZWCzXgaK6JM7fQ102dBPxIrFM8aLRFEVjSTtgwlNjYJfSuXcpYkIR4phoz8Vd1lEVxl6Q4HwqqE300weYVRKJh')


ReactDOM.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <CoreApiContext.Provider value={coreApi}>
        <ProvideAuth>
          <Router>
            <App />
          </Router>
        </ProvideAuth>
      </CoreApiContext.Provider>
    </Elements>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
