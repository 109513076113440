import React, { FC, useState, useEffect } from 'react'

import classes from '../LinkedinPreview/LinkedinPreview.module.css'
import { PreviewProps } from '../PreviewList'


export const LinkedinPreview: FC<PreviewProps> = ({ title, body, link, image }) => {
  const [url, setUrl] = useState<URL>()
  useEffect(() => {
    try {
      setUrl(new URL(link))
    } catch (error) {
      setUrl(undefined)
    }
  }, [link])
  return(
    <div className={classes['card-seo-linkedin']}>
      <div className={classes['card-seo-linkedin__image']} style={{ backgroundImage: `url(${image})` }}></div>
      <div className={classes['card-seo-linkedin__text']}>
        <div className={classes['card-seo-linkedin__content']}>
          <div className={classes['card-seo-linkedin__title']}>{title}</div>
          <span className={classes['card-seo-linkedin__link']}>{url ? url.host : ''}</span>
          <span className={classes['card-seo-linkedin__description']}>{body}</span>
        </div>
      </div>
    </div>
    // <div className={classes.card_seo_slack}>
    //   <div className={classes.card_seo_slack__bar}></div>
    //   <div className={classes.card_seo_slack__content}>
    //     <div>
    //       {/* <img
    //         className={classes.card_seo_slack__favicon}
    //         alt="favicon"
    //         src={`${link}/favicon.ico`}
    //       /> */}
    //       <span className={classes.card_seo_slack__link}>{url ? url.host : ''}</span>
    //     </div>
    //     <div className={classes.card_seo_slack__title}>
    //       {title}
    //     </div>
    //     <span className={classes.card_seo_slack__description}>{body}</span>
    //     <div
    //       className={classes.card_seo_slack__image}
    //     // style={{ backgroundImage: `url(${image})` }}
    //     >
    //       <img alt="slack-preview" src={image}/>
    //     </div>
    //   </div>
    // </div>
  )}
