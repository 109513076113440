import React from 'react'


interface Props {
    className?: string
}

export const IcoSave:React.FC<Props> = ({ className }) => (
  <svg className={ className }  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 17.4785 14.1587"><defs/><path d="M248.8135 43.1587L243 36.7739 244.4785 35.4272 248.8135 40.188 259 29 260.4785 30.3467z" transform="translate(-243 -29)"/></svg>
)


