import React, { FC } from 'react'

import classes from '../InstagramPreview/InstagramPreview.module.css'
import { PreviewProps } from '../PreviewList'


export const InstagramPreview: FC<PreviewProps> = ({ title, body, link, image }) => {
  return(
    <div className={classes['card-seo-instagram']}>
      <div className={classes['card-seo-instagram__image']} style={{ backgroundImage: `url(${image})` }}></div>
      <div className={classes['card-seo-instagram__text']}>
        <div className={classes['card-seo-instagram__title']}>{title}</div>
        {/* <div className={classes['card-seo-instagram__body']}>{body}</div> */}
      </div>
    </div>
  )}
