export enum Channel {
  facebook,
  telegram,
  slack,
  twitter,
  viber,
  pinterest,
  linkedin, 
  whatsapp,
  instagram
}
