import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeCardElementOptions } from '@stripe/stripe-js'
import classnames from 'classnames'
import mixpanel from 'mixpanel-browser'
import React, { useState, useCallback, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Redirect, useHistory } from 'react-router-dom'

import { UserData } from 'src/apis/core'
import { Callback } from 'src/common'
import { CurrentPlan } from 'src/components/CurrentPlan/CurrentPlan'
import { PricingPlan } from 'src/components/PricingPlan/PricingPlan'
import buttonClasses from 'src/components/shared/button/Button.module.css'
import buttonStyles from 'src/components/shared/button/Button.module.css'
import { ErrorMessage } from 'src/components/shared/errorMessage/ErrorMessage'
import inputStyles from 'src/components/shared/inputs/inputs.module.css'
import { Modal } from 'src/components/shared/modal/Modal'
import { trackPaymentIntentToGA } from 'src/google-analytics'
import { useAuth } from 'src/hooks/useAuth'
import { useCoreApi } from 'src/hooks/useCoreApi'
import { TARIFF_LIST_FEATURES } from 'src/models/TariffsListFeatures'

import icoClosed from '../../assets/icons/ico-closed.svg'
import icoError from '../../assets/icons/ico-error.svg'
import icoSuccess from '../../assets/icons/ico-success.svg'
import profileClasses from '../AccountSettings/AccountSettings.module.css'

import classes from './Billing.module.css'


interface Props {
  user: UserData | null
  setUser: Callback<UserData | null>
}

export const Billing: React.FC<Props> = ({ user, setUser }) => {
  const { register, handleSubmit: handleAppSumoCodeSubmit } = useForm()
  const history = useHistory()
  const [paymentFrequency, setPaymentFrequency] = useState<
    'monthly' | 'yearly'
  >('monthly')
  const [paymentProcessing, setPaymentProcessing] = useState(
    new URLSearchParams(window.location.search).has('paymentId')
  )
  const [paymentProcessingStatus, setPaymentProcessingStatus] = useState<
    'loading' | 'confirmed' | 'rejected'
  >('loading')
  const [pricingList, setPricingList] = useState<{
    [key: string]: {
      name:
      'basic' | 'standard' | 'enterprise'
        | 'app_summo_1'
        | 'app_summo_2'
        | 'app_summo_3'
      features: string[]
      monthlyPrice: number
      yearlyPrice: number
      comingSoon: boolean
    }
  }>({})
  const [selectedPlan, setSelectedPlan] = useState<
  'basic' | 'standard' | 'enterprise' | 'app_summo_1' | 'app_summo_2' | 'app_summo_3'
  >('basic')
  const [stripeForm, setStripeForm] = useState(false)
  const [stripeData, setStripeData] = useState()
  const [appSumoError, setAppSumoError] = useState<string | null>(null)
  const auth = useAuth()
  const coreApi = useCoreApi()

  const toggleHandler = useCallback(
    () =>
      setPaymentFrequency((current) =>
        current === 'monthly' ? 'yearly' : 'monthly'
      ),
    []
  )

  const selectPlanHandler = useCallback(
    (
      name:
        | 'basic' | 'standard' | 'enterprise' 
        | 'app_summo_1'
        | 'app_summo_2'
        | 'app_summo_3'
    ) => setSelectedPlan(name),
    []
  )

  const getPaymentPrice = () => {
    const plan = pricingList[selectedPlan]
    const frequency =
      paymentFrequency === 'monthly' ? 'monthlyPrice' : 'yearlyPrice'
    return plan && plan[frequency]
  }

  const stripe = useStripe()
  const elements = useElements()

  const stripeHandler = async () => {
    fetch(
      'https://api.piar.io/api/billing/payments/stripe/intent?strategy=MONTHLY',
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
        },
      }
    ).then((res) => {
      res.json().then((data) => {
        console.log(data)
        setStripeForm(true)
        setStripeData(data)
      })
    })
  }

  const CARD_OPTIONS: StripeCardElementOptions = {
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#c4f0ff',
        color: '#fff',
        fontWeight: '500',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': { color: '#fce883' },
        '::placeholder': { color: '#87bbfd' },
      },
      invalid: {
        iconColor: '#ffc7ee',
        color: '#ffc7ee',
      },
    },
  }

  const handleSubmit = async (event: any) => {
    // Block native form submission.
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    const cardElement = elements.getElement(CardElement)

    await stripe
      ?.confirmCardPayment((stripeData as any).clientSecret, {
        payment_method: {
          card: cardElement,
        } as any,
      })
      .then(function (result) {
        console.log(result)
      })
  }

  const paymentHandler = useCallback(async () => {
    mixpanel.track('pay_btn_click')
    trackPaymentIntentToGA(user?.id)
    if (user?.subscription?.trial || user?.subscription?.name === 'basic') {
      const redirectUrl = (
        await coreApi.checkoutPayment(
          paymentFrequency === 'yearly' ? 'YEARLY' : 'MONTHLY'
        )
      ).redirectUrl
      window.location.assign(redirectUrl)
    } else {
      const redirectUrl = (
        await coreApi.startNewPlan({
          name: 'basic',
          strategy: paymentFrequency.toUpperCase(),
        })
      ).redirectUrl
      window.location.assign(redirectUrl)
    }
  }, [paymentFrequency, coreApi, user])

  const startTrial = async () => {
    const userData = await coreApi.startTrial('basic')
    setUser(userData)
  }

  useEffect(() => {
    (async () => {
      const paymentId = new URLSearchParams(window.location.search).get(
        'paymentId'
      )
      const payerId = new URLSearchParams(window.location.search).get('PayerID')
      if (payerId && paymentId && paymentProcessing) {
        try {
          const userData = await coreApi.completePayment({
            paymentId: paymentId,
            payerId: payerId,
          })
          setPaymentProcessingStatus('confirmed')
          await new Promise((resolve) => setTimeout(() => resolve(null), 5000))
          history.push('/billing')
          setUser(userData)
        } catch (error) {
          setPaymentProcessingStatus('rejected')
          await new Promise((resolve) => setTimeout(() => resolve(null), 5000))
          history.push('/billing')
        }
      }
    })()
  }, [coreApi, history, paymentProcessing, setUser])

  useEffect(() => {
    (async () => {
      const plans = await coreApi.getBillingPlans()
      let list: {
        [key: string]: {
          name:
            | 'basic' | 'standard' | 'enterprise'
            | 'app_summo_1'
            | 'app_summo_2'
            | 'app_summo_3'
          features: string[]
          monthlyPrice: number
          yearlyPrice: number
          comingSoon: boolean
        }
      } = {}
      plans
        .sort((a, b) => a.monthlyPrice - b.monthlyPrice)
        .forEach(({ name, monthlyPrice, yearlyPrice }) => {
          list = {
            ...list,
            [name]: {
              ...TARIFF_LIST_FEATURES[name],
              name,
              monthlyPrice,
              yearlyPrice,
            },
          }
        })
      setPricingList(list)
    })()
  }, [coreApi])

  const appSumoCodeSubmit: SubmitHandler<any> = ({ code }) => {
    if (!code.length) {
      return
    }
    coreApi.updateAppSumoCodes({ codes: [code] }).then(
      () => window.location.assign(window.location.toString()),
      ({ error, description }) =>
        setAppSumoError(description ? description : error)
    )
  }

  if (!auth.token) {
    return (
      <Redirect
        to={{
          pathname: '/builder',
        }}
      />
    )
  }

  return (
    <>
      <article className={classes.billing}>
        <h2 className={classes.title}>Subscription</h2>
        <p className={classes.subtitle}>Customize your preview link</p>
        <div className={classes.content}>
          <section>
            <h3 className={classes['section-title']}>Your plan</h3>
            <CurrentPlan
              subscription={user?.subscription}
              planDetails={
                (user?.subscription && pricingList[user.subscription.name]) ||
              pricingList['basic']
              }
              startTrial={startTrial}
              paymentHandler={paymentHandler}
              frequency={paymentFrequency}
            />
          </section>
          { user?.subscription.name.startsWith('app_summo') ?
            <section>
              <h3 className={classes['section-title']}>Stacking an AppSumo Code</h3>
              <div className={classnames(profileClasses['section-card'])}>
                <p className={classes.text}>
              If you are one of the lucky ones who purchased more than one AppSumo code,
              enter your code below. If you have more than one, please add them
              one at a time
                </p>
                <form
                  style={{
                    marginTop: '12px',
                  }}
                  onSubmit={handleAppSumoCodeSubmit(appSumoCodeSubmit)}
                >
                  <div className={profileClasses['settings-details']}>
                    <div className={profileClasses['form-group']}>
                      <label htmlFor='code' className={inputStyles.title}>
                    AppSumo Code
                      </label>
                      <input
                        className={classnames(inputStyles.input)}
                        type='text'
                        placeholder='Input Code'
                        name='code'
                        id='code'
                        ref={register()}
                      />
                    </div>
                    <div className={profileClasses['form-group']}>
                      <button
                        className={classnames(
                          buttonStyles['root'],
                          buttonStyles['btn-accent-1']
                        )}
                        type='submit'
                      >
                    Apply code
                      </button>
                    </div>
                  </div>
                </form>
                {appSumoError ? <ErrorMessage>{appSumoError}</ErrorMessage> : null}
              </div>
            </section>
            : null
          }
          {!user?.subscription.name.startsWith('app_summo') ? <>
            <section>
              <h3 className={classes['section-title']}>Select frequency</h3>
              <div className={classes['toggle-frequency']}>
                <button
                  type='button'
                  className={classnames({
                    [classes['toggle-btn']]: true,
                    [classes.selected]: paymentFrequency === 'yearly',
                  })}
                  disabled={paymentFrequency === 'yearly'}
                  onClick={toggleHandler}
                >
                  <span>Annual</span>
                  <span className={classes.discount}>save 17%</span>
                </button>
                <button
                  type='button'
                  className={classnames({
                    [classes['toggle-btn']]: true,
                    [classes.selected]: paymentFrequency === 'monthly',
                  })}
                  disabled={paymentFrequency === 'monthly'}
                  onClick={toggleHandler}
                >
                  <span>Monthly</span>
                </button>
              </div>
            </section>
            <section>
              <h3 className={classes['section-title']}>Select plan</h3>
              <div className={classes['tariffs-wrapper']}>
                {Object.keys(pricingList).map((key, i) => {
                  return pricingList[key]['name'] === 'enterprise' ||
                    pricingList[key]['name'] === 'basic' ||
                    pricingList[key]['name'] === 'standard' ? (
                      <PricingPlan
                        key={i}
                        planDetails={pricingList[key]}
                        selectedPlan={selectedPlan}
                        selectPlan={() =>
                          selectPlanHandler(pricingList[key]['name'])
                        }
                        frequency={paymentFrequency}
                      />
                    ) : null
                })}
              </div>
            </section>
            <div>
              <button
                type='button'
                className={classnames(
                  buttonClasses.root,
                  buttonClasses.active,
                  classes.Button,
                  classes['payment-btn']
                )}
                onClick={() => {
              user?.email === 'nvipus24@gmail.com' ||
              user?.email === 'maystrovyy@gmail.com' ||
              user?.email === 'nvipus24+demo@gmail.com'
                ? stripeHandler()
                : paymentHandler()
                }}
              >
                {`Pay $${getPaymentPrice()}`}
              </button>
            </div>
          </>
            : null}
          {stripeForm && (
            <form onSubmit={handleSubmit}>
              <fieldset className={classes.FormGroup}>
                <div className={classes.FormRow}>
                  <CardElement options={CARD_OPTIONS} />
                </div>
              </fieldset>
              <button type='submit' disabled={!stripe}>
              Pay
              </button>
            </form>
          )}
        </div>
        <Modal
          visible={paymentProcessing}
          onCloseAttempt={() => {
            history.push('/billing')
          }}
        >
          <div className={classes['payment-modal']}>
            <button
              type='button'
              onClick={() => setPaymentProcessing(false)}
              className={classes['modal-close']}
              aria-label='Close modal'
            >
              <img src={icoClosed} alt='Close modal' />
            </button>

            {paymentProcessingStatus === 'loading' ? (
              <span className={classes['payment-loading']}></span>
            ) : (
              <>
                <img
                  src={
                    paymentProcessingStatus === 'confirmed'
                      ? icoSuccess
                      : icoError
                  }
                  alt='Payment status icon'
                  width='32'
                />
                <h4>
                  {paymentProcessingStatus === 'confirmed'
                    ? 'Payment successful'
                    : 'Payment error'}
                </h4>
                <p>
                  {paymentProcessingStatus === 'confirmed'
                    ? 'Thank you for your payment'
                    : 'Some error was occurred'}
                </p>
              </>
            )}
          </div>
        </Modal>
      </article>
      <footer>
        <div>© 2021 Piar.io. represented in the USA by BarvaTech LLC 30 N Gould St Ste R Sheridan, WY 82801</div>
      </footer>
    </>
  )
}
