import Tippy from '@tippyjs/react'
import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { StatisticResponse } from 'src/apis/core'
import classesCard from 'src/components/HistoryItemCard/HistoryItemCard.module.css'
import { InputWithAction } from 'src/components/InputWithAction/InputWithAction'
import classes from 'src/components/StatisticModal/StatisticModal.module.css'
import { ButtonGroup } from 'src/components/shared/buttonGroup/ButtonGroup'
import { Bar } from 'src/components/shared/charts/Bar'
import { Pie } from 'src/components/shared/charts/Pie'
import { useClipboard } from 'src/hooks/useClipboard'
import { useCoreApi } from 'src/hooks/useCoreApi'
import { PreviewHistoryItem } from 'src/models/PreviewHistoryItem'

import ownClasses from './StatsSharing.module.css'


export const StatsSharing: React.FC = () => {
  const { hash } = useParams() as any
  const coreApi = useCoreApi()
  const [stat, setStat] = useState<StatisticResponse>()
  const [preview, setPreview] = useState<PreviewHistoryItem>()
  const [statisticPeriod, setStatisticPeriod] = useState<'LAST_DAY' | 'YESTERDAY' | 'WEEK' | 'MONTH' | 'LIFETIME'>('LIFETIME')
  const getCreatedDate = (date: string) => new Date(date).toLocaleString('en-GB', { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })
  const copyLinkToClipboard = useClipboard(`${preview?.domain}/${preview?.hash}`)

  const periodButtons = [
    { title: 'Last day', action: () => setStatisticPeriod('LAST_DAY'), isSelected: statisticPeriod === 'LAST_DAY' },
    { title: 'Yesterday', action: () => setStatisticPeriod('YESTERDAY'), isSelected: statisticPeriod === 'YESTERDAY' },
    { title: 'Week', action: () => setStatisticPeriod('WEEK'), isSelected: statisticPeriod === 'WEEK' },
    { title: 'Month', action: () => setStatisticPeriod('MONTH'), isSelected: statisticPeriod === 'MONTH' },
    { title: 'All time', action: () => setStatisticPeriod('LIFETIME'), isSelected: statisticPeriod === 'LIFETIME' },
  ]
    
  useEffect(() => {
    coreApi.getPreviewPublicStat({ hash, period: statisticPeriod }).then(setStat)
  }, [statisticPeriod, hash, coreApi])

  useEffect(() => {
    coreApi.getPreviewPublicContent({ hash }).then(setPreview)
  }, [coreApi, hash])

  return (
    <div className={ownClasses.root}>
      <h4 className={classes.modal_caption}>Preview statistic</h4>
      <div className={classnames(classes['preview-content'], ownClasses['preview-content'])}>
        <div className={classesCard.root}>
          <div className={classesCard['heading-group']}>
            {/* <Tippy
              content={ stat?.content?.name }
              placement="top-start"
            >
              <h2>{ stat?.content?.name }</h2>
            </Tippy> */}
          </div>
          <div className={classesCard.preview} style={{ cursor: 'default' }}>
            <div className={classesCard.image}>
              <img alt="preview" src={preview?.image}/>
            </div>
            <div className={classesCard.preview_caption}>
              <p className={classesCard.title}>{preview?.title}</p>
              <p className={classesCard.secondary}>{preview?.body}</p>
            </div>
          </div>
          <div className={classesCard.caption}>
            {/* <span className={classesCard.label}>Author</span> */}
            {/* <span className={classesCard.content}>{stat?.content?.createdBy}</span> */}
            <span className={classesCard.label}>Destination</span>
            <Tippy
              content={ preview?.link }
              placement="top-start"
            >
              <a
                href={ preview?.link }
                target="_blank"
                rel="noopener noreferrer"
                className={classnames(classesCard.target_link, classesCard.content)}
              >{ preview?.link }</a>
            </Tippy>
          </div>
          <InputWithAction btn_styles={{ backgroundColor:'#40466e' }} inputText={`${preview?.domain}/${preview?.hash}`} inputDescription="Preview Link" action={copyLinkToClipboard} buttonText="Copy"/>
          <div className={classesCard['preview-meta']}>
            <div>
              <span>{preview ? getCreatedDate(preview.createdAt) : ''}</span>
              <span className={classesCard.label}>
              Generated
              </span>
            </div>
            <div>
              <span>
                {stat?.totalClicks}
              </span>
              <span className={classesCard.label}>Total clicks</span>
            </div>
          </div>
        </div>

        <div className={classes.charts_container}>
          {/* { !stat &&
              <div className={classes.Statistic__overlay}>
                { user?.status === 'ACTIVE'
                  ?
                  <>
                    <p style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>Start your trial to see statistic<br/>
                      <Button button_styles={{ marginTop: '10px' }} onClick={() => history.push('/billing')}>Start now!</Button>
                    </p>
                  </>
                  :
                  (user?.status === 'CLIENT' && (user.subscription.expiredAt.getTime() < Date.now()))
                    ?
                    <>
                      <p style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>Select your plan to see statistic<br/>
                        <Button button_styles={{ marginTop: '10px' }} onClick={() => history.push('/billing')}>Select plan</Button>
                      </p>
                    </>
                    :
                    loading ?
                      <p>Stats is loading...</p> :
                      <p>There is no statistics yet. Share the link to your clients to see more...</p>
                }
              </div>
            } */}
          <div className={classes.Bar_container} style={stat ? {} : { filter: 'opacity(0.3) grayscale(1)' }}>
            <div className={classes['period-picker']}>
              <h5 className={ classes.caption }>Clicks</h5>
              <ButtonGroup
                list={ periodButtons }
              />
            </div>
            {!stat?.discreteClicks?.find(i => i?.amount > 0) && stat?.totalClicks && (<div className={classes.empty_stat_message}>
              <p>There is no statistic by this period</p>
            </div>)}
            <div className={classes.Bar_wrapper} style={!stat?.discreteClicks?.find(i => i?.amount > 0) && stat?.totalClicks ? { filter: 'opacity(0.3) grayscale(1)' }: {}}>
              <Bar data={stat ? stat.discreteClicks : null} totalClicks={stat ? stat.totalClicks : 0}/>
            </div>
          </div>
          <div className={classnames(classes.Pies_container)} style={stat ? {} : { filter: 'opacity(0.3) grayscale(1)' }}>
            <div className={classes.Pie_wrapper}>
              <Pie data={stat && stat.operationSystems ? stat.operationSystems : null}/>
              <p>OS</p>
            </div>
            <div className={classes.Pie_wrapper}>
              <Pie data={stat && stat.devices ? stat.devices : null}/>
              <p>Platforms</p>
            </div>
            <div className={classes.Pie_wrapper}>
              <Pie data={(stat && stat.browsers) ? stat.browsers : null}/>
              <p>Browsers</p>
            </div>
            <div className={classes.Pie_wrapper}>
              <Pie data={stat && stat.countries ? stat.countries : null}/>
              <p>Countries</p>
            </div>
            <div className={classes.Pie_wrapper}>
              <Pie data={stat && stat.cities ? stat.cities : null}/>
              <p>Cities</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
