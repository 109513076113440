import React, { FC, useEffect, useState } from 'react'

import { PreviewProps } from '../PreviewList'
import classes from '../TelegramPreview/TelegramPreview.module.css'


export const TelegramPreview: FC<PreviewProps> = ({ title, body, link, image }) => {
  const [url, setUrl] = useState<URL>()
  useEffect(() => {
    try {
      setUrl(new URL(link))
    } catch (error) {
      setUrl(undefined)
    }
  }, [link])

  const [previewImageType, setPreviewImageType] = useState<'big' | 'small' | 'none'>('big')
  const imageInstance = new Image()
  imageInstance.src = image
  imageInstance.onload = function() {
    if((imageInstance.height / imageInstance.width) > 20 || (imageInstance.width / imageInstance.height) > 20){
      return setPreviewImageType('none')
    }
    if(imageInstance.height / imageInstance.width > 12.8){
      return setPreviewImageType('small')
    }
    if(imageInstance.width < imageInstance.height && ((imageInstance.width > 200) && (imageInstance.height > 320))) {
      return setPreviewImageType('big')
    }
    if(((imageInstance.width === imageInstance.height) || (imageInstance.width > imageInstance.height)) && (imageInstance.width > 320) && (imageInstance.height > 100)) {
      return setPreviewImageType('big')
    }
    return setPreviewImageType('small')
  }

  if(previewImageType === 'none') return (
    <div className={classes.im_message_webpage_wrap}>
      <div className={classes.im_message_webpage_photo}>
        <span className={classes.im_message_photo_thumb} style={{ float: 'right' }}>
        </span>
        <div className={classes.im_message_webpage_title}>
          <span>{title}</span>
        </div>
        <div className={classes.im_message_webpage_description}>{body}</div>
      </div>
    </div>
  )

  if(previewImageType === 'small') return (
    <div className={classes.im_message_webpage_wrap}>
      <div className={classes.im_message_webpage_photo}>
        <span className={classes.im_message_photo_thumb} style={{ float: 'right' }}>
          <img
            alt="preview"
            className={classes.im_message_photo_thumb}
            src={image}
            width={55}
            height={55}
            style={{ margin: '5px 0 5px 5px' }}
          />
        </span>
        <div className={classes.im_message_webpage_title}>
          <span>{title}</span>
        </div>
        <div className={classes.im_message_webpage_description}>{body}</div>
      </div>
    </div>
  )
  

  return (
    <div className={classes.im_message_webpage_wrap}>
      <div className={classes.im_message_webpage_photo}>
        <div className={classes.im_message_webpage_host}>
          <span>{url ? url.host : ''}</span>
        </div>
        <div className={classes.im_message_webpage_title}>
          <span>{title}</span>
        </div>
        <div className={classes.im_message_webpage_description}>{body}</div>
        <span className={classes.im_message_photo_thumb}>
          <img
            alt="preview"
            className={classes.im_message_photo_thumb}
            src={image}
            width={300}
          />
        </span>
      </div>
    </div>
  )}
