import React from 'react'

import classes from './DnsRecord.module.css'


interface Props {
    type: string
    name: string
    value: string
}

export const DnsRecord: React.FC<Props> = ({ type, name, value }: Props) => {
  return ( 
    <div className={ classes['wrapper'] }>
      <table>
        <tbody>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Value</th>
          </tr>
          <tr>
            <td>{name}</td>
            <td>{type}</td>
            <td>{value}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
