import classnames from 'classnames'
import React, { useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import buttonStyles from 'src/components/shared/button/Button.module.css'
import inputClasses from 'src/components/shared/inputs/inputs.module.css'
import { Domain, DomainStatus } from 'src/models/Domain'
import { isValidUrl } from 'src/utils/validators'

import icoDomain from '../../assets/icons/ico-domain.svg'
// import { DomainChips } from '../DomainChips/DomainChips'

import { DnsRecord } from './DnsRecord/DnsRecord'
import { DomainSettings } from './DomainSettings'
import classes from './DomainsSettings.module.css'


const dnsRecord = {
  type: 'CNAME',
  name: 'www',
  value: 'app.piar.io',
}

interface Props {
    domainsList: Domain[]
    setDefaultDomain: (id: number) => void
    createNewDomain: (domain: string) => void
    deleteDomain: (id: number) => void
    refreshDomain: (id: number) => Promise<Domain>
}

export const DomainsSettings: React.FC<Props> = ({ domainsList, createNewDomain, deleteDomain, refreshDomain, setDefaultDomain }: Props) => {
  const [newDomain, setNewDomain] = useState<string>('')

  const createDomain = () => {
    if (!newDomain) return
    
    if (!isValidUrl(newDomain)) {
      toast.error( 'Incorrect domain format. Check it and try again.', {
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      })
      return
    }

    toast.info( 'Adding new domains is currently unavailable. Access will be restored during one week.', {
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
    })
    return

    // createNewDomain(newDomain)
  }

  const nonactiveDomainPresented = useMemo(() => {
    return domainsList.some((i) => i.status !== DomainStatus.ACTIVE)
  }, [domainsList])

  return ( 
    <section>
      <h3 className={ classes['section-title']}>Domain</h3>
      <div className={ classnames(classes['section-card'], classes['section-info']) }>
        <img className={ classes.icon } src={ icoDomain } alt="Domain icon" width="63" />
        <div className={ classes.details }>
          <h4 className={ classes.subtitle }>Connect your domain for creating links</h4>
          <p className={ classes.text }>
            To open all the features of the service, you need to connect your own domain. 
            If you don&apos;t know how to do this or don&apos;t have a domain, 
            you can find answers to these questions in the detailed instructions at the <a href='#' target='_blank'>LINK</a>
          </p>
        </div>
        <div className={ classes['settings-domains'] }>
          {
            domainsList.length ? (
              <>
                {
                  nonactiveDomainPresented ? (
                    <div>
                      <p>Set the following A-record on your DNS provider to continue:</p>
                      <DnsRecord {...dnsRecord} />
                    </div>
                  ) : null
                }
                {domainsList.map((domain, index) => (
                  <DomainSettings key={index} domain={domain} refreshDomain={refreshDomain} deleteDomain={deleteDomain} setDefaultDomain={setDefaultDomain} />
                ))}
                <p className={ classes['hint'] }>
                  Depending on your provider, the application of DNS records may require some time. 
                  {/* eslint-disable-next-line */}
                  <a href='#' target='_blank' rel='noopener noreferrer'>Learn more</a>
                </p>
              </>
            ) : (
              <div className={ classes['domain-creator'] }>
                <input 
                  type="text" className={classnames(inputClasses.input)}
                  value={newDomain}
                  onChange={(e) => setNewDomain(e.currentTarget.value)}
                  placeholder='https://link.yourdomain.com'
                />
                <button disabled={!newDomain} onClick={createDomain} className={ classnames(buttonStyles['root'], buttonStyles['btn-accent-1']) }>
                  Add new domain
                </button>
              </div>
            )
          }
        </div>
        {/* <div className={ classes.actions }>
          <div className={ classes['domains-list'] }>
            { domainsList.map((domain, key) =>
              <DomainChips
                key={ key }
                domain={ domain }
                setAsDefault={ setDefaultDomain }
              />
            )}
          </div>
          <button
            className={ classnames(buttonStyles['root'], buttonStyles['btn-accent-1']) }
            type="button"
            onClick={ addDomain }
          >Send request</button>
        </div> */}
      </div>
    </section>
  )
}
