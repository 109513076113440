import { useLocation } from 'react-router-dom'


export const useQuery = (queryParam: string) => {
  const search = new URLSearchParams(useLocation().search)
  return search.get(queryParam)
}

export const useQueryParams = () => {
  const search = new URLSearchParams(useLocation().search)
  const params: any = {}
  search.forEach((value, key) => {
    params[key] = value
    return
  })
  return params
}
