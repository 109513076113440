import classnames from 'classnames'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import ReactCrop, { Crop } from 'react-image-crop'
import { toast } from 'react-toastify'

import { Callback } from 'src/common'

import icoArrowDown from '../../assets/icons/ico-arrowdown.svg'
import icoImage from '../../assets/icons/ico-img.svg'
import imageIcon from '../../assets/images/image-in-cloud.svg'
import 'react-image-crop/dist/ReactCrop.css'
import { Button } from '../shared/button/Button'
import buttonClasses from '../shared/button/Button.module.css'
import { Input } from '../shared/inputs/Input'
import inputClasses from '../shared/inputs/inputs.module.css'

import classes from './ImageEditor.module.css'

import mixpanel from 'mixpanel-browser'


interface Props {
    setModalVisible: Callback<boolean>
    setImageURL: any
    imageURL: string
}

const aspects = {
  'facebook': 1200 / 630,
  'twitter': 2 / 1,
  'reddit': 1 / 1,
  'custom': undefined,
  'none': undefined,
}

type FormValues = {
  title: string
  body: string
  name: string
  link: string
  image: string
}

export const ImageEditor: React.FC<Props> = ({ setModalVisible, setImageURL, imageURL }) => {

  const imgRef = useRef<HTMLImageElement | null>(null)
  const [crop, setCrop] = useState<Crop | undefined>({ unit: '%', width: 80, aspect: aspects['facebook'] })
  const [completedCrop, setCompletedCrop] = useState<Crop | null>(null)
  const [loadError, setLoadError] = useState<string | null>(null)
  const previewCanvasRef = useRef<HTMLCanvasElement | null>(null)
  const fileInputRef = useRef<any>(null)
  const [uploadedImageURL, setUploadedImageURL] = useState<string>(imageURL.startsWith('data:') ? imageURL : '')

  const { register, watch, setValue, errors, trigger, setError } = useForm<any>({ defaultValues: { imageURL: imageURL.startsWith('data:') ? '' : imageURL, reValidateMode: 'onChange' } })
  const watchInput = watch().imageURL

  useEffect(() => {
    imageURL.startsWith('data:') ? setUploadedImageURL(imageURL) : setValue('imageURL', imageURL)
  }, [imageURL, setValue, setUploadedImageURL])

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return
    }

    const image:HTMLImageElement = imgRef.current
    const canvas:HTMLCanvasElement = previewCanvasRef.current
    const crop: Crop = completedCrop

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D

    canvas.width = Math.trunc(crop.width as number * scaleX)
    canvas.height = Math.trunc(crop.height as number * scaleY)
    ctx.imageSmoothingEnabled = true

    ctx.drawImage(
      image,
          crop.x as number * scaleX,
          crop.y as number * scaleY,
          crop.width as number * scaleX,
          crop.height as number * scaleY,
          0,
          0,
          crop.width as number * scaleX,
          crop.height as number * scaleY
    )
  }, [completedCrop])

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (!files?.length) return
    const uploadedImage = files[0]
    if (uploadedImage.size > 5000000) {
      setLoadError('File size exceeds 5mb')
      return
    }
    setLoadError(null)
    const reader  = new FileReader()
    reader.onloadend = () => {
      setUploadedImageURL(URL.createObjectURL(uploadedImage))
      fileInputRef.current.value = ''
      setValue('imageURL', '')
      // setImageURLFromInput('')
    }
    if (uploadedImage) {
      reader.readAsDataURL(uploadedImage)
    } else {
      setUploadedImageURL('')
    }
    // methods.setValue('image', URL.createObjectURL(uploadedImage))
  }

  const onLoad = useCallback((img: HTMLImageElement) => {
    imgRef.current = img
    setCrop({ unit: '%', width: 80, aspect: aspects['facebook'] })
    return false
  }, [])

  function getResizedCanvas(canvas: CanvasImageSource, newWidth: number, newHeight: number) {
    const tmpCanvas = document.createElement('canvas')
    tmpCanvas.width = newWidth
    tmpCanvas.height = newHeight

    const ctx = tmpCanvas.getContext('2d')
    const imgData = (ctx as CanvasRenderingContext2D).getImageData(0, 0, canvas.width as number, canvas.height as number)
    const data= imgData.data
    for(let i=0;i<data.length;i+=4){
      if(data[i+3]<255){
        data[i]=255
        data[i+1]=255
        data[i+2]=255
        data[i+3]=255
      }
    }
    ctx?.putImageData(imgData, 0, 0)
    ctx?.drawImage(
      canvas,
      0,
      0,
      canvas.width as number,
      canvas.height as number,
      0,
      0,
      newWidth as number,
      newHeight as number
    )

    return tmpCanvas
  }

  const saveImageUrl = (url: any) => {
    setImageURL(url)
    toast.info('Image saved', {
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
    })
  }
  function isValidUrl(value: string) {
    try {
      new URL(value)
    } catch (_) {
      return false  
    }
  
    return true
  }

  function generateDownload(previewCanvas: HTMLCanvasElement | null, crop: Crop | null) {
    console.log(crop)

    if ((!crop || !previewCanvas) && watchInput.length) {
      if(errors.imageURL){
        setImageURL('')
        return
      }
      setImageURL(watchInput)
      return
    }

    if (!crop || !previewCanvas) {
      return
    }

    const canvas = getResizedCanvas(previewCanvas, crop?.width as number * ((imgRef.current?.naturalWidth as number) / (imgRef.current?.width as number)), crop?.height as number * ((imgRef.current?.naturalHeight as number) / (imgRef.current?.height as number)))

    const imgAsDataURL = canvas.toDataURL('image/jpeg')

    saveImageUrl(imgAsDataURL)

    if(watchInput.length){
      setUploadedImageURL('')
    }
  }

  useEffect(() => {
    !errors.inputURL && setLoadError(null)
    if(watchInput.length) {
      setUploadedImageURL('')
      trigger('imageURL')
    }
  }, [watchInput, trigger, errors.inputURL])

  return (
    <div className={ classes['editor-wrapper']}>
      <div className={ classnames(classes['image-wrapper'], !loadError || errors.imageURL ? '' : classes['hidden-wrapper']) }>
        <input
          ref={fileInputRef}
          id="image-upload"
          type="file"
          accept=".jpeg, .png, .gif, .jpg"
          onChange={ onSelectFile }
          hidden
        />

        { !((uploadedImageURL || watchInput.length) && !loadError)  &&
          <>
            <label onClick={() => mixpanel.track('upload_click')} className={ classes['image-grabber']} htmlFor="image-upload">
              <img src={ imageIcon } alt="Icon" width="144" />
              <p className={ classes['image-caption'] }>Select image from computer</p>
              <span className={ classnames(classes['image-btn'], buttonClasses.root, buttonClasses['btn-accent-1']) }>Upload</span>
              <p className={ classes['image-note'] }>Max file size: 5mb, supported formats: jpg,gif,png</p>
            </label>
          </>
        }

        { ((uploadedImageURL || watchInput.length) && !loadError) ?
          <>
            <ReactCrop
              src={watchInput.length ? watchInput : uploadedImageURL}
              crossorigin='anonymous'
              onImageLoaded={ onLoad }
              crop={ crop }
              onChange={ setCrop }
              onComplete={ setCompletedCrop }
              ruleOfThirds={ true }
              imageStyle={{ width: 'auto', height: '100%', objectFit: 'contain', objectPosition: 'center' }}
              className= { classes['image-cropper']}
              onImageError={() => {
                setLoadError('You cannot edit image by this URL due to security policy')
                setCompletedCrop(null)
              }}
            />
            <div style={{ display: 'none' }}>
              <canvas
                ref={ previewCanvasRef }
              />
            </div>
          </> : null
        }
      </div>
      { (watchInput.length && loadError && !errors.imageURL) ?
        <div className={classes['not-editable']}>
          <img onError={() => {
            setError('imageURL', {
              type: 'manual',
              message: 'Image not found',
            })
          }} src={watchInput} alt="fetched"/>
        </div> : null
      }

      { (loadError || errors.imageURL) &&
        (errors.imageURL && errors.imageURL.message ? <p className={ classes.error }>{errors.imageURL.message}</p> : <p className={ classes.error }>{loadError}</p>)
      }

      <div>
        <div className={ classes['form-group'] }>
          <label
            htmlFor="select-ratio"
            className={ inputClasses.title}
          >Ratio</label>

          <div className={classes['select-wrap']}>
            <select
              className={ classes['select-control']}
              id="select-ratio"
              onChange={
                (event) => {
                  if(event.target.value === 'none'){
                    setCrop({ unit: '%', width: 0, aspect: aspects[event.target.value as 'facebook' | 'twitter' | 'reddit' | 'custom' | 'none'] })
                    setCompletedCrop(null)
                    return
                  }
                  setCrop({ unit: '%', width: 80, aspect: aspects[event.target.value as 'facebook' | 'twitter' | 'reddit' | 'custom' | 'none'] })
                }
              }
            >
              <option value="facebook">Facebook, Instagram, LinkedIn (1.91:1)</option>
              <option value="twitter">Twitter (2:1)</option>
              <option value="reddit">Reddit (1:1)</option>
              <option value="custom">Custom</option>
              <option disabled={!!uploadedImageURL.length} value="none">None</option>
            </select>
            <img src={ icoArrowDown } alt="Arrow down icon" width="10" />
          </div>
        </div>
        <form className={ classes['form-group'] }>
          <Input
            inputId="image-url"
            title="Image URL"
            name="imageURL"
            placeholder="Paste image URL here"
            register={register}
            rules={{ validate: (value: any) => isValidUrl(value) || 'URL in not valid!' }}
            // errors={errors}
            // {...methods}
          />
        </form>
      </div>

      <div className={ classes['editor-actions']}>
        <div>
          <label
            className={ classes['upload-btn'] }
            htmlFor="image-upload"
            onClick={() => mixpanel.track('upload_new_click')}
          >
            <img width="20" src={ icoImage } alt="Image icon" aria-hidden="true" /><span>Upload new</span>
          </label>
          <p className={ classes['image-note'] }>Max file size: 5mb, supported formats: jpg,gif,png</p>
        </div>
        <Button
          btn_class={classnames([buttonClasses['btn-accent-1'], classes['save-btn']])}
          onClick={() => {
            mixpanel.track('save_image_click')
            generateDownload(previewCanvasRef.current, completedCrop)
            setModalVisible(false)
          }}
          isActive={true}
        >Save</Button>
      </div>
    </div>

  )
}
